export const CheckNotification = async (setData) => {
  const request = indexedDB.open('notificationDB', 1);

  request.onsuccess = function (event) {
    const db = event.target.result;
    
    // Open a transaction to the object store with readwrite access
    const transaction = db.transaction('notifications', 'readwrite');

    // Get the object store
    const objectStore = transaction.objectStore('notifications');

    // Create an array to store the retrieved data
    const dataArray = [];

    // Open a cursor to iterate over the data
    const cursorRequest = objectStore.openCursor();

    cursorRequest.onsuccess = function (e) {
      const cursor = e.target.result;

      if (cursor) {
        // Accumulate data into the array
        const data = cursor.value;
        dataArray.push(data);

        // Move to the next item in the cursor
        cursor.continue();
      } else {
        // Update the component state with the retrieved data
        setData(dataArray);

        // Now, open another transaction for deleting the data
        const deleteTransaction = db.transaction('notifications', 'readwrite');
        const deleteObjectStore = deleteTransaction.objectStore('notifications');

        // Clear the object store (remove all data)
        const clearRequest = deleteObjectStore.clear();

        clearRequest.onsuccess = function () {
          console.log('Data cleared successfully');
        };

        clearRequest.onerror = function (e) {
          console.error('Error clearing data:', e.target.error);
        };
      }
    };

    cursorRequest.onerror = function (e) {
      console.error('Error retrieving data:', e.target.error);
    };
  };
};
