import axios from "axios";
import Cookies from "js-cookie";

export const updateProfilePictureApi = async (selectedFile) => {
  const token = Cookies.get("access_token");
  const headers = {
    Accept: "*/*",
    Authorization: token,
  };
  const formData = new FormData();
  formData.append("profile_picture", selectedFile);
  try {
    const response = await axios.put(
      `https://django-cloudrun-5cc2zmgpqq-lz.a.run.app/api/clients/me`,
      formData,
      { headers }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
