import React, { useState } from 'react';
 import TopBar from '../compenent/TopBar';
import { Fdata } from '../assest/data/Fdata';
import { useTranslation } from "react-i18next";
  
const Offers = () => {
 
  const {t , i18n} = useTranslation()
 var isArabic = i18n.language.startsWith('ar');
  return (
    <div className='  w-full    flex flex-col px-[1.4rem] pb-20 '>
    <span className=' pt-[1.4rem] '>
    <TopBar text={t('offer.MyOffers') }/> 
    </span>

    <div className=' pt-[1.4rem] flex flex-col'>
      <h1 className=' font-[600] text-[1rem] '>{t('offer.title',{num : 3}  )} <span className=' text-main'>{t('offer.title1')}</span></h1>
    <div className='  pt-[1.8rem] grid grid-cols-2 w-full gap-3'>
{Fdata.map((i)=> (
  <div className=' flex h-full flex-col  w-full'>
    <img className=' w-full h-[8rem] object-cover rounded-md ' src={i.img} alt="" />
    <h1 className=' pt-1 text-[.9rem] font-[600]  '>{i.text} </h1>
    <p className=' text-sec text-[.8rem] '>{i.p} </p>
  </div>
) ) }
    </div>
    
    </div>
     
</div>
  );
};

export default Offers;

 