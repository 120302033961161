import React, { useContext } from "react";
import { themecontext } from "../context/Themecontexte";
import { useTranslation } from "react-i18next";
import notfoundImg from '../assest/notfoundImg.png'

function NotFound() {
  const { t } = useTranslation();
  const { theme } = useContext(themecontext);

  return (
    <div
      className={` ${
        theme ? "bg-white" : "bg-[#212121] "
      } z-50  text-center flex flex-col items-center justify-center gap-4 fixed top-0 right-0 w-full h-full    `}
    >
        <img className=" w-[50%] " src={notfoundImg} alt="" />
    <div className=" flex flex-col items-center text-center gap-2">
     <h1 className=" text-main text-[2rem]  font-bold "> Page not found !</h1>
      <p className=" w-[70%] text-[.9rem] text-sec ">
        Oops! We can’t seem to find the page you’re looking for Please check
        again your URL{" "}
      </p>

    </div>
    </div>
  );
}

export default NotFound;
