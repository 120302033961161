import React, { useContext, useEffect, useState } from "react";
import TopBar from "../compenent/TopBar";
import { linkEmailWithAccount, GetAllLinkedAccunts , unlinkEmail } from "../API/importEmails";
import Cookies from "js-cookie";
import OutlookImg from "../../src/assest/outLook.png";
import AddIcon from "@mui/icons-material/Add";
import Social from "../compenent/Socials";
import { themecontext } from "../context/Themecontexte";
import { useTranslation } from "react-i18next";
import Account from "../compenent/LinkedAccount/Account";

function LinkedAccount() {
  const [isOpen1, setIsOpen1] = useState(false);
  const [isSocialOpen, setIsSocialOpen] = useState(false);
  const [Accounts, setAccounts] = useState([]);
  const { theme } = useContext(themecontext);
  const { t, i18n } = useTranslation();
  var isArabic = i18n.language.startsWith("ar");

  useEffect(() => {
    const UserToken = Cookies.get("access_token");
    async function handelGetAllLinkedAccunts() {
      try {
        const response = await GetAllLinkedAccunts(UserToken);
        console.log('response')
        console.log(response)

        setAccounts(response?.result);
      } catch (error) {}
    }
    handelGetAllLinkedAccunts();
  }, []);
  

  return (
    <>
      <div className=" text-left  pt-[1.6rem] px-3 h-full relative">
        <TopBar text={t("importReFromEmail.MyReceipts")} />
        <div className=" pt-[1.7rem]  flex flex-col ">
          <h1
            className={`font-semibold  ${
              isArabic ? " text-right" : " text-left"
            } `}
          >
            {t("importReFromEmail.ManageEmailAccounts")}
          </h1>
          <div>
            <div className="  flex flex-col gap-2">
              {Accounts?.map((account, index) => (
                <div key={index}>
                  {index === 0 && (
                    <h1 className="py-[1rem]">
                      {account.integration_name === "GMAIL"
                        ? "Gmail"
                        : "Outlook"}
                    </h1>
                  )}
                  <Account
                  Accounts={Accounts}
                    integration_name={account?.integration_name}
                    id={account.external_account_id}
                    setAccounts={setAccounts}
                    email={account?.Gmail_account}
                  />
                </div>
              ))}
            </div>
          </div>

          <div></div>
        </div>
        <button
          onClick={() => setIsSocialOpen(!isSocialOpen)}
          className="  absolute bottom-20 right-4  p-2   rounded-md  bg-main z-30 flex flex-row items-center gap-1  text-white "
        >
          {" "}
          <AddIcon /> {t("importReFromEmail.AddAccount")}
        </button>
      </div>
      <Social
        text={"Please select your email provider below."}
        setIsOpen={setIsSocialOpen}
        isOpen={isSocialOpen}
      />
    </>
  );
}

export default LinkedAccount;
