import { React, useEffect, useState, useRef, useContext } from "react";
import { Calendar } from "react-date-range";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseBtn from "../../assest/CloseBtn.svg";
import calendar from "../../assest/Calendar.svg";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; //  css file
import format from "date-fns/format";
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";
import { ApplyFilter, ClearFilter } from "../../Functions/FilterActions";
import CheckBoxs from "../Filtter/CheckBoxs";
import { useClickOutside } from "../../hooks/useClickOutside";
import { themecontext } from "../../context/Themecontexte";

function Filter({ FilterOpen, setFilterOpen, setFiltredData, Receiptes }) {
  const [PeriodOpen, setPeriodOpen] = useState(false);
  const [StatusOpen, setStatusOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const [EndDateCalendar, setEndDateCalendar] = useState(false);
  const [StartDateCalendar, setStartDateCalendar] = useState(false);
  const [date, setDate] = useState({
    StartDate: null,
    EndDate: null,
  });
  const [Period, setPeriod] = useState("All Date");
  const [Status, setStatus] = useState("All");

  const [timeOrder, setTimeOrder] = useState(null);
  const [alphabeticalOrder, setAlphabeticalOrder] = useState(null);
  const [priceOrder, setPriceOrder] = useState(null);
  const [receiptType, setReceiptType] = useState(null);

  var isArabic = i18n.language.startsWith("ar");
  let startDate = new Date();

  const StartRef = useRef();
  const EndRef = useRef();

  const { theme } = useContext(themecontext);
  // Closing the StartCalendr By clicking outSide it
  const closeStartCalendr = () => {
    setStartDateCalendar(false);
  };
  useClickOutside(StartRef, closeStartCalendr);
  // ------------------------------------

  // Closing the EndCalendr By clicking outSide it
  const closeEndCalendr = () => {
    setEndDateCalendar(false);
  };
  useClickOutside(EndRef, closeEndCalendr);
  // ------------------------------------

  useEffect(() => {
    const currentDate = new Date();

    if (Period === "Today") {
      startDate.setHours(0, 0, 0, 0);
      console.log(startDate);
    } else if (Period === "Yesterday") {
      startDate.setDate(currentDate.getDate() - 1);
      startDate.setHours(0, 0, 0, 0);
      console.log(startDate);
    } else if (Period === "This week") {
      startDate.setDate(currentDate.getDate() - currentDate.getDay());
      startDate.setHours(0, 0, 0, 0);
    } else if (Period === "Last week") {
      startDate.setDate(currentDate.getDate() - 7 - currentDate.getDay());
      startDate.setHours(0, 0, 0, 0);
    } else if (Period === "This month") {
      startDate.setDate(1);
      startDate.setHours(0, 0, 0, 0);
    } else if (Period === "Last month") {
      startDate.setMonth(currentDate.getMonth() - 1, 1);
      startDate.setHours(0, 0, 0, 0);
    } else if (Period === "This year") {
      startDate.setMonth(0, 1);
      startDate.setHours(0, 0, 0, 0);
    } else if (Period === "Last year") {
      startDate.setFullYear(currentDate.getFullYear() - 1, 0, 1);
      startDate.setHours(0, 0, 0, 0);
    }
  }, [Period]);

  const SubmitApllyFILTER = (data) => {
    ApplyFilter(
      Period,
      startDate,
      data,
      setFiltredData,
      date,
      Status,
      alphabeticalOrder,
      priceOrder,
      receiptType,
      timeOrder
    );
  };

  const SubmitClearFilter = () => {
    ClearFilter(
      setPeriod,
      setDate,
      setStatus,
      setTimeOrder,
      setAlphabeticalOrder,
      setPriceOrder,
      setReceiptType
    );
  };

  const StatusArray = [
    { text: t("filter.all"), value: "All" },
    { text: t("receipts.paid"), value: "Paid" },
    { text: t("receipts.Unpaid"), value: "Unpaid" },
    { text: t("receipts.Ppaid"), value: "Partially Paid" },
    { text: t("receipts.refunded"), value: "Refunded" },
  ];

  const PeriodArray = [
    { text: t("filter.AllDate"), value: "All Date" },
    { text: t("filter.Today"), value: "Today" },
    { text: t("filter.Yesterday"), value: "Yesterday" },
    { text: t("filter.Thisweek"), value: "This week" },
    { text: t("filter.Lastweek"), value: "Last week" },
    { text: t("filter.Thismonth"), value: "This month" },
    { text: t("filter.Lastmonth"), value: "Last month" },
    { text: t("filter.Thisyear"), value: "This year" },
    { text: t("filter.Lastyear"), value: "Last year" },
  ];

  return (
    <div
      className={`  top-0 h-full flex fixed duration-150 right-0 ${
        FilterOpen ? "z-50 opacity-100" : " -z-10   opacity-0 "
      }   w-full   `}
    >
      <div
        onClick={() => setFilterOpen(false)}
        className={`absolute z-10 top-0 right-0 w-full h-full bg-black  ${
          FilterOpen ? " bg-opacity-25" : "  bg-opacity-0 "
        }   duration-400 `}
      ></div>
      <div
        className={`   ${theme ? " bg-white" : " bg-[#212121]"} ${
          FilterOpen ? "right-0" : " right-[-100%] "
        }  duration-300  z-20 absolute        top-0 right-0 w-[82%]  py-14 h-full`}
      >
        <div
          className={`   flex   ${
            isArabic ? "  flex-row-reverse" : " flex-row"
          } justify-between items-center absolute top-0 right-0 w-full px-[1.3rem] h-[4rem]    flex-grow  ${
            theme ? " bg-white" : " bg-[#212121]"
          }`}
        >
          <h1 className={` w-full text-[1.4rem] font-[700] `}>
            {t("filter.title")}
          </h1>

          <img onClick={() => setFilterOpen(false)} src={CloseBtn} alt="" />
        </div>
        <div
          className={` w-full flex flex-col gap-3 px-[1.3rem] ${
            StartDateCalendar || EndDateCalendar ? "" : "overflow-auto"
          }  flex-grow h-full pb-10`}
        >
          <h1 className=" text-[.8] font-medium pt-[1rem] ">
            {t("filter.Ptitle")}
          </h1>

          <div
            onClick={() => setPeriodOpen(!PeriodOpen)}
            className={`  ${
              theme ? "  bg-opacity-100" : "bg-opacity-10 "
            }   relative items-center flex   ${
              isArabic ? "  flex-row-reverse" : " flex-row"
            }   justify-between  px-[.7rem] w-full  py-4  h-[5rem]   bg-[#F8F8F8] rounded-[.27rem] `}
          >
            <span className=" w-full text-[.9rem] text-sec">
              {PeriodArray.find((i) => i.value == Period).text}
            </span>
            <span onClick={() => setPeriodOpen(!PeriodOpen)}>
              <ExpandMoreIcon className=" text-main" />
            </span>

            <div
              className={` duration-150   z-10 flex flex-col  top-16 right-0 rounded w-full absolute   ${
                PeriodOpen ? "h-[22.7rem]" : " h-0"
              }  ${
                !theme ? " bg-[#1C1C1C] " : "bg-[#F9F9FF]"
              }  shadow overflow-hidden `}
            >
              {PeriodArray.map((i) => (
                <div className=" flex flex-col">
                  <span
                    onClick={() => setPeriod(i.value)}
                    className={`  ${
                      Period == i.value ? " text-main" : ""
                    }    p-2   `}
                  >
                    {" "}
                    {i.text}{" "}
                  </span>
                  <span
                    className={` w-full h-[1px]  ${
                      !theme
                        ? " bg-[#1C1C1C] bg-[#f2f2f22f]  "
                        : " bg-[#e9e7e7] "
                    }  `}
                  ></span>
                </div>
              ))}
            </div>
          </div>

          <div className=" flex    justify-between w-full pt-4 items-center gap-[.6rem]  ">
            {/*  Start Date Calendar */}
            <span
              className={`  relative text-[.9rem] flex flex-col items-center   gap-1  ${
                StartDateCalendar
                  ? " border-main text-main"
                  : `text-sec  ${theme ? "   " : "  border-[#424242] "} `
              } border-solid border-[1px]  rounded   px-1 py-[.7rem] h-[5rem] w-full `}
            >
              <div
                ref={StartRef}
                className=" w-full h-full flex flex-col  items-center gap-2"
              >
                <span
                  className=" w-full h-full flex flex-col  items-center gap-2"
                  onClick={() => setStartDateCalendar(!StartDateCalendar)}
                >
                  <img
                    className="  h-[1.8rem] w-[1.8rem]  "
                    src={calendar}
                    alt=""
                  />
                  <h1>
                    {date?.StartDate == null
                      ? t("filter.startD")
                      : format(date?.StartDate, "MM/dd/yyyy")}
                  </h1>
                </span>

                {StartDateCalendar && (
                  <span className=" z-50 absolute top-16 shadow-lg    -left-[60%] ">
                    <Calendar
                      onChange={(selectedDate) =>
                        setDate({
                          ...date,
                          StartDate: selectedDate,
                        })
                      }
                      color="#06C671"
                      rangeColors={"#06C671"}
                      date={date.StartDate}
                    />
                  </span>
                )}
              </div>
            </span>
            {/* ------------- */}

            {/*  End Date Calendar */}
            <div
              className={` relative text-[.9rem] flex flex-col items-center   ${
                EndDateCalendar
                  ? " border-main text-main"
                  : `text-sec  ${theme ? "    " : "  border-[#424242] "} `
              } gap-1 border-solid border-[1px]  rounded    px-1 py-[.7rem] h-[5rem] w-full`}
            >
              <div
                ref={EndRef}
                className=" relative w-full h-full flex flex-col  items-center gap-2"
              >
                <span
                  className=" w-full h-full flex flex-col  items-center gap-2"
                  onClick={() => setEndDateCalendar(!EndDateCalendar)}
                >
                  <img
                    className="  h-[1.8rem] w-[1.8rem]  "
                    src={calendar}
                    alt=""
                  />

                  <h1>
                    {date?.EndDate == null
                      ? t("filter.endD")
                      : format(date?.EndDate, "MM/dd/yyyy")}
                  </h1>
                </span>

                {EndDateCalendar && (
                  <span className=" z-50 absolute top-16 shadow-lg  right-0  ">
                    <Calendar
                      onChange={(selectedDate) =>
                        setDate({
                          ...date,
                          EndDate: selectedDate,
                        })
                      }
                      minDate={date.StartDate || undefined}
                      color="#06C671"
                      rangeColors={"#06C671"}
                      date={date.EndDate}
                    />
                  </span>
                )}
              </div>
            </div>
            {/* ------------- */}
          </div>
          <h1 className=" text-[.8] font-medium pt-[1rem] ">
            {" "}
            {t("filter.Status")}{" "}
          </h1>

          <div
            onClick={() => setStatusOpen(!StatusOpen)}
            className={`  ${theme ? "  bg-opacity-100" : "bg-opacity-10 "} ${
              isArabic ? "  flex-row-reverse" : " flex-row"
            }   relative items-center flex   justify-between px-[.7rem] w-full  py-4  h-[5rem] mt-[1rem] bg-[#F8F8F8] rounded-[.27rem] `}
          >
            <span className=" w-full text-[.9rem] text-sec">
              {StatusArray.find((i) => i.value == Status).text}
            </span>
            <span onClick={() => setStatusOpen(!StatusOpen)}>
              <ExpandMoreIcon className=" text-main" />
            </span>

            <div
              className={` duration-150   z-10  flex flex-col  top-16 right-0 rounded w-full absolute   ${
                StatusOpen ? "h-[12.5rem]" : " h-0"
              }  ${
                theme ? " bg-white" : " bg-[#1C1C1C]"
              }  shadow overflow-hidden `}
            >
              {StatusArray.map((i) => (
                <div key={i.index} className="flex flex-col">
                  <span
                    onClick={() => setStatus(i.value)}
                    className={`  ${
                      Period == i.value ? " text-main" : ""
                    }    p-2   `}
                  >
                    {i.text}
                  </span>
                  <span
                    className={` w-full h-[1px]  ${
                      !theme
                        ? " bg-[#1C1C1C] bg-[#f2f2f22f]  "
                        : " bg-[#e9e7e7] "
                    }  `}
                  ></span>
                </div>
              ))}
            </div>
          </div>

          <CheckBoxs
            timeOrder={timeOrder}
            setTimeOrder={setTimeOrder}
            alphabeticalOrder={alphabeticalOrder}
            setAlphabeticalOrder={setAlphabeticalOrder}
            priceOrder={priceOrder}
            setPriceOrder={setPriceOrder}
            receiptType={receiptType}
            setReceiptType={setReceiptType}
          />

          <div
            className={` absolute   gap-3 bottom-0 right-0 w-full justify-between items-center flex  ${
              isArabic ? " flex-row-reverse" : " flex-row"
            } px-5 h-[4rem] border-solid border-t-[1px]  ${
              theme ? " bg-white" : " bg-[#212121]"
            } `}
          >
            <button
              onClick={SubmitClearFilter}
              className=" w-full flex items-center gap-2 justify-center text-sec bg-transparent "
            >
              {t("filter.clear")}
              <ClearIcon fontSize="" />
            </button>

            <button
              onClick={() => {
                SubmitApllyFILTER(Receiptes);
                setFilterOpen(!FilterOpen);
              }}
              className=" flex items-center gap-1   justify-center border-solid border-main border-[2px] text-main  w-full rounded-full  font-[600] py-[.7rem] bg-transparent "
            >
              {t("filter.title")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Filter;
