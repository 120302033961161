// ImageUpload.js
import React from "react";
import CloseBtn from "../../assest/CloseBtn.svg";
import cameraImg from '../../assest/camera.svg'
const ImageUpload = ({ image, setImage, handleFileChange, label ,Edit }) => {
  return (
    <label className={`h-[7rem] w-[9.5rem] border-solid border-[1px] relative overflow-hidden rounded-md items-center justify-center gap-2 flex flex-col`}>
      <input
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        disabled={!Edit}
        multiple={false}
        style={{ display: "none" }}
      />

      {image && Edit &&  (
        <button
          type="button"
          onClick={(e) => {
            setImage(null);
            e.preventDefault();
          }}
        >
          <img className="absolute top-2 right-2 z-20" src={CloseBtn} alt="" />
        </button>
      )}
      {image  && (
        <img
          className="w-full h-full object-cover absolute top-0 right-0"
          src={
            typeof image === "string"
              ? image
              : URL.createObjectURL(image)
          }
          alt="Selected"
        />
      )}
      {!image && (
        <span className="h-[7rem] w-[9rem] rounded-md items-center justify-center gap-2 flex flex-col">
          <img className="scale-150" src={cameraImg} alt="" />
          <h1 className="text-sm">{label}</h1>
        </span>
      )}
    </label>
  );
};

export default ImageUpload;
