import { React, useState, useEffect } from "react";
import TopBar from "../compenent/TopBar";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import SearchIcon from '@mui/icons-material/Search';
import FrFlag from "../../src/assest/frenchFlag.png";
import ArFlag from "../../src/assest/saudiFlag.png";
import EnFlag from "../../src/assest/EnFlag.png";
import { themecontext } from "../context/Themecontexte";

function Currency() {
  const { t, i18n } = useTranslation();
  const { theme } = useContext(themecontext);
  var isArabic = i18n.language.startsWith("ar");

  const languages = [
    {
      text: "English",
      value: "en",
      img: EnFlag,
    },
    {
      text: "العربية",
      value: "ar",
      img: ArFlag,
    },
    {
      text: "Français",
      value: "fr",
      img: FrFlag,
    },
  ];

  const [FiltredData, setFiltredData] = useState(languages);
  const [searchTerm, setSearchTerm] = useState("");

  

  // Search functionality -----------
  useEffect(() => {
    setFiltredData(languages);
  }, []);

  const handleSearch = () => {
    const filtreData = languages?.filter((item) =>
      item.text.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFiltredData(filtreData);
  };

  useEffect(() => {
    handleSearch();
  }, [searchTerm]);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    handleSearch();
  };
  // ----------------

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <div className="  pt-[1.2rem] px-[1.3rem] h-[100vh] ">
      <TopBar text={t("topbar.Ltoptitle")} />
      <div className=" flex flex-col pt-[1.4rem] ">
        <span className=" relative w-full ">
          
         <SearchIcon  className= "   text-slate-400 absolute top-5 left-2 scale-75"/>
          <input
            value={searchTerm}
            onChange={handleInputChange}
            type="text"
            className={` text-[.75rem] px-8  ${
              isArabic ? "  text-right " : "  text-left"
            }    bg-gray-500 bg-opacity-5  rounded-[.3rem] w-full h-[3.8rem] `}
            placeholder={
              t("profile.searchPlaceh") + ` ` + t("topbar.Ltoptitle")
            }
          />
        </span>

        <div className=" flex flex-col gap-2  pt-[2rem] ">
          {FiltredData?.map((item) => (
            <span
              onClick={() => changeLanguage(item.value)}
              className={` ${
                i18n.language.startsWith(item.value)
                  ? ` ${
                      !theme
                        ? "    bg-black bg-opacity-30  active:bg-black"
                        : "bg-slate-100  active:bg-gray-50 "
                    }  font-medium `
                  : " "
              }  flex w-full ${
                isArabic ? " flex-row-reverse" : " flex-row"
              } items-center gap-2`}
            >
              <img className=" h-[3.4rem] w-[3.4rem] " src={item.img} />
              <h1> {item.text} </h1>
            </span>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Currency;
