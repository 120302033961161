import AddCardOrUpdate from "../API/CardAPI";
import { addCardOrUpdateToLocalDB } from "../DB/LoyaltyCardDB";

export async function UplodingOfflineCard(allCards) {
    for (const card of allCards) {
      if (card?.local) {
        const formData = new FormData();
        if (card?.front_image != null) {
          formData.append("front_image", card?.front_image);
        }
        if (card?.back_image != null) {
          formData.append("back_image", card?.back_image);
        }
  
        formData.append("code", card?.code);
        formData.append("notes", card?.notes);
        formData.append("store_name", card?.store_name);
  
        if (card?.opiration == "add") {
          try {
            const response = await AddCardOrUpdate(null, formData, "post");
            const cardDetails = {
              back_image: card?.back_image,
              code: card?.code,
              front_image: card?.front_image,
              id: card?.id,
              notes: card?.notes,
              store_name: card?.store_name,
              opiration: null,
              local: false
            };
            await addCardOrUpdateToLocalDB(cardDetails);
          } catch (error) {
            // Handle error if needed
          }
        } else if (card?.opiration == "update") {
          const response = await AddCardOrUpdate(card?.id, formData, "patch");
          // Handle response if needed
        }
      }
    }
  }
  