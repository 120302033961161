import * as yup from 'yup';
import i18n from '../i18n';

const generateResetPassSchema2 = () => {
  return yup.object().shape({
    password: yup.string().min(5).required(i18n.t('inputsVal.passwordRequired')),
    confirmpassword: yup.string()
      .oneOf([yup.ref('password')], i18n.t('inputsVal.passwordMismatch'))
      .required(i18n.t('inputsVal.confirmPasswordRequired')),
  });
};

export default generateResetPassSchema2;
