import React from "react";
import DeleteNotification from "../../assest/notif_delete.svg";
 import LoadingBtn from "../LoadingBtn";
import { useTranslation } from "react-i18next";

function ConfirmeDeleteReceipt({  id , setIsOpen  , handleDelete , isLoading  }) {
const {t} = useTranslation()
  return (
    <div className="  text-center  w-full gap-4   h-full  flex-col flex justify-between ">
      <img className=" h-[10rem]   " src={DeleteNotification} alt="" />
      
      <div className=" h-full flex gap-3 flex-col">
         <h1 className=" text-[1.25rem] font-semibold "> {t('receipts.confirmDelete')} </h1>
      <p className=" text-gray-400   ">
        {t('receipts.deleteReceipt')} 
      </p>
      <div className=" flex pt-3  font-semibold text-[#939393] text-[1rem] flex-row gap-2 ">
        <button onClick={ ()=>setIsOpen(null)} className=" w-full h-[3rem]  bg-[#E4E4E4]   rounded-md  ">
          {t('card.no')} 
        </button>
        <button onClick={ ()=> handleDelete(id)} className=" relative text-white w-full h-[3rem] bg-red-500 rounded-md  ">
        {t('card.yes')} 
          <LoadingBtn isLoading={isLoading} color={'white'}  bg={'bg-red-500'} />
        </button>
      </div>
     
      </div>
    </div>
  );
}

export default ConfirmeDeleteReceipt;
