import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
 



export default function CheckBoxs({
  
timeOrder,
setTimeOrder,
alphabeticalOrder,
setAlphabeticalOrder,
priceOrder,
setPriceOrder,
receiptType,
setReceiptType,
}) {
 
 const {t , i18n} = useTranslation() 

  var isArabic = i18n.language.startsWith('ar');
  const handleCheckboxChange = (group, value) => {
    switch (group) {
      case 'timeOrder':
        setTimeOrder(timeOrder === value ? null : value);
        break;
      case 'alphabeticalOrder':
        setAlphabeticalOrder(alphabeticalOrder === value ? null : value);
        break;
      case 'priceOrder':
        setPriceOrder(priceOrder === value ? null : value);
        break;
      case 'receiptType':
        setReceiptType(receiptType === value ? null : value);
        break;
      default:
        break;
    }
  };

  return (
    <div className={`    text-[.9rem] `}  >
      {/* By Time Order */}
      <h1 className=" font-medium pt-[1rem]">{t('filter.bytime')} </h1>
      <div className= {`   pr-3  pt-[.7rem] w-full grid grid-cols-2  `}>
        <span className= {`flex   gap-2 text-sec ${isArabic ? ' flex-row-reverse' :'  flex-row ' }`} >
          <input
            className="w-7 h-7 border-gray-200 border-solid opacity-20 bg-gray-800 checked:opacity-100"
            type="checkbox"
            checked={timeOrder === 'newToOld'}
            onChange={() => handleCheckboxChange('timeOrder', 'newToOld')}
          />
        {t('filter.NtoO')}
        </span>
        <span className= {`flex   gap-2 text-sec ${isArabic ? ' flex-row-reverse' :'  flex-row ' }`}>
          <input
            className="w-7 h-7 border-gray-200 border-solid opacity-20 bg-gray-800 checked:opacity-100"
            type="checkbox"
            checked={timeOrder === 'oldToNew'}
            onChange={() => handleCheckboxChange('timeOrder', 'oldToNew')}
          />
        {t('filter.OtoN')}
        </span>
      </div>

      {/* By Alphabetical Order */}
      <h1 className=" font-medium pt-[1rem]">{t('filter.Alphabetical')}</h1>
      <div className="flex-row pr-3  pt-[.7rem] w-full grid grid-cols-2">
        <span className= {`flex   gap-2 text-sec ${isArabic ? ' flex-row-reverse' :'  flex-row ' }`}>
          <input
            className="w-7 h-7 border-gray-200 border-solid opacity-20 bg-gray-800 checked:opacity-100"
            type="checkbox"
            checked={alphabeticalOrder === 'aToZ'}
            onChange={() => handleCheckboxChange('alphabeticalOrder', 'aToZ')}
          />
           {t('filter.SAtoZ')}
        </span>
        <span className= {`flex   gap-2 text-sec ${isArabic ? ' flex-row-reverse' :'  flex-row ' }`}>
          <input
            className="w-7 h-7 border-gray-200 border-solid opacity-20 bg-gray-800 checked:opacity-100"
            type="checkbox"
            checked={alphabeticalOrder === 'zToA'}
            onChange={() => handleCheckboxChange('alphabeticalOrder', 'zToA')}
          /> 
           {t('filter.SZtoA')}
        </span>
      </div>

      {/* By Price Order */}
      <h1 className=" font-medium pt-[1rem]"> {t('filter.OPrice')}</h1>
      <div className="flex-row pr-3  pt-[.7rem] w-full grid grid-cols-2">
        <span className= {`flex   gap-2 text-sec ${isArabic ? ' flex-row-reverse' :'  flex-row ' }`}>
          <input
            className="w-7 h-7 border-gray-200 border-solid opacity-20 bg-gray-800 checked:opacity-100"
            type="checkbox"
            checked={priceOrder === 'highToLow'}
            onChange={() => handleCheckboxChange('priceOrder', 'highToLow')}
          />
           {t('filter.HPrice')} 
        </span>
        <span className= {`flex   gap-2 text-sec ${isArabic ? ' flex-row-reverse' :'  flex-row ' }`}>
          <input
            className="w-7 h-7 border-gray-200 border-solid opacity-20 bg-gray-800 checked:opacity-100"
            type="checkbox"
            checked={priceOrder === 'lowTohigh'}
            onChange={() => handleCheckboxChange('priceOrder', 'lowTohigh')}
          />
          {t('filter.LPrice')} 
        </span>
      </div>

      {/* By Receipt Type */}
      <h1 className=" font-medium pt-[1rem]"> {t('filter.RType')} </h1>
      <div className="flex-row pr-3   pt-[.7rem] w-full grid grid-cols-2">
        <span className= {`flex   gap-2 text-sec ${isArabic ? ' flex-row-reverse' :'  flex-row ' }`}>
          <input
            className="w-6 border-gray-200 border-solid opacity-20 bg-gray-800 checked:opacity-100"
            type="checkbox"
            checked={receiptType === 'pdf'}
            onChange={() => handleCheckboxChange('receiptType', 'pdf')}
          />
         {t('filter.DReceipt')} 
        </span>
        <span className= {`flex   gap-2 text-sec ${isArabic ? ' flex-row-reverse' :'  flex-row ' }`}>
          <input
            className="w-6 border-gray-200 border-solid opacity-20 bg-gray-800 checked:opacity-100"
            type="checkbox"
            checked={receiptType === 'jpg'}
            onChange={() => handleCheckboxChange('receiptType', 'jpg')}
          />
          {t('filter.PReceipt')} 
        </span>
      </div>
    </div>
  );
}
