// SubmitFormUtils.js
import AddCardOrUpdate from "../API/CardAPI";
import { addCardOrUpdateToLocalDB } from "../DB/LoyaltyCardDB";
export async function HandelUpdateOrAddCard({
  isOnline,
  id,
  intID,
  values,
  CfrontImage,
  CbackImage,
  setLoyaltyCard,
  setMsg,
  setIsLoading,
  handleSubmissionComplete,
}) {
  console.log(CfrontImage, CbackImage, values);
  function generateUniqueId() {
    return "_" + Math.random().toString(36).substr(2, 9);
  }
  // prepare the data of the card to send
  const cardDetails = {
    back_image: CbackImage,
    code: values.cardnumber,
    front_image: CfrontImage,
    id: id ? id : generateUniqueId(),
    notes: values.notes,
    store_name: values.storename,
    operation: id ? "update" : "add",
    local: true,
  };

  if (!isOnline) {
    await addCardOrUpdateToLocalDB(cardDetails);

    if (!id) {
      setLoyaltyCard((prevCards) => [cardDetails, ...prevCards]);
    }

    handleSubmissionComplete();
  } else {
    const formData = new FormData();
    if (CfrontImage != null) {
      formData.append("front_image", CfrontImage);
    }
    if (CbackImage != null) {
      formData.append("back_image", CbackImage);
    }

    formData.append("code", values.cardnumber);
    formData.append("notes", values.notes);
    formData.append("store_name", values.storename);

    try {
      const response = await AddCardOrUpdate(
        id,
        formData,
        id ? "patch" : "post"
      );

      console.log(response);

      if (id) {
        addCardOrUpdateToLocalDB(response);
        setLoyaltyCard((prevCards) =>
          prevCards.map((card) => (card.id === response.id ? response : card))
        );
        setMsg({
          text: "Card updated successfully",
          color: "bg-green-400 text-[0.9rem]",
        });
      } else {
        setLoyaltyCard((prevCards) => [response, ...prevCards]);
        setMsg({
          text: "Card added successfully",
          color: "bg-green-400 text-[0.9rem]",
        });
      }

      handleSubmissionComplete();
    } catch (error) {
      console.log(cardDetails);
      // Handle errors
      await addCardOrUpdateToLocalDB(cardDetails);
      if (!id) {
        setLoyaltyCard((prevCards) => [cardDetails, ...prevCards]);
      }else{
        setLoyaltyCard((prevCards) =>
        prevCards.map((card) => (card.id === cardDetails.id ? cardDetails : card))
      );
      }

      setIsLoading(false);
    }
  }
}
