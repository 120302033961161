export const ProcessChartData = (data, Period , Countable) => {
    const processedData = [];
    let totalAmount = 0;
    const time = data?.time;
    const Data = data?.data;
  
    const shouldGroupDates = Data?.length > 12;
  
    for (let i = 0; i < Data?.length; i++) {
      const entry = Data[i];
      const entryTime = time[i];
      const entryDate = new Date(entryTime);
      let timeUnitValue;
  
      if (shouldGroupDates) {
        const rangeStart = entryDate.getDate(); // Get the day part
        let rangeEnd;
  
        // Determine the end of the range
        if (i + 3 < Data.length) {
          rangeEnd = new Date(time[i + 3]).getDate();
        } else {
          rangeEnd = new Date(time[Data.length - 1]).getDate();
        }
  
        timeUnitValue = `${rangeStart} - ${rangeEnd}`;
        i += 3; // Move the loop index to the end of the current range
      } else {
        if (Period === "Last year" || Period === "This year") {
          timeUnitValue = entryDate.toLocaleString("en-us", {
            month: "short",
          });
        } else {
          timeUnitValue = entryDate.toLocaleString("en-us", {
            day: "2-digit",
          });
        }
      }
  
      const dataObject = { x: timeUnitValue, y: entry };
      processedData.push(dataObject);
      if(Countable){
          totalAmount += entry || 0;
      }

    }
  
    return { totalAmount, processedData };
  };
  