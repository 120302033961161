import * as yup from 'yup';
import i18n from '../i18n';

const generatePersonalSchema = () => {
  return yup.object().shape({
    firstname: yup.string().max(15).required(i18n.t('inputsVal.firstnameRequired')),
    lastname: yup.string().max(15).required(i18n.t('inputsVal.lastnameRequired')),
    email: yup.string().email(i18n.t('inputsVal.emailInvalid')).required(i18n.t('inputsVal.emailRequired')),
  });
};

export default generatePersonalSchema;
