import {React , useState , useEffect, useContext } from "react";
import TopBar from "../TopBar";
import { Sdata } from "../../assest/data/Sdata";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import "swiper/swiper.min.css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useTranslation } from "react-i18next";
import { themecontext } from "../../context/Themecontexte";

function AddCard() {
  const [data, setdata] = useState(Sdata);
  const [FiltredData, setFiltredData] = useState(Sdata);
const {theme} = useContext(themecontext)
  const [searchTerm, setSearchTerm] = useState("");
  const { t, i18n } = useTranslation();
  let isArabic = i18n.language.startsWith("ar");

  // Search functionality -----------
  useEffect(() => {
    setFiltredData(Sdata);
  }, [Sdata]);

  const handleSearch = () => {
    const filtreData = data?.filter((item) =>
      item.text.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFiltredData(filtreData);
  };

  useEffect(() => {
    handleSearch();
  }, [searchTerm]);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    handleSearch();
  };
// ----------------------------





  return (
    <div className=  {`fixed top-0 right-0   ${!theme ?' bg-[#212121] ' : 'bg-white'} z-50  w-full   flex  flex-col  px-[1.2rem]   h-full`} >
      <div className=" w-full pt-[1.4rem]  flex flex-row justify-between">
        <TopBar text={t("card.addC")} />
      </div>
      <div className=" mt-[1.4rem]  flex flex-col ">
        <span className=" relative w-full ">
          <p className="  text-[#9C9C9C]  flex flex-row gap-[.35rem] items-center  absolute  top-[1.5rem]  left-3 ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
            >
              <path
                d="M9.64153 8.21094H9.06072L8.85486 8.01243C9.31433 7.47865 9.65013 6.84991 9.83823 6.1712C10.0263 5.49249 10.0621 4.78059 9.94296 4.08645C9.59742 2.04259 7.89175 0.410438 5.83318 0.160469C5.10946 0.0689108 4.37438 0.144126 3.6842 0.38036C2.99402 0.616593 2.36703 1.00758 1.8512 1.52341C1.33537 2.03924 0.944382 2.66623 0.708149 3.35641C0.471916 4.04659 0.3967 4.78167 0.488258 5.50539C0.738227 7.56396 2.37038 9.26963 4.41424 9.61517C5.10838 9.73431 5.82028 9.69855 6.49899 9.51044C7.1777 9.32234 7.80644 8.98654 8.34022 8.52707L8.53873 8.73293V9.31374L11.6633 12.4384C11.9648 12.7398 12.4574 12.7398 12.7588 12.4384C13.0602 12.1369 13.0602 11.6443 12.7588 11.3429L9.64153 8.21094ZM5.23031 8.21094C3.39966 8.21094 1.9219 6.73318 1.9219 4.90252C1.9219 3.07187 3.39966 1.59411 5.23031 1.59411C7.06097 1.59411 8.53873 3.07187 8.53873 4.90252C8.53873 6.73318 7.06097 8.21094 5.23031 8.21094Z"
                fill="#C1C1C1"
              />
            </svg>{" "}
          </p>
          <input
          value={searchTerm}
          onChange={handleInputChange}
            type="text"
            className={` text-[.75rem] px-8  ${
              isArabic ? "  text-right " : "  text-left"
            }    bg-gray-500 bg-opacity-5  rounded-[.3rem] w-full h-[3.8rem] `}
            placeholder={t("profile.searchPlaceh") + ` ` + t("menuBar.Cards")}
          />
        </span>
        <div className=" w-full text-sm flex flex-col gap-[.6rem]  pt-[1.4rem] ">
          <Link
            to={"/Cards/addcartmanully"}
            className={`  ${
              isArabic ? "   flex-row-reverse " : " flex-row  "
            }  items-center  flex   gap-2 `}
          >
            <span className=" w-[5rem] h-[3.5rem] rounded-md text-main flex justify-center items-center bg-[#F1FFF9]  ">
              <AddIcon />
            </span>
            <h1>{t("card.OtherC")} </h1>
          </Link>

          {FiltredData?.slice(0, 4).map((i) => (
            <Link
            to={`/Cards/addcartmanully?brand=${i.text}`}
              className={`w-full flex flex-row justify-between  ${
                isArabic ? "   flex-row-reverse " : " flex-row  "
              }    items-center `}
            >
              <span
                className={`  flex  ${
                  isArabic ? "   flex-row-reverse " : " flex-row  "
                } gap-2 items-center `}
              >
                <img
                  className="w-[5rem] h-[3.5rem] object-cover rounded-md "
                  src={i.img}
                  alt=""
                />
                <h1>{i.text} </h1>
              </span>
              <AddIcon />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AddCard;
