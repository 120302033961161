export  function ClearFilter(setPeriod ,setDate , setStatus,setTimeOrder , setAlphabeticalOrder , setPriceOrder ,setReceiptType ) {
    setDate({
      StartDate: null,
      EndDate: null,
    });
    setPeriod("All Date");
    setStatus("All");

    setTimeOrder(null);
    setAlphabeticalOrder(null);
    setPriceOrder(null);
    setReceiptType(null);
  }

  export function ApplyFilter( Period , startDate, data , setFiltredData ,date , Status ,alphabeticalOrder,priceOrder ,receiptType , timeOrder ) {
    

    let filteredData = data;

    // Apply date filter if Period is not 'All Date'
    if (Period !== "All Date") {
      filteredData = filteredData?.filter((item) => {
        const itemDate = new Date(item.created_at);
        return itemDate >= startDate;
      });
    }

    // Apply additional filters based on StartDate and EndDate
    if (date.StartDate && date.EndDate) {
      const fromTimestamp = date.StartDate.getTime();
      const toTimestamp = date.EndDate.getTime();

      filteredData = filteredData?.filter((receipt) => {
        const createdAt = new Date(receipt.created_at).getTime();
        return createdAt >= fromTimestamp && createdAt <= toTimestamp;
      });
    }

    // Apply status filter
    if (Status !== "All") {
      if (Status === "Paid") {
        filteredData = filteredData?.filter((i) => i?.json?.credit === 0);
      } else if (Status === "Unpaid") {
        filteredData = filteredData?.filter(
          (i) => i?.json?.credit === i?.json?.total
        );
      } else if (Status === "Partially Paid") {
        filteredData = filteredData?.filter(
          (i) => i?.json?.credit < i?.json?.total && i?.json?.credit !== 0
        );
      } else {
        filteredData = filteredData?.filter((i) => i?.json?.total === 0);
      }
    }

    // Apply Date OldtoNew & NewtoOld Sort
    filteredData = filteredData?.sort((a, b) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);

      if (timeOrder === "newToOld") {
        // Sort by 'created_at' from new to old
        return dateB - dateA;
      } else if (timeOrder === "oldToNew") {
        // Sort by 'created_at' from old to new
        return dateA - dateB;
      } else {
        // Default to new to old if sortOrder is not specified or invalid
        return dateB - dateA;
      }
    });
    // Apply AtoZ & ZtoA Sort
    filteredData = filteredData?.sort((a, b) => {
      const nameA = a.json?.products[0]?.name.toLowerCase(); // Assuming name is within products array
      const nameB = b.json?.products[0]?.name.toLowerCase();

      if (alphabeticalOrder === "aToZ") {
        // Sort alphabetically from A to Z
        return nameA?.localeCompare(nameB);
      } else if (alphabeticalOrder === "zToA") {
        // Sort alphabetically from Z to A
        return nameB?.localeCompare(nameA);
      }
    });

    // Apply Pirce Sort

    filteredData = filteredData?.sort((a, b) => {
      const totalA = a.json?.total;
      const totalB = b.json?.total;

      if (priceOrder === "highToLow") {
        // Sort by total from high to low
        return totalB - totalA;
      } else if (priceOrder === "lowTohigh") {
        // Sort by total from low to high
        return totalA - totalB;
      }
    });

    // Aplly filterr of digital and phy
    if (receiptType) {
      filteredData = filteredData?.filter(
        (receipt) => receipt.file_type === receiptType && receiptType
      );
    }

    setFiltredData(filteredData);
  }