import { React, useState, useEffect } from "react";
import TopBar from "../compenent/TopBar";
import { useTranslation } from "react-i18next";
import USD from "../assest/EnFlag.png";
import SearchIcon from '@mui/icons-material/Search';
function Currency() {
  const Currencies = [
    {
      conrty: "United states",
      curr: "USD",
      sign: "$",
      img: USD,
    },
  ];
  const { t, i18n } = useTranslation();
  var isArabic = i18n.language.startsWith("ar");
  const [FiltredData, setFiltredData] = useState();
  const [searchTerm, setSearchTerm] = useState("");

  // Search functionality -----------
  useEffect(() => {
    setFiltredData(Currencies);
  }, []);

  const handleSearch = () => {
    const filtreData = Currencies?.filter((item) =>
      item.curr.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.conrty.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFiltredData(filtreData);
  };
  useEffect(() => {
    handleSearch();
  }, [searchTerm]);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    handleSearch();
  };
  // ----------------------------

  return (
    <div className="  pt-[1.2rem] px-[1.3rem] h-[100vh] ">
      <TopBar text={t("topbar.Ctoptitle")} />
      <div className=" flex flex-col pt-[1.4rem] ">
        <span className=" relative w-full ">
        <SearchIcon  className= "   text-slate-400 absolute top-5 left-2 scale-75"/>
        
          <input
            value={searchTerm}
            onChange={handleInputChange}
            type="text"
            className={` text-[.75rem] px-8  ${
              isArabic ? "  text-right " : "  text-left"
            }    bg-gray-500 bg-opacity-5  rounded-[.3rem] w-full h-[3.8rem] `}
            placeholder={
              t("profile.searchPlaceh") + ` ` + t("topbar.Ctoptitle")
            }
          />
        </span>
        {FiltredData?.map((item) => (
          <div className=" flex flex-col gap-2  pt-[2rem] ">
            <div
              className={`flex   ${
                isArabic ? " flex-row-reverse" : " flex-row"
              }  w-full py-2 justify-between items-center`}
            >
              <span
                className={`flex ${
                  isArabic ? " flex-row-reverse" : " flex-row"
                } items-center gap-2`}
              >
                <img className=" w-[4rem] " src={item.img} alt="" />
                <span>
                  <h1 className="  text-[.95rem] font-semibold">{item.curr}</h1>
                  <p className=" text-sm text-sec "> {item.conrty} </p>
                </span>
              </span>

              <h1 className=" font-semibold">{item.sign} </h1>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Currency;
