import  Quagga  from "quagga";
export const getCodeBarFromPic = (file) => {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject("File is not provided");
      return;
    }

    const reader = new FileReader();

    reader.onloadend = () => {
      const imageDataUrl = reader.result;

      Quagga.decodeSingle({
        src: imageDataUrl,
        numOfWorkers: 0,
        inputStream: {
          size: 800,
        },
        decoder: {
          readers: [
            'code_128_reader',
            'ean_reader',
            'ean_8_reader',
            'code_39_reader',
            'code_39_vin_reader',
            'codabar_reader',
            'upc_reader',
            'upc_e_reader',
            'i2of5_reader',
            '2of5_reader',
            'code_93_reader'
          ],
        },
      }, (result) => {
        if (result && result.codeResult) {
          resolve(result.codeResult.code);
        } else {
          reject("Unable to decode the barcode");
        }
      });
    };

    reader.readAsDataURL(file);
  });
};