import { React, useContext, useState } from "react";

import rotatePhone from "../../assest/rotatePhone.gif";
import { Usercontext } from "../../context/Usercontexte";
import Barcode from "react-barcode";
import { useTranslation } from "react-i18next";

function DisplayCodebar({ CodeIsopne, setCodeIsopne }) {
  const { User } = useContext(Usercontext);
  const { t, i18n } = useTranslation();
  const [rotate, setRotate] = useState(false);

  return (
    <div
      className={` w-full h-full   bg-black fixed sm:pb-0  pb-[4rem] px-[2.7rem] top-0 z-[70] flex flex-col items-center md:justify-center justify-between   md:pt-0  pt-[4.66rem]  gap-0 md:gap-5 `}
    >
      <span
        onClick={() => setCodeIsopne(!CodeIsopne)}
        className=" cursor-pointer absolute right-4 top-5"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.34498 1.01697C7.43884 0.674031 10.5611 0.674031 13.655 1.01697C15.368 1.20897 16.75 2.55797 16.951 4.27697C17.3179 7.41461 17.3179 10.5843 16.951 13.722C16.8493 14.5572 16.4684 15.3337 15.8702 15.9254C15.2719 16.5171 14.4913 16.8894 13.655 16.982C10.5612 17.326 7.43878 17.326 4.34498 16.982C3.50863 16.8894 2.72806 16.5171 2.1298 15.9254C1.53155 15.3337 1.15069 14.5572 1.04898 13.722C0.682078 10.5847 0.682078 7.41528 1.04898 4.27797C1.15065 3.44284 1.53136 2.66651 2.12941 2.07481C2.72747 1.48311 3.50781 1.11071 4.34398 1.01797L4.34498 1.01697ZM5.46998 5.46997C5.61061 5.32952 5.80123 5.25063 5.99998 5.25063C6.19873 5.25063 6.38936 5.32952 6.52998 5.46997L8.99998 7.93997L11.47 5.46997C11.5386 5.39628 11.6214 5.33718 11.7134 5.29619C11.8054 5.2552 11.9048 5.23315 12.0055 5.23138C12.1062 5.2296 12.2062 5.24812 12.2996 5.28585C12.393 5.32357 12.4778 5.37971 12.549 5.45093C12.6202 5.52215 12.6764 5.60698 12.7141 5.70037C12.7518 5.79376 12.7703 5.89379 12.7686 5.99449C12.7668 6.09519 12.7448 6.19451 12.7038 6.28651C12.6628 6.37851 12.6037 6.46131 12.53 6.52997L10.06 8.99997L12.53 11.47C12.6625 11.6121 12.7346 11.8002 12.7312 11.9945C12.7277 12.1888 12.649 12.3742 12.5116 12.5116C12.3742 12.649 12.1888 12.7277 11.9945 12.7311C11.8002 12.7346 11.6122 12.6624 11.47 12.53L8.99998 10.06L6.52998 12.53C6.46132 12.6037 6.37852 12.6628 6.28652 12.7037C6.19452 12.7447 6.09521 12.7668 5.9945 12.7686C5.8938 12.7703 5.79377 12.7518 5.70038 12.7141C5.607 12.6764 5.52216 12.6202 5.45094 12.549C5.37972 12.4778 5.32358 12.393 5.28586 12.2996C5.24814 12.2062 5.22961 12.1061 5.23139 12.0054C5.23317 11.9047 5.25521 11.8054 5.2962 11.7134C5.33719 11.6214 5.39629 11.5386 5.46998 11.47L7.93998 8.99997L5.46998 6.52997C5.32953 6.38934 5.25064 6.19872 5.25064 5.99997C5.25064 5.80122 5.32953 5.61059 5.46998 5.46997Z"
            fill="white"
          />
        </svg>
      </span>
      <span className=" items-center flex flex-col text-white text-xs gap-1 ">
        <img
          onClick={() => setRotate(!rotate)}
          className="  flex  sm:hidden  w-[4rem] "
          src={rotatePhone}
          alt=""
        />
        Click Here to Rorate
      </span>

      <div
        className={` ${
          rotate ? " rotate-90 scale-150 " : ""
        } duration-200 rounded-xl text-xs    md:w-[90%]  max-w-full p-3 bg-white     sm:h-[74%] h-[10rem]  flex items-center justify-center`}
      >
        <span className="      scale-[.80]   sm:scale-[1.6] ">
          <Barcode
            value={User?.reference}
            fontSize={"18rem"}
            height={"120%"}
            width={"3rem"}
          />
        </span>
      </div>

      <p className="  sm:hidden  flex text-[0.8rem] text-center text-white">
        {t("receipts.barcode")}
      </p>
    </div>
  );
}

export default DisplayCodebar;
