import React, { useContext, useEffect, useState } from "react";
import { themecontext } from "../../context/Themecontexte";
import { useTranslation } from "react-i18next";
import cmaera from "../../assest/camera.svg";
import LoadingBtn from "../LoadingBtn";
import generateAddCardSchema from "../../Validation/AddCardVal";
import { useFormik } from "formik";
import Masseege from "../masseege";
import ImageUpload from "./ImageUpload";
import { HandelUpdateOrAddCard } from "../../Functions/HandelUpdateOrAddCard";
import useOnlineStatus from "../../hooks/useOnlineStatus";
import { CardsContext } from "../../context/Cardscontext";
import { Cropperr } from "../Cropper";

function DetailsInputs({ setEdit, Edit, OneCard }) {
  const { theme } = useContext(themecontext);
  const { setLoyaltyCard } = useContext(CardsContext);
  const { t, i18n } = useTranslation();
  const [msg, setMsg] = useState({ text: "", color: "" });
 

  const [isLoadingCard, setIsLoadingCard] = useState();


// states of the card image before corpping
  const [frontImage, setFrontImage] = useState();
  const [backImage, setBackImage] = useState();

// states of card image last result 'aftr corpping'
  const [ResultOfFrontImg, setResultOfFrontImg] = useState();
  const [ResultOfBackImg, setResultOfBackImg] = useState();
  
  const [isLoading, setisLoading] = useState();
  var isArabic = i18n.language.startsWith("ar");
  const isOnline = useOnlineStatus();

  const AddCardSchema = generateAddCardSchema();
  const formik = useFormik({
    initialValues: {
      storename: "",
      cardnumber: "",
      notes: "",
    },
    validationSchema: AddCardSchema,
    onSubmit,
  });

  // geting the infornation of the card
  useEffect(() => {
    formik.setValues({
      storename: OneCard?.store_name || "",
      cardnumber: OneCard?.code || "",
      notes: OneCard?.notes || "",
    });
    setResultOfBackImg(OneCard?.back_image);
    setResultOfFrontImg(OneCard?.front_image);
  }, [OneCard ,Edit]);

  // updating the lang of the Schema
  useEffect(() => {
    const updatedSchema = generateAddCardSchema();
  }, [i18n.language]);

  const handleFileChange = (e, setImage) => {
    const selectedFile = e.target?.files[0];
    const fileSizeInMB = selectedFile?.size / (1024 * 1024); // Convert bytes to megabytes

    // Check if the file size is less than 5MB
    if (fileSizeInMB >= 5) {
      setMsg({
        text: t("Msg.FileSizeExceed"),
        color: "bg-[#FF1203F5] text-[0.9rem]",
      });
      return;
    }
    setImage(selectedFile);
  };

  // when the submition is complited
  function handleSubmissionComplete() {
    setisLoading(false);
    setTimeout(() => {
      setEdit(false);
    }, 1000);
  }

  const values = formik?.values;

  // Form submission handler
  async function onSubmit() {
    console.log("Submitting...");
    setisLoading(true);
  console.log(ResultOfFrontImg , ResultOfBackImg)

    try {
      await HandelUpdateOrAddCard({
        isOnline,
        id: OneCard.id,
        values,
        CfrontImage:ResultOfFrontImg,
        CbackImage:ResultOfBackImg,
        setLoyaltyCard,
        setMsg,
        setisLoading,
        handleSubmissionComplete,
      });

      console.log("Submission successful.");
    } catch (error) {
      console.error("Error in onSubmit:", error);
      // Handle the error as needed
    } finally {
      setisLoading(false);
    }
  }

  return (
    <form
      className="flex flex-col gap-[1rem] pt-[6rem] px-[1.2rem]"
      onSubmit={formik.handleSubmit}
    >

      <span>
      <h1 className="text-[1.1rem] font-medium">{t("card.Sname")}</h1>

        <input
          disabled={!Edit}
          placeholder="Store name"
          name="storename"
          id="storename"
          value={formik.values.storename}
          type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className={`${!Edit ? ` opacity-50 ` : ""} ${
            !isArabic ? "text-left" : "text-right "
          } h-[3.7rem] px-[1rem] bg-transparent  w-full rounded-[.32rem] border-solid border-[1px]`}
        />
        {formik.touched.storename && formik.errors.storename && (
          <div className="text-sm pt-1 text-red-500">
            {formik.errors.storename}
          </div>
        )}
      </span>

      <span>
      <h1 className="text-[1.1rem] font-medium">{t("card.Cnumber")}</h1>

        <input
          disabled={!Edit}
          placeholder="Card number"
          name="cardnumber"
          value={formik.values.cardnumber}
          type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className={`${!Edit ? ` opacity-50 ` : ""} ${
            !isArabic ? "text-left" : "text-right "
          } h-[3.7rem] px-[1rem] bg-transparent  w-full rounded-[.32rem] border-solid border-[1px]`}
        />
        {formik.touched.cardnumber && formik.errors.cardnumber && (
          <div className=" pt-1 text-sm text-red-500">
            {formik.errors.cardnumber}
          </div>
        )}
      </span>
      <h1 className="text-[1.1rem] font-medium">{t("card.Notes")}</h1>

      <input
        disabled={!Edit}
        placeholder={t("card.Notes")}
        name="notes"
        value={formik.values.notes}
        type="text"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className={`${!Edit ? ` opacity-50 ` : ""} ${
          !isArabic ? "text-left" : "text-right "
        } h-[3.7rem] px-[1rem] bg-transparent  w-full rounded-[.32rem] border-solid border-[1px]`}
      />

      {/* Card Images Display */}
      <h1 className=" text-[1.1rem]  font-medium">{t("card.CardP")} </h1>

      <div
        className={`w-full flex ${
          isArabic ? "flex-row-reverse" : "flex-row"
        } gap-2 justify-center items-center border-[1px] py-10 rounded-lg    `}
      >
        {/* Front Image Upload */}
        <ImageUpload
          Edit={Edit}
          image={ResultOfFrontImg}
          setImage={setResultOfFrontImg}
          handleFileChange={(e) => handleFileChange(e, setFrontImage)}
          label={t("card.Front")}
        />

        {/* Back Image Upload */}
        <ImageUpload
          Edit={Edit}
          image={ResultOfBackImg}
          setImage={setResultOfBackImg}
          handleFileChange={(e) => handleFileChange(e, setBackImage)}
          label={t("card.Back")}
        />
      </div>

      {/* Form Buttons */}
      {Edit && (
        <div className=" w-full pt-5 flex flex-row gap-2 ">
          {/* Cancel Button */}

          <button
            onClick={() => setEdit(false)}
            className={`h-[3.5rem]  text-[1.1rem]  ${
              theme ? "  border-[#F8F8F8] " : " border-[#424242] "
            }    border-solid border-[1px] text-gray-400   font-medium   w-full  rounded-[0.32rem]`}
          >
            {t("card.Cancel")}
          </button>

          {/* Submit Button */}
          <button
            // disabled={isLoading}
            type="submit"
            className=" h-[3.5rem] relative  text-[1.1rem] font-medium text-white w-full bg-main rounded-[0.32rem] "
          >
            {t("card.Submit")}
            <LoadingBtn color={"#fff"} isLoading={isLoading} bg={"bg-main"} />
          </button>
        </div>
      )}
      <Masseege msg={msg} setMsg={setMsg} />

      {/* Afetr we get the first image we will pass it to the Cropperr to cropp it and then
       we get the wanted result  */}

      {backImage && (
        <Cropperr
          isLoading={isLoading}
          setIsLoading={setIsLoadingCard}
          CardImg={backImage}
          setResult={setResultOfBackImg}
          setImage={setBackImage}
        />
      )}

      {frontImage && (
        <Cropperr
          isLoading={isLoading}
          setIsLoading={setIsLoadingCard}
          CardImg={frontImage}
          setResult={setResultOfFrontImg}
          setImage={setFrontImage}
        />
      )}
    </form>
  );
}

export default DetailsInputs;
