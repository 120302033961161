 import img1 from '../img1.png'
 import img2 from '../img2.png'
 import img3 from '../img3.png'
 import img4 from '../img4.png'
import { useTranslation } from 'react-i18next'
 
 
 export const BData = [
    {
      Htitle: <h1 >Ask for<span className='   text-main'> your receipt </span>  from our partner shops </h1>   ,
      Ptitle:"Receive receipts directly from your local shops in seconds",
      img:img1,
      wihteText:true
      
    },
    {
        // [ {text:""} ,{highlight:""}, {text:""}],
        Htitle:<h1 >Receive<span className='   text-main'> Invoices </span>  Anywhere</h1>,
        Ptitle:"Stay on top of your finances with ease. Receive and manage invoices from anywhere",
        img:img2,
        wihteText:false
        
      },
      {
          

       

        Htitle:<h1 > Track <span className='   text-main'> Expenses </span> & Anywhere <span className='   text-main'>Purchases </span></h1>,

        Ptitle:"Take control of your expenses and purchases effortlessly. all at your fingertips.",
        img:img3,
        wihteText:true
        
      },
      {
          

 
        Htitle:<h1 > Your Digital <span className='   text-main'> Loyalty Card </span> & <span className='   text-main'> Offers </span> Hub </h1>,
        
        Ptitle:"Carry your loyalty cards digitally, right on your  phone, and receive exclusive offers from our  partner shops.",
        img:img4,
        wihteText:false
        
        
      },
        
    
    ]