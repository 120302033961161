import React from "react";
import ReactDOM from "react-dom/client";
import "tailwindcss/tailwind.css";
import "./index.css";
import i18n from "./i18n";
import { OpenCvProvider } from "opencv-react";
import Themeprovider from "./context/Themecontexte";
import "@fontsource/cairo"; // Defaults to weight 400
import "@fontsource/cairo/400.css"; // Specify weight
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import Userprovider from "./context/Usercontexte";
import Receiptsprovider from "./context/Receiptscontext";
import CardsProvider from "./context/Cardscontext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <OpenCvProvider>
    <Userprovider>
      <Themeprovider>
        <CardsProvider>
        <Receiptsprovider>
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </Receiptsprovider>
        </CardsProvider> 
      </Themeprovider>
    </Userprovider>
  </OpenCvProvider>
);

serviceWorkerRegistration.register();

reportWebVitals();
