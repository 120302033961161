import { React, useState, useContext } from "react";
import tour from "../assest/tour.svg";
import { themecontext } from "../context/Themecontexte";

function AskForTour({ setState, State, setStep, setTour, Skip }) {
  const { theme } = useContext(themecontext);

  return (
    <div
      className={` duration-300   ${
        State ? "top-[50%]  " : " top-[-100%] -z-10 "
      }  flex  z-[120] fixed h-full w-full relateve `}
    >
      <div
        className={`  flex  fixed h-full w-full absulet ${
          State ? " opacity-50 top-0  " : "opacity-0  top-[-100%] -z-10  "
        }  right-0 bg-black `}
      ></div>

      <div
        className={` text-center ${
          State
            ? "  z-30  opacity-100  top-[100%] "
            : " opacity-30   hidden -z-10    "
        }   duration-300     transform      flex   translate-x-[-50%]   translate-y-[-50%]  top-[50%]  h-[20rem]    left-[50%]   fixed  w-[80%]      flex-col items-center justify-between  pt-[1.6rem] pb-[3.5rem] px-[.74rem]    rounded-[.31rem]  ${
          theme ? " bg-white" : " bg-[#212121]"
        }   `}
      >
        <div
          className=" w-[4rem] h-[4rem]   bg-no-repeat bg-cover  "
          style={{ backgroundImage: `url(${tour})` }}
        ></div>
        <div className=" flex flex-col gap-1">
          <h1
            className={`  ${
              theme ? "text-[#25215e]" : " text-white"
            } text-[1.1rem] font-semibold  text-center `}
          >
            Let’s take a quick tour together
          </h1>
          <p className=" text-sm text-sec">
            First time using the app let’s take a <br /> tour together
          </p>
        </div>
        <button
          onClick={() => {
            setStep(0);
            setState(!State);
          }}
          className=" w-full bg-main text-[1.1rem] font-semibold  text-white h-[3.5rem] rounded-[.31rem] "
        >
          Take Tour
        </button>
        <p onClick={Skip} className=" text-[#C4C4C4]  absolute bottom-4 ">
          Skip
        </p>
      </div>
    </div>
  );
}

export default AskForTour;
