// Importing necessary modules and dependencies
import { React, useState, useContext, useEffect } from "react";
import TopBar from "../../compenent/TopBar";
import CloseBtn from "../../assest/CloseBtn.svg";
import { useFormik } from "formik";
import LoadingBtn from "../../compenent/LoadingBtn";
import Masseege from "../../compenent/masseege";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getCodeBarFromPic } from "../../Functions/getCodeBarFromPic";
import { CardsContext } from "../../context/Cardscontext";
import AddCardOrUpdate from "../../API/CardAPI";
import { addCardOrUpdateToLocalDB } from "../../DB/LoyaltyCardDB";
import cameraImg from "../../assest/camera.svg";
import useOnlineStatus from "../../hooks/useOnlineStatus";
import { Cropperr } from "../Cropper";
import { themecontext } from "../../context/Themecontexte";
import generateAddBCardSchema from "../../Validation/AddBcardVal";
import { InputField } from "./InputField";
import ImageUpload from "../LoyaltyCard/ImageUpload";
import DetailsInputs from "./DetailsInputs";
// AddCardManully Component
function AddBankingCard() {
  // State variables
  const [frontImage, setFrontImage] = useState(null);
  const [CfrontImage, setCfrontImage] = useState(null);
  const [backImage, setBackImage] = useState(null);
  const [CbackImage, setCbackImage] = useState(null);
  const [msg, setMsg] = useState({ text: "", color: "" });
  const [Code, setCode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const isOnline = useOnlineStatus();

  const { setLoyaltyCard, card } = useContext(CardsContext);

  const { id } = useParams();
  const location = useLocation();
  const oneCard = card?.find((item) => item.id == id);
  const searchParams = new URLSearchParams(location.search);
  const brand = searchParams.get("brand");

  const { theme } = useContext(themecontext);
  // lang
  const { t, i18n } = useTranslation();
  let isArabic = i18n.language.startsWith("ar");

 
  return (
    <div
      className={`fixed top-0 right-0 ${
        theme ? "bg-white" : " bg-[#212121] "
      }   w-full h-full z-50 overflow-auto px-[1.2rem] pt-[1.8rem] flex flex-col`}
    >
      <TopBar text={id ? t("card.EditC") : t("card.addC")} />

     <DetailsInputs Add={true} Edit={true}/>

     
      {backImage && (
        <Cropperr
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          CardImg={backImage}
          setResult={setCbackImage}
          setImage={setBackImage}
        />
      )}

      {frontImage && (
        <Cropperr
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          CardImg={frontImage}
          setResult={setCfrontImage}
          setImage={setFrontImage}
        />
      )}
      {/* Message Component */}
      <Masseege msg={msg} setMsg={setMsg} />
    </div>
  );
}

// Exporting the AddCardManully component
export default AddBankingCard;
