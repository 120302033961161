import React, { useContext, useState, useRef } from "react";
import { themecontext } from "../../context/Themecontexte";
import OutlookImg from "../../assest/outLook.png";
import GmailImg from "../../assest/gmail.png";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LoopIcon from "@mui/icons-material/Loop";
import DeleteIcon from "@mui/icons-material/Delete";
import { unlinkEmail } from "../../API/importEmails";
import { useClickOutside } from "../../hooks/useClickOutside";
import Modal from "../UI/Modal";
function Account({ email, id, UnLinkAccount, integration_name ,setAccounts ,Accounts  }) {
  const [isOpen, setIsOpen] = useState(false);
  const [Delete, setDelete] = useState(false);
  const { theme } = useContext(themecontext);

  const OptionRef = useRef();
  useClickOutside(OptionRef, () => setIsOpen(false));

  // UnLink Account
  function HandelUnLinkAccount() {
    try {
      unlinkEmail(id, integration_name == "GMAIL" ? "gmail" : "outlook");
      const filteredAccounts = Accounts?.filter(account => account.external_account_id != id  );
      setAccounts(filteredAccounts);
    } catch (error) {}
  }

  return (
    <span className=" flex flex-row items-center justify-between w-full h-[3.6rem]  border-solid  px-[.8rem]  py-[.9rem] border-[1px] rounded-md ">
      <div className=" flex gap-3 justify-center items-center">
        <img
          className=" h-[1.8srem]  "
          src={integration_name == "GMAIL" ? GmailImg : OutlookImg}
          alt=""
        />
        <span>
          <h1 className=" text-[.9rem] font-semibold ">{email}</h1>
          <p className=" text-sec text-[.7rem] ">Synced</p>
        </span>
      </div>
      <span
        ref={OptionRef}
        onClick={() => setIsOpen(!isOpen)}
        className=" relative"
      >
        <MoreVertIcon />
        <div
          className={`text-sm absolute flex flex-col rounded-md right-5 duration-150  justify-between ${
            isOpen ? " h-[4rem]  py-1 border-[1px]" : " h-0 py-0 border-[0px]"
          }    overflow-hidden  border-solid  top-5 w-[6rem]   ${
            !theme ? " bg-[#212121] " : " bg-white"
          } `}
        >
          <h1 className="  active:bg-opacity-25 flex flex-row gap-1 items-center">
            {" "}
            <LoopIcon className="  scale-75 " /> Sync now
          </h1>
          <span className=" w-full h-[1px] bg-gray-200 "></span>

          <h1
            onClick={() => setDelete(true)}
            className="  active:bg-opacity-25 flex flex-row gap-1 items-center"
          >
            <DeleteIcon className="  scale-75 " /> Remove
          </h1>
        </div>
      </span>
      <Modal isOpen={Delete}>
        <div className=" text-center  flex flex-col items-center w-[70vw] h-full px-5 gap-5 ">
       <span className=" h-[10rem] ">

       </span>
        <span>
        <h1 className=" text-lg font-bold ">Delete account </h1>
          <p className="  pt-1  text-gray-400 ">
            are you sure you want to delete this account
          </p>
        </span>
          
          <div className=" flex  w-full justify-between gap-3">
            <button onClick={()=>setDelete(false)} className="  w-full py-2  rounded-md bg-gray-300 text-gray-500 ">
              No
            </button>
            <button onClick={HandelUnLinkAccount} className="  w-full py-2  rounded-md bg-red-500 text-white ">
              Yes
            </button>
          </div>
        </div>
      </Modal>
    </span>
  );
}

export default Account;
