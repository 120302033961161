import Cookies from "js-cookie";
import axios from "axios";
 

const apiUrl = 'https://django-cloudrun-5cc2zmgpqq-lz.a.run.app/api/clients/fidelity_cards';

const AddCardOrUpdate = async (id, formData,  method = 'post') => {  try {
 
  const token = Cookies.get("access_token");
    const url = id ? `${apiUrl}/${id}` : apiUrl;
    const response = await axios[method](url, formData, {  headers: {
      Authorization: token,
    }, });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export default AddCardOrUpdate;



// Geting the card details
export const GetFidelity_card = (
  id,
  selectedCard,
  setFrontImage,
  setBackImage,
  setValues
) => {
  const token = Cookies.get("access_token");
  if (id && !selectedCard) {
    axios
      .get(`/api/clients/fidelity_cards/${id}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        const { data } = response;
        setValues({
          storename: data?.store_name,
          cardnum: data?.code,
          notes: data?.notes,
        });
        console.log(data);
        setFrontImage(data?.front_image);
        setBackImage(data?.back_image);
      })
      .catch((error) => {
        console.error();
      });
  }
};
