import { React, useEffect, useState } from "react";
import digitalR from "../../assest/digitalR.svg";
import physicalR from "../../assest/physicalR.svg";
import LinedChart from "./LinedChart";
import { useTranslation } from "react-i18next";

function ReceiptType({ Period, filteredData }) {
  // State variables for physical and digital data, and total physical and digital receipts
  const [Physical, setPhysical] = useState();
  const [Digital, setDigital] = useState();
  const [totalDandPRec, settotalDandPRec] = useState({
    physical: "",
    digital: "",
  });

  // Translation function for internationalization
  const { t, i18n } = useTranslation();

  // Check if the language is Arabic
  const isArabic = i18n.language.startsWith("ar");

  // Effect hook to update data based on the selected period
  useEffect(() => {
    // Helper function to determine the period based on the timestamp
    function getPeriod(timestamp) {
      const date = new Date(timestamp);
      // Return the appropriate value based on the selected period
      return Period === "Yesterday" || Period === "Today"
        ? date.getHours()
        : Period === "Last year" || Period === "This year"
        ? date.getMonth()
        : date.getDate();
    }

    // Object to store receipts data grouped by period and file type
    const receiptType = {};

    // Iterate through filtered data to categorize receipts
    filteredData?.forEach((entry) => {
      const { created_at, file_type } = entry;
      const period = getPeriod(created_at);
      // Determine file type based on the extension
      const fileType =
        file_type === "png" ? "physical" : file_type === "pdf" ? "digital" : "unknown";

      // Check if the file type is not unknown
      if (fileType !== "unknown") {
        if (!receiptType[period]) {
          // Initialize the period if not already present
          receiptType[period] = {
            digital: 0,
            physical: 0,
            date: "",
          };
        }

        // Increment the count for the corresponding file type
        receiptType[period][fileType]++;
        // Store the date and created_at in the result array
        receiptType[period].date = created_at;
      }
    });

    // Calculate total digital and physical receipts
    const { digital, physical } = Object.values(receiptType).reduce(
      (totals, PeriodData) => {
        totals.digital += PeriodData.digital;
        totals.physical += PeriodData.physical;
        return totals;
      },
      { digital: 0, physical: 0 }
    );

    // Convert the values of receiptType into an array
    const result = Object.values(receiptType);

    // Set the total digital and physical receipts
    settotalDandPRec({
      physical: physical,
      digital: digital,
    });

    // Helper function to update chart data for a specific type (physical or digital)
    const updateChartData = (setType, result) => {
      setType({
        data: result.map((i) => i.digital),
        time: result.map((i) => i.date),
      });
    };

    // Update chart data for both physical and digital receipts
    updateChartData(setDigital, result);
    updateChartData(setPhysical, result);
  }, [Period]);

 
 
  return (
    <div className=" w-full flex flex-col pb-7  ">
      <LinedChart
        Physical={Physical}
        Digital={Digital}
        state={"ReceiptType"}
        Period={Period}
        colors={["#FFCC00", "#06C671"]}
      />
      <div
        className={`flex ${
          isArabic ? " flex-row-reverse" : " flex-row"
        }  w-full justify-between   pt-2    text-left`}
      >
        <span
          className={` w-[80%]   py-4 flex ${
            isArabic ? " flex-row-reverse" : " flex-row"
          } gap-2  text-[.8rem]  items-center justify-start  font-[400] `}
        >
          <span className={`w-3 h-3 rounded-full  bg-main  `}></span>{" "}
          <h1 className="  text-[.96rem]  "> {t("Stats.DR")} </h1>
        </span>

        <span
          className={` w-full  justify-end py-4    flex  ${
            isArabic ? " flex-row-reverse" : " flex-row"
          } gap-2 text-[.8rem]  text-center  items-center  font-[400] `}
        >
          <span className={`w-3 h-3 rounded-full bg-[#FFCC00] `}></span>{" "}
          <h1 className="  text-[.96rem]  ">{t("Stats.PR")}</h1>
        </span>
      </div>

      <div className=" w-full flex flex-row justify-between pt-[1.5rem] gap-9    text-center  ">
        <div className=" flex flex-col justify-center w-full  items-center  border-gray-200 pt-[1.48rem] px-[.8rem] pb-[.7rem]  border-solid border-[1px] rounded-[.32rem]  gap-[.6rem] ">
          <img
            src={physicalR}
            className=" bg-[#E5FFF4] w-[3rem] p-[.2rem] rounded-full h-[3rem]   "
            alt=""
          />

          <h1 className=" text-[.9rem] leading-5 font-medium ">
            {t("Stats.totalD")}
          </h1>
          <p className=" text-[1.1rem] font-[500] ">
            {totalDandPRec?.digital}{" "}
          </p>
        </div>
        <div className=" flex flex-col justify-center w-full  items-center  border-gray-200 pt-[1.48rem] px-[.8rem] pb-[.7rem]  border-solid border-[1px] rounded-[.32rem]  gap-[.8rem] ">
          <img
            src={digitalR}
            className=" bg-[#FFF3D0] w-[3rem] p-[.4rem] rounded-full h-[3rem]   "
            alt=""
          />
          <h1 className=" text-[.9rem] leading-5 font-medium ">
            {t("Stats.totalP")}
          </h1>
          <p className=" text-[1.1rem] font-[500] ">
            {totalDandPRec?.physical}{" "}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ReceiptType;
