import { React, useEffect, useState, useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { themecontext } from "../../context/Themecontexte";
import { ProcessChartData } from "../../Functions/ProcessChartData";
import   { BillingChartOptions } from "../../assest/data/ChartOption";
const BillingChart = ({ Refund, Paid, UnPaid, PartPaid, state, Period, setTotals }) => {
  // State for processed data for different billing categories
  const [PDatachart, setPDatachart] = useState();
  const [UPDatachart, setUPDatachart] = useState();
  const [RDatachart, setRDatachart] = useState();
  const [PPDatachart, setPPDatachart] = useState();

  // Translation and theme context
  const { t, i18n } = useTranslation();
  const { theme } = useContext(themecontext); // Replace with actual context

  // Check if the language is Arabic
  const isArabic = i18n.language.startsWith("ar");

  // Process and update data when 'Paid' array changes
  useEffect(() => {
    const { totalAmount, processedData } = ProcessChartData(Paid, Period,true);
    setPDatachart(processedData);
    console.log("paid " + totalAmount);
    setTotals((prev) => ({ ...prev, Paid: totalAmount }));
  }, [Paid]);

  // Process and update data when 'UnPaid' array changes
  useEffect(() => {
    const { totalAmount, processedData } = ProcessChartData(UnPaid, Period,true);
    setUPDatachart(processedData);
    setTotals((prev) => ({ ...prev, Unpaid: totalAmount }));
  }, [UnPaid]);

  // Process and update data when 'PartPaid' array changes
  useEffect(() => {
    const { totalAmount, processedData } = ProcessChartData(PartPaid, Period,true);
    setPPDatachart(processedData);
    setTotals((prev) => ({ ...prev, Parti: totalAmount }));
  }, [PartPaid]);

  // Process and update data when 'Refund' array changes
  useEffect(() => {
    const { totalAmount, processedData } = ProcessChartData(Refund, Period,true);
    setRDatachart(processedData);
    setTotals((prev) => ({ ...prev, Refund: totalAmount }));
  }, [Refund]);

  // Array of indices
  const index = [0, 1, 2, 3];

  // Create billing chart options
  const Option = BillingChartOptions(
    state,
    theme,
    PDatachart,
    UPDatachart,
    RDatachart,
    PPDatachart,
  );

  return (
    <div className=" relative w-full h-full  mt-[.7rem] ">
      <div className="  w-full flex  pb-[1.3rem]  rounded-[.32rem] pt-[1.7rem] pl-[0rem] pr-[1.8rem] border-solid border-[1px]    flex-col   h-[300px] ">
        <div className=" flex flex-row items-center w-full gap-1 justify-between">
          <span
            className={`w-full  justify-end flex flex-row gap-1 items-center    font-[400]`}
          >
            <span
              className={`w-3 h-3 rounded-full  ${
                state == 0
                  ? " bg-main"
                  : state == 1
                  ? " bg-[#F42929] "
                  : state == 2
                  ? " bg-[#FF8125] "
                  : " bg-[#29D7FD] "
              }    `}
            ></span>{" "}
            <h1>
              {state == 0
                ? t("Stats.PB")
                : state == 1
                ? t("Stats.TU")
                : state == 2
                ? t("Stats.PB")
                : t("Stats.RB")}
            </h1>
          </span>
        </div>

        <ReactApexChart
          options={Option}
          series={Option.series}
          type="area"
          height={"100%"}
          width={"102%"}
        />
      </div>
      <span className=" w-full justify-center items-center pt-[.7rem]  flex flex-row">
        {index.map((i) => (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 7 7"
            fill="none"
          >
            <circle
              cx="3.36435"
              cy="3.604"
              r="3"
              fill={i == state ? "#06C671" : "#F0EFEF"}
            />
          </svg>
        ))}
      </span>
    </div>
  );
};

export default BillingChart;
