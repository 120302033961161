import { React, useState, useEffect } from "react";
import logo from "../../src/assest/ftrclient.png";
import DownloadAppPhone from "../../src/assest/DownloadAppPhone.gif";
import openAppPhone from "../../src/assest/openAppPhone.gif";
import loadingAnimation from "../../src/assest/loadingAnimation.svg";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import useOnlineStatus from "../hooks/useOnlineStatus";
import { usePWAInstall } from 'react-use-pwa-install'
function InstallPage() {
  const [showOpenBtn, setshowOpenBtn] = useState(false);
  const [showLoadingInstall, setshowLoadingInstall] = useState(false);
  const [isPwaInstalled, setIsPwaInstalled] = useState(false);
  const [isStandaloneMode, setIsStandaloneMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [progress, setProgress] = useState(30);
  const isOnline = useOnlineStatus();
  const install = usePWAInstall();

  useEffect(() => {
    if (progress >= 100) {
      const orientation = window.orientation;
      setshowOpenBtn(true);
      setshowLoadingInstall(false);
      if (!orientation) {
        window.location.reload();
      }
    }
  }, [progress]);

  useEffect(() => {
    if (isPwaInstalled) {
      setshowOpenBtn(true);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    // Check if the PWA is installed in the component's lifecycle

    // Method 1: Using window.matchMedia
    if (window.matchMedia("(display-mode: standalone)").matches) {
      setIsStandaloneMode(true);
    }

    // Method 2: Using navigator.standalone (iOS only)
    if (window.navigator.standalone) {
      setIsStandaloneMode(true);
    }
  }, []);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      // Store the event for later use
      window.deferredPrompt = e;
      // Check if the app is already installed (using a more reliable method)
      if (
        window.matchMedia("(display-mode: standalone)").matches ||
        window.navigator.standalone === true
      ) {
      } else {
      }
    };
    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  useEffect(() => {
    const handleBeforeInstallPrompt = (event) => {
      // Prevent the default prompt
      event.preventDefault();

      // Update the state to indicate that the PWA can be installed
      setIsPwaInstalled(true);
    };
    const handleAppInstalled = () => {
      // Update the state to indicate that the PWA is installed
      setIsPwaInstalled(true);
    };
    // Check if the beforeinstallprompt event is supported
    if ("onbeforeinstallprompt" in window) {
      window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
      // Check if the app is already installed
      window.addEventListener("appinstalled", handleAppInstalled);
    } else {
      console.log("beforeinstallprompt event not supported");
    } 
    return () => {
      // Remove event listeners when the component unmounts
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
      window.removeEventListener("appinstalled", handleAppInstalled);
    };
  }, []); // Emt runs once on mount

  
  const handleInstallClick = async () => {
    const deferredPrompt = window.deferredPrompt;

    if (isOnline) {
      if (deferredPrompt) {
        try {
          await deferredPrompt.prompt();
          const choiceResult = await deferredPrompt.userChoice;

          if (choiceResult.outcome === "accepted") {
            setshowLoadingInstall(true);

            const interval = setInterval(() => {
              // Simulating progress update
              setProgress((prevProgress) =>
                prevProgress < 100 ? prevProgress + 15 : 100
              );
            }, 2300);

            // Clean up the interval when the component is unmounted
            return () => clearInterval(interval);
          }
        } catch (error) {
          console.error("Error during installation prompt:", error);
        } finally {
          // Clean up the deferredPrompt
          window.deferredPrompt = null;
        }
      }
    } else {
      console.log("offline");
    }
  };

  const openApp = () => {
    // Replace 'your-pwa-url' with the actual URL of your PWA
    const pwaUrl = "https://ftrclient.fintechracy.org/";
    // Open the PWA in a new window
    window.open(pwaUrl, "_blank");
  };
 
  return (
    <>

    <div
      className={`    text-center  ${
        isStandaloneMode ? " hidden" : " flex"
      }  w-full h-full fixed top-0 right-0 bg-white z-[60]      flex-col items-center  pt-[6rem]   `}
    >
      {isLoading && (
        <span className="  justify-center  items-center flex fixed w-full h-full top-0 right-0 z-50 bg-white">
          <img className=" h-[4rem] w-[4rem]  " src={loadingAnimation} alt="" />
        </span>
      )}
      <img className="  h-[3.5rem] w-[3.5rem]  " src={logo} alt="logo" />

      <div
        className={`  flex items-center    ${
          showOpenBtn || !isPwaInstalled ? "  flex-col-reverse" : "flex-col"
        } `}
      >
        <span>
          <h1
            className={` ${
              showOpenBtn || !isPwaInstalled ? " pt-0" : "pt-[2.6rem]"
            }  text-[1.1rem] font-semibold `}
          >
            Welcome {showOpenBtn || (!isPwaInstalled && " Back ")} To FTR Client
          </h1>
          <p className="   text-sec pt-4">
            {showOpenBtn || !isPwaInstalled ? (
              <>You have already downloaded the app.</>
            ) : (
              <>
                Hello Planet Protector! Let’s end <br /> paper receipt and save
                our Home!
              </>
            )}
          </p>
        </span>
        <img
          className={` duration-300  md:w-[31rem] w-[100%]   ${
            showOpenBtn || !isPwaInstalled ? "  scale-100 " : "scale-150"
          }  `}
          src={showOpenBtn || !isPwaInstalled ? openAppPhone : DownloadAppPhone}
          alt="DownloadAppPhone IMAGE"
        />
      </div>

      <>
        {!showLoadingInstall ? (
          <>
          <button
            id="install"
            disabled={!isOnline}
            className={` z-20  mt-[2.1rem] md:w-[20%] w-[50%]  p-3 px-5 rounded-full  font-semibold  text-[1.1rem]  text-white ${
              isOnline ? "bg-main" : "bg-gray-300"
            }  `}
            onClick={
              showOpenBtn || !isPwaInstalled ? openApp : handleInstallClick
            }
          >
         {isOnline ? (  showOpenBtn || !install
              ? "Open the app"
              : "Download the app")  :  <WifiOffIcon className=" text-gray-500"/>  }
          </button>
          <button onClick={()=> setIsStandaloneMode(true) } className=" text-gray-500 pt-[.8rem] text-sm ">Proceed directly with the browser</button>
          </>
        ) : (
          <div className="h-[3rem] mt-[2.1rem] z-20 flex flex-row items-center gap-4 justify-center  md:w-[20%] w-[50%]  text-main font-extrabold ">
            <div className="  z-20  h-full w-full  rounded-full flex flex-row  font-semibold  text-[1.1rem] border-solid border-[1px]  border-[#E6E6E6]  ">
              <div
                className="   bg-main h-full rounded-full duration-300"
                style={{ width: `${progress}%` }}
              ></div>
            </div>
            <div> {progress}% </div>
          </div>
        )}
      </>
    </div>
    </>
  );
}

export default InstallPage;