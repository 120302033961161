import * as yup from 'yup';
import i18n from '../i18n';

const generateAddBCardSchema = () => {
  return yup.object().shape({
    cardholdername: yup.string().required(i18n.t('inputsVal.cardholderName')),
    cardnum: yup.string().matches(/^[0-9]{4}[\s]*[0-9]{4}[\s]*[0-9]{4}[\s]*[0-9]{4}$/, i18n.t('inputsVal.invalidCardNumber')).required(i18n.t('inputsVal.cardNumberRequired')),
    expdate: yup.string().matches(/^(0[1-9]|1[0-2])\/[0-9]{2}$/, i18n.t('inputsVal.invalidExpirationDate')).required(i18n.t('inputsVal.expirationDateRequired')),
    cvv: yup.string().matches(/^[0-9]{3,4}$/, i18n.t('inputsVal.invalidCVV')).required(i18n.t('inputsVal.cvvRequired')),
  });
};

export default generateAddBCardSchema;