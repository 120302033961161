import { PatternFormat } from "react-number-format";

export const InputField = ({
  label,
  placeholder,
  onBlur,
  onChange,
  value,
  error,
  touched,
  isArabic,
  id,
  Edit,
  name,
  format,
  type,
  max
}) => (
  <>
    {format ? (
      // if the id of the input is expdate so it's will be formatted ##/##
      <div className="flex flex-col w-full">
        <h1 className="font-medium pb-2 pt-[1.5rem] text-[1rem]">{label}</h1>
        <PatternFormat
          name={name}
          placeholder={placeholder}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          className={`${isArabic ? "text-right" : "text-left"} ${
            error && touched
              ? "border-[#f14e4e] focus-visible:ring-2 focus-visible:ring-red-500  border-[2px]"
              : " focus-visible:ring-main "
          } focus-visible:ring-2 focus-visible:outline-none text-sec w-full h-[4rem] bg-transparent px-[1rem] text-sm border-solid border-[1px] rounded-[0.32rem]`}
          id={id}
          format={format}
        />
        {error && touched && (
          <p className="pt-1 px-1 text-xs text-red-400">{error}</p>
        )}
      </div>
    ) : (
      // if its not its will be normal input without formating
      <div className="flex flex-col w-full">
        <h1 className="font-medium pb-2 pt-[1.5rem] text-[1rem]">{label}</h1>
        <input
          name={name}
          placeholder={placeholder}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          max={max}
          maxLength={max}
          className={`${isArabic ? "text-right" : "text-left"} ${
            error && touched
              ? "border-[#f14e4e] focus-visible:ring-2 focus-visible:ring-red-500  border-[2px]"
              : " focus-visible:ring-main "
          } focus-visible:ring-2 focus-visible:outline-none text-sec w-full h-[4rem] bg-transparent px-[1rem] text-sm border-solid border-[1px] rounded-[0.32rem]`}
          id={id}
          type={type}
        />
        {error && touched && (
          <p className="pt-1 px-1 text-xs text-red-400">{error}</p>
        )}
      </div>
    )}
  </>
);
