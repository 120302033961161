import React from "react";
import Cookies from "js-cookie";
import axios from "axios";
import Dexie from "dexie";
import deleteCardImg from '../../assest/delete_card.svg'
import { deleteCardFromLocalDB } from "../../DB/LoyaltyCardDB";
import { useNavigate } from "react-router-dom";
import { deleteBankingCardFromLocalDB } from "../../DB/BankingCardDB";

function DeleteCard({
  bankCard,
  setDeleting,
  theme,
  setCards,
  t,
  setisLoading,
  id,
  navTo
}) {
  const navto = useNavigate();

 
  

  // Function to delete a card
  async function deleteCard(id) {
    console.log("deleteCardid")
    console.log(id)
    setisLoading(true);
    const token = Cookies.get("access_token");
    setCards((prevCards) => prevCards?.filter((i) => i.id != id));
    await  deleteBankingCardFromLocalDB(id);
    if(bankCard){
      setTimeout(() => {
          setisLoading(false);
      navto(navTo);
      }, 1000);
    
    }else{
       axios
      .delete(`/api/clients/fidelity_cards/${id}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        setisLoading(false);
        navto(navTo);
      async function handeleDeleteCard (){
          try {
          await deleteCardFromLocalDB(id);
        } catch (error) {
          console.error("Error calling deleteCardFromLocalDB:", error);
        }
      }
      handeleDeleteCard()
      }).catch((err)=>{
        navto(navTo);
      } )
      .then((error) => {
        setisLoading(false);
        navto(navTo);
      });
    }
   
  }

  return (
    <div className=" z-50 fixed w-full h-full top-0 right-0 ">
      <div className=" absolute top-0 right-0 bg-black w-full h-full opacity-30"></div>
      <div
        className={`w-[70%] px-5 py-3 text-[.95rem] text-center  gap-2  flex flex-col ${
          theme ? " bg-white" : " bg-[#212121] "
        } shadow-lg fixed top-[50%] transform -translate-y-[50%] translate-x-[50%] right-[50%]   rounded-md`}
      >
        <img className=" h-[10rem]  " src={deleteCardImg} alt="" />
        <h1 className=" font-bold text-lg"> {t("card.DeleteC")} </h1>

        <h1> {t("card.Sure")} </h1>
        <span className=" flex gap-3 w-full flex-row justify-between items-center h-full ">
        
          <button
            onClick={(e) => {
              setDeleting(false);
              e.preventDefault();
            }}
            className=" w-full  rounded-md h-10  text-gray-500  bg-gray-300   "
          >
            {t("card.no")}
          </button>
          <button
            onClick={(e) => {
              setDeleting(false);
              deleteCard(id);
            }}
            className=" w-full rounded-md h-10  bg-red-400 text-white font-[600] "
          >
            {t("card.yes")}
          </button>
        </span>
      </div>
    </div>
  );
}

export default DeleteCard;
