// Importing necessary modules and dependencies
import { createContext, useEffect, useState, useContext } from "react";
import Dexie from "dexie";
import axios from "axios";
import Cookies from "js-cookie";
import { Usercontext } from "./Usercontexte";
import { checkAndAddCardsToDB } from "../DB/LoyaltyCardDB";
import { UplodingOfflineCard } from "../Functions/UplodingOfflineCard";
import useOnlineStatus from "../hooks/useOnlineStatus";
import { BankingCardsData } from "../assest/data/BnkingCarts";   

// Creating a context for managing loyaltycards state
export const CardsContext = createContext();

// CardsProvider Component
const CardsProvider = ({ children }) => {
  // State variables
  const [Loyalty_Card, setLoyaltyCard] = useState([]);
  const { User } = useContext(Usercontext);
  const [BankingCardsList, setBankingCardsList] = useState();

  const isOnline = useOnlineStatus();
  // Creating the LocalDB using Dexie
  const Database = new Dexie("Database");
  Database.version(1).stores({
    receipts: "id",
    users: "id",
    loyaltycards: "id",
    bankingcards: "id",
  });
 

  useEffect(() => {
    UplodingOfflineCard(Loyalty_Card);
  }, [isOnline]);
 

  // Fetching loyaltycards from the local DB OR Server
  useEffect(() => {
    const fetchLocalData = async () => {
      try {
        const allCards = await Database?.loyaltycards?.toArray();
        if (allCards.length > 0) {
          // geting all the added loyaltycards offline and send them to the server
          setLoyaltyCard(allCards.reverse());
        } else {
          const token = Cookies.get("access_token");

          axios
            .get("/api/clients/fidelity_cards", {
              headers: {
                Authorization: token,
              },
            })
            .then((response) => {
              const { data } = response;
              setLoyaltyCard(data.reverse());
            })
            .catch((error) => {
              console.error("Error fetching loyaltycards from the server:", error);
            });
        }
      } catch (error) {
        console.error("Error fetching loyaltycards from local DB:", error);
      }
    };
    if (User) {
      fetchLocalData();
    }
  }, [User]);

  // Saving the loyaltycards in the local DB
  useEffect(() => {
    if (Loyalty_Card) {
      checkAndAddCardsToDB(Loyalty_Card);
    }
  }, [User, Loyalty_Card]);

  // Providing the CardsContext to the component tree



   // Fetching loyaltycards from the local DB OR Server
   useEffect(() => {
    const fetchLocalData = async () => {
      try {
        const allCards = await Database?.bankingcards?.toArray();
        if (allCards.length > 0) {
          // geting all the added loyaltycards offline and send them to the server
          setBankingCardsList(allCards.reverse());
        } 
        else{
          setBankingCardsList([]);
        }
        // else {
        //   const token = Cookies.get("access_token");

        //   axios
        //     .get("/api/clients/fidelity_cards", {
        //       headers: {
        //         Authorization: token,
        //       },
        //     })
        //     .then((response) => {
        //       const { data } = response;
        //       setLoyaltyCard(data.reverse());
        //     })
        //     .catch((error) => {
        //       console.error("Error fetching loyaltycards from the server:", error);
        //     });
        // }
      } catch (error) {
        console.error("Error fetching loyaltycards from local DB:", error);
      }
    };
    if (User) {
      fetchLocalData();
    }
  }, [User]);
  return (
    <CardsContext.Provider
      value={{
        Loyalty_Card,
        setLoyaltyCard,
        setBankingCardsList,
        BankingCardsList,

      }}
    >
      {children}
    </CardsContext.Provider>
  );
};

// Exporting the CardsProvider component
export default CardsProvider;
