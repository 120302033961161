import { React, useEffect, useState } from "react";
import billimg from "../../assest/money.png";
import BillingChart from "./BillingChart";
import RefBill from "../../assest/RefBill.png";
import TotalBill from "../../assest/TotalBill.png";
import PaidBill from "../../assest/PaidBill.png";
import { useTranslation } from "react-i18next";

function Billing({ filteredData, Period }) {
  const [state, setState] = useState(0);
  const [Paid, setPaid] = useState();
  const [UnPaid, setUnPaid] = useState();
  const [PartPaid, setPartPaid] = useState();
  const [Refund, setRefund] = useState();
  const [Totals, setTotals] = useState({
    Paid: "",
    Unpaid: "",
    Refund: "",
    Parti: "",
  });
  const { t, i18n } = useTranslation();
  var isArabic = i18n.language.startsWith("ar");
  var isEn = i18n.language.startsWith("en");

  useEffect(() => {
    function getPeriod(timestamp) {
      const date = new Date(timestamp);
      if (Period === "Yesterday" || Period === "Today") {
        return date.getHours();
      } else if (Period === "Last year" || Period === "This year") {
        return date.getMonth();
      } else {
        return date.getDate();
      }
    }

    const paidReceipts = [];
    const unpaidReceipts = [];
    const refundedReceipts = [];
    const partiallyPaidReceipts = [];

    // Helper function to categorize receipts
    function categorizeReceipt(receipt) {
      const period = getPeriod(receipt.created_at);

      if (receipt?.json?.credit === 0) {
        // Paid
        if (!paidReceipts[period]) {
          paidReceipts[period] = {
            total: receipt?.json?.total,
            date: receipt.created_at,
          };
        } else {
          paidReceipts[period].total += receipt?.json?.total;
          paidReceipts[period].date = receipt.created_at;
        }
      } else if (receipt?.json?.credit === receipt?.json?.total) {
        // Unpaid
        if (!unpaidReceipts[period]) {
          unpaidReceipts[period] = {
            total: receipt?.json?.total,
            date: receipt.created_at,
          };
        } else {
          unpaidReceipts[period].total += receipt?.json?.total;
          unpaidReceipts[period].date = receipt.created_at;
        }
        
      } else if (
        receipt?.json?.credit < receipt?.json?.total &&
        receipt?.json?.total !== 0
      ) {
        // Partially Paid
        if (!partiallyPaidReceipts[period]) {
          partiallyPaidReceipts[period] = {
            total: receipt?.json?.total,
            date: receipt.created_at,
          };
        } else {
          partiallyPaidReceipts[period].total += receipt?.json?.total;
          partiallyPaidReceipts[period].date = receipt.created_at;
        }
      } else if (receipt?.json?.total === 0) {
        // Refunded
        if (!refundedReceipts[period]) {
          refundedReceipts[period] = {
            total: receipt?.json?.total,
            date: receipt.created_at,
          };
        } else {
          refundedReceipts[period].total += receipt?.json?.total;
          refundedReceipts[period].date = receipt.created_at;
        }
      }
    }

    // Function to count paid and unpaid receipts per Period
    function countReceiptsPerHour(filteredData) {
      const countPerHour = [];

      filteredData?.forEach((receipt) => {
        const period = getPeriod(receipt.date); // You can adjust the period as needed

        countPerHour.push({
          period,
          created_at: receipt.date,
          total: receipt.total,
        });
      });

      return countPerHour;
    }

    // Call the categorizeReceipt function for each receipt
    // (Assuming receipts is an array of receipt objects)
    filteredData?.forEach((receipt) => categorizeReceipt(receipt));
 

    // Filter the results for paid and unpaid receipts
    const paidCountPerPeriod = countReceiptsPerHour(paidReceipts);
    const unpaidCountPerPeriod = countReceiptsPerHour(unpaidReceipts);
    const partiallyCountPerPeriod = countReceiptsPerHour(partiallyPaidReceipts);
    const refundCountPerPeriod = countReceiptsPerHour(refundedReceipts);

    filteredData?.forEach((receipt) => categorizeReceipt(receipt));
 

    setPaid({
      data: paidCountPerPeriod.map((i) => i.total),
      time: paidCountPerPeriod.map((i) => i.created_at),
    });
    setUnPaid({
      data: unpaidCountPerPeriod.map((i) => i.total),
      time: unpaidCountPerPeriod.map((i) => i.created_at),
    });
    setPartPaid({
      data: partiallyCountPerPeriod.map((i) => i.total),
      time: partiallyCountPerPeriod.map((i) => i.created_at),
    });
    setRefund({
      data: refundCountPerPeriod.map((i) => i.total),
      time: refundCountPerPeriod.map((i) => i.created_at),
    });
  }, [Period]);

  return (
    <div className=" w-full flex flex-col ">
      <div
        onClick={() => setState(0)}
        className={`bg-main text-white items-start justify-center flex  ${
          isArabic ? " flex-row-reverse" : " flex-row"
        }  font-[700] text-[1.4rem] py-[1.1rem] pl-[1.8rem] pr-[.8rem] w-full h-[6.4rem] rounded-[.4rem]`}
      >
        <div className=" w-full  h-full flex flex-col gap-1">
          <h1 className=" text-[1.1rem] font-[500] ">{t("Stats.TB")}</h1>
          <span>{Totals.Paid} </span>
        </div>
        <span className=" ">
          <img className=" w-[35px] " src={billimg} alt="" />
        </span>
      </div>
      <BillingChart
        Period={Period}
        setTotals={setTotals}
        Paid={Paid}
        UnPaid={UnPaid}
        PartPaid={PartPaid}
        filteredData={filteredData}
        Refund={Refund}
        state={state}
        setState={setState}
      />
      <div
        className={`w-full flex flex-row ${
          isEn ? " text-[.9rem] " : " text-xs "
        } justify-between pt-[1rem] gap-2 text-center`}
      >
        <div
          onClick={() => setState(1)}
          className=" flex flex-col justify-center w-full  items-center  border-gray-200 pt-[.48rem] px-[.8rem] pb-[.7rem]  border-solid border-[1px] rounded-[.32rem]  gap-[.6rem] "
        >
          <img
            src={TotalBill}
            className=" bg-[#FFD9D9] w-[2.5rem] p-[.4rem] rounded-full h-[2.5rem]   "
            alt=""
          />
          <h1 className="   leading-5 font-medium "> {t("Stats.TU")} </h1>
          <p className=" text-[1.1rem] font-[500] "> {Totals.Unpaid} </p>
        </div>
        <div
          onClick={() => setState(2)}
          className=" flex flex-col justify-center w-full  items-center  border-gray-200 pt-[.48rem] px-[.8rem] pb-[.7rem]  border-solid border-[1px] rounded-[.32rem]  gap-[.6rem] "
        >
          <img
            src={PaidBill}
            className=" bg-[#FFEADE] w-[2.5rem] p-[.4rem] rounded-full h-[2.5rem]   "
            alt=""
          />
          <h1 className="  leading-5 font-medium ">{t("Stats.PB")} </h1>
          <p className=" text-[1.1rem] font-[500] ">{Totals.Parti} </p>
        </div>
        <div
          onClick={() => setState(3)}
          className=" flex flex-col justify-center w-full  items-center  border-gray-200 pt-[.48rem] px-[.8rem] pb-[.7rem]  border-solid border-[1px] rounded-[.32rem]  gap-[.6rem] "
        >
          <img
            src={RefBill}
            className=" bg-[#C7FFF5] w-[2.5rem] p-[.4rem] rounded-full h-[2.5rem]   "
            alt=""
          />
          <h1 className="   leading-5 font-medium ">{t("Stats.RB")} </h1>
          <p className=" text-[1.1rem] font-[500] ">{Totals.Refund} </p>
        </div>
      </div>
    </div>
  );
}

export default Billing;
