import { useEffect, useState } from 'react';

const useOnlineStatus = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  console.log(isOnline)
  // Function to handle online/offline events
  const handleOnlineStatusChange = () => {
    setIsOnline(navigator.onLine);
  };

  // Attach event listeners for online/offline changes
  useEffect(() => {
    window.addEventListener('online', handleOnlineStatusChange);
    window.addEventListener('offline', handleOnlineStatusChange);

    // Cleanup: Remove event listeners when component unmounts
    return () => {
      window.removeEventListener('online', handleOnlineStatusChange);
      window.removeEventListener('offline', handleOnlineStatusChange);
    };
  }, []);

  return isOnline;
};

export default useOnlineStatus;
