// Importing necessary modules and dependencies
import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CardsContext } from "../context/Cardscontext";
import LoyaltyCards from "../compenent/LoyaltyCard/LoyaltyCards";
import AddCard from "../compenent/LoyaltyCard/AddCard";
import BankingCards from "../compenent/BankingCard/BankingCards.jsx";
import AddLoyaltyCard from "../compenent/LoyaltyCard/AddLoyaltyCard.jsx";
import CardDetails from "../compenent/LoyaltyCard/CardDetails";
import AddBankingCard from "../compenent/BankingCard/AddBankingCard.jsx";
import { themecontext } from "../context/Themecontexte.js";
// Cards Component
function Cards() {
  // Getting the current pathname from react-router
  const { pathname } = useLocation();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  var isArabic = i18n.language.startsWith("ar");
  const { Loyalty_Card, Banking_Card } =
    useContext(CardsContext);
  const { theme } = useContext(themecontext);
  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <div>
      <nav className="  w-full flex items-center  justify-center pt-5   ">
        <div
          className={`w-[60%] h-[36px] rounded-full flex flex-row justify-between gap-1 text-gray-400 items-center px-1 text-sm  ${
            theme ? "bg-[#E7E7E7]" : "bg-[#1C1C1C]"
          } `}
        >
          <Link
            to={"/Cards/LoyaltyCards"}
            className={` ${
              isActive("/Cards/LoyaltyCards")
                ? " bg-main text-white  font-semibold "
                : ""
            } w-full rounded-full text-center  p-1 `}
          >
            {t("card.loyaltyCards")}
          </Link>
          <Link
            to={"/Cards/BankingCards"}
            className={` ${
              isActive("/Cards/BankingCards")
                ? " bg-main text-white  font-semibold "
                : ""
            } w-full rounded-full text-center  p-1 `}
          >
            {t("card.bankingCards")}
          </Link>
        </div>
      </nav>
      {/* Banking Cards */}

      {pathname == "/Cards/BankingCards" && (
        <BankingCards
          Loyalty_Card={Loyalty_Card}
        />
      )}

      {pathname == "/Cards/addbankingcard" && (
        <AddBankingCard
        />
      )}

      {/* Loyalty Cards */}
      {pathname == "/Cards/LoyaltyCards" && (
        <LoyaltyCards
          Loyalty_Card={Loyalty_Card}
        />
      )}

      {pathname == "/Cards/addcartmanully" && (
        <AddLoyaltyCard
        />
      )}
      {pathname == "/Cards/addcart" && <AddCard />}
      {pathname == "/Cards/CardDetails" && <CardDetails />}
    </div>
  );
}

// Exporting the Cards component
export default Cards;
