import { React, useContext, useState } from "react";
import LoadingBtn from "../LoadingBtn";
import axios from "axios";
import Masseege from "../masseege";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { themecontext } from "../../context/Themecontexte";
function CodeVer({ msg, setMsg }) {
  const [isLoading, setIsLoading] = useState(false);
  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const { theme } = useContext(themecontext);
  const inputRefs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];

  const {t,i18n} = useTranslation()
var isArabic = i18n.language.startsWith('ar');

  const Email = localStorage.getItem("email");

  const handleChange = (index, e) => {
    const value = e.target.value;
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    if (value && index < 5) {
      inputRefs[index + 1].current.focus();
    }
  };

  const Navto = useNavigate();

  function SendRestPass(e) {
    setIsLoading(true);
    e.preventDefault();

    const VerCode = code.join("");
    axios
      .post(
        "/api/clients/reset-password-code",
        {
          email: Email,
          code: VerCode,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
 
localStorage.setItem('token', 'token ' + response.data.access_token )

        setIsLoading(false);
        setMsg({
          text: t('Msg.VcCorrect'),
          color: "bg-[#4fd837] text-[0.9rem] ",
        });
        Navto('/Signin/UpdatePassword')
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false);

        setMsg({
          text: error.response.data.message
            ? error.response.data.message   
            :  t('Msg.InVrcode'),
          color: "bg-[#FF1203F5] text-[0.9rem] ",
        });
      });
  }

  function ResenCode(e) {
    axios
      .post(
        "/api/clients/reset_password",
        {
          email: Email,
          code,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setMsg({
          text: t('Msg.VcSent'),
          color: "bg-[#4fd837] text-[0.9rem] ",
        });
      })
      .catch((error) => {
        console.error("Error:", error);

        setMsg({
          text:  t('Msg.VcIncorrect'),
          color: "bg-[#FF1203F5] text-[0.9rem] ",
        });
      });
  }

  return (
    <div className=   {`pt-[6rem] pb-[3.4rem]   text-center fixed top-0 px-[1.2rem] right-0 z-30 w-full  h-full flex flex-col justify-between ${!theme ? 'bg-[#212121] ' :' bg-white' } `} >
      <div className=" h-[57%] w-full flex flex-col justify-between ">
        <div className=" w-full flex flex-col items-center gap-[1rem]  ">
          <h1 className=" text-[1.6rem] font-[700] ">{t('Signin.Vcode')}</h1>
          <p className=" text-center text-sec text-[.92rem] ">
          {t('Signin.Vcodep')}<br /> {Email}
          </p>
        </div>

        <form onSubmit={SendRestPass}>
          <div className=" flex flex-col   ">
            <div className=" flex flex-row gap-3">
              {code.map((digit, index) => (
                <input
                  key={index}
                  ref={inputRefs[index]}
                  type="text"
                  value={digit}
                  className={`   text-center  bg-transparent border-sec border-opacity-50  text-[1.8rem] w-full h-[3.6rem]   px-[1rem]   text-sm  border-solid border-[1px] rounded-[0.32rem]`}
                  onChange={(e) => handleChange(index, e)}
                  maxLength={1}
                />
              ))}
            </div>
          </div>
          <div className=" w-full">
            <button
              type="submit"
              className=" h-[3.6rem] relative mt-[1.58rem]  text-[1.1rem] font-medium text-white w-full bg-main rounded-[0.32rem] "
            >
              {t('Signin.verify')}
              <LoadingBtn bg={'bg-main'} color={'#fff'} isLoading={isLoading} small={true} />
            </button>
          </div>
        </form>
      </div>
      <span className=" text-[.95rem] text-sec ">
        {" "}
      {t('Signin.Resend')}
        <span onClick={ResenCode} className=" text-main   font-medium ">
        {t('Signin.DidntRecive')}
        </span>{" "}
      </span>
      <Masseege msg={msg} setMsg={setMsg} />
    </div>
  );
}

export default CodeVer;
