import Dexie from "dexie";

// Creating the LocalDB using Dexie
const Database = new Dexie("Database");
Database.version(1).stores({
  receipts: "id",
  users: "id",
  loyaltycards: "id",
  bankingcards: "id",
});


// Define a function to remove Receipt by ID
export const deleteReceiptFromLocalDB = async (id) => {
  try {
    // Open the database
    await Database.open();

    // Remove the item by ID
    await Database.receipts.where("id").equals(id).delete();
    console.log(`Item with ID ${id} removed successfully.`);
  } catch (error) {
    console.error("Error removing item:", error);
  }
};

export const AddOneReceiptToDB = async (ReceiptData) => {
  try {
    const existingReceipt = await Database.receipts.get(ReceiptData?.id);

    if (!existingReceipt) {
      // If the receipt does not exist, add it to the local database
      await Database.transaction("rw", Database.receipts, async () => {
        await Database.receipts.add(ReceiptData);
      });
      console.log("Added to local DB:", ReceiptData?.id);
    } else {
      // If the receipt exists, update it in the local database
      await Database?.receipts.update(ReceiptData?.id, ReceiptData);
      console.log("Updated in local DB:", ReceiptData?.id);
    }
  } catch (error) {
    console.error("Error checking and updating receipts in the local DB:", error);
  }
};

export const checkAndAddAllReceiptsToDB = async (Receiptes) => {
  for (const receipt of Receiptes) {
    const existingReceipt = await Database.receipts.get(receipt?.id);
    if (!existingReceipt) {
      // Receipt doesn't exist in the local DB, add it
      await Database?.receipts.put(receipt);
    } else {
      // Receipt already exists in the local DB
    }
  }
};

export const fetchLocalData = async () => {
  try {
    const allReceipts = await Database.receipts.toArray();

    return allReceipts;
  } catch (error) {
    console.error("Error fetching receipts from the local DB:", error);
  }
};
