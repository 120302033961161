
import axios from 'axios'

export function sendFcmT(token){
const FCMTOKEN = localStorage.getItem('FCMTOKEN')
axios
.put("/api/clients/me", {
  fcm_token: FCMTOKEN
}, { 
  headers: {
    Authorization: token,
  },
})
.then(({ data }) => {

}).catch((err)=>{
})
}