import { React, useEffect, useRef, useState } from "react";
import { BData } from "../assest/data/OnboardingData";
import Mlogo from "../assest/Mlogo.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import axios from "axios";
import { useContext } from "react";
import { Usercontext } from "../context/Usercontexte";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import Cookies from "js-cookie";
export default function Onboarding({ setCurrentIndex, currentIndex }) {
  const swiperRef = useRef(null);
  const { setUser } = useContext(Usercontext);

  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
      setCurrentIndex((prevIndex) => prevIndex + 1); // Update the current index
    }
  };
  function CreatUknownUser() {
    handleNext();

    if (currentIndex == 3) {
      const FCMTOKEN = localStorage.getItem("FCMTOKEN");

      axios
        .post("/api/clients/anonymous")
        .then((response) => {
          const { data } = response;
          axios
            .put(
              "/api/clients/me",
              {
                fcm_token: FCMTOKEN,
              },
              {
                headers: {
                  Authorization: "Token " + data.access_token,
                },
              }
            )
            .then(({ data }) => {
              setUser(data);
              console.log(data);
            })
            .catch((err) => {});
          Cookies.set("access_token", "Token " + data.access_token);
        })
        .catch((error) => {
          console.error();
        });
    }
  }
  return (
    <>
      <div
        className={` bg-white fixed w-full  items-start justify-between h-full top-0 right-0 z-[60]  `}
      >
        {currentIndex <= 3 && (
          <Swiper
            ref={swiperRef}
            spaceBetween={0}
            draggable={false}
            slidesPerView={1}
          >
            {BData?.map((item, idx) => (
              <SwiperSlide key={idx}>
                {/* <img      src={item.img} alt={`Photo ${idx + 1}`} className= {` object-cover rounded-xl w-full    h-full   `}  /> */}
                <div
                  className={` ${
                    item.wihteText ? " text-white" : " text-black"
                  } w-full bg-no-repeat bg-center h-[100vh] pt-[1.9rem] px-5 flex flex-col gap-2    bg-cover `}
                  style={{ backgroundImage: `url(${item.img})` }}
                >
                  <div className="  leading-[1.3]   text-[1.9rem] font-[700]  ">
                    {item.Htitle}
                  </div>
                  <p className=" text-sm ">{item.Ptitle} </p>
                </div>
              </SwiperSlide>
            ))}
            <div className=" w-full h-full flex justify-between flex-col ">
              <button
                className={`   ${
                  currentIndex == BData.length - 1 ? " w-[9.3rem]  " : ""
                }  bg-main transform   duration-200 ease-out  text-[1rem] font-[700]     translate-x-[50%]    text-white flex items-center justify-center  h-[3rem] w-[3rem]  rounded-full  right-[50%] z-30      absolute   bottom-[10%] `}
                onClick={CreatUknownUser}
              >
                {currentIndex == BData.length - 1 ? (
                  "Get Started"
                ) : (
                  <NavigateNextIcon fontSize="small" />
                )}
              </button>
            </div>
          </Swiper>
        )}
        <div className=" flex w-full h-full justify-center items-center">
          <img
            className={` ${
              currentIndex >= 4 ? " opacity-100" : " opacity-0"
            }  duration-300 z-50   top-0 w-[3.5rem] h-[3.5rem]  `}
            src={Mlogo}
            alt=""
          />
        </div>
      </div>
    </>
  );
}
