import React, { useState } from "react";
import DOMPurify from "dompurify";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

function EmailReceipts({
  body,
  from,
  date,
  subject,
  checkboxStates,
  handleCheckboxChange,
  checkboxId,
}) {
  const [Isopen, setIsOpen] = useState(false);
  const From = from.split("<");
  const sanitizedHTML = DOMPurify.sanitize(body);
  console.log(body);

  return (
    <div className="">
      <span className="  overflow-hidden items-center w-full gap-2 h-full  rounded-md border-solid flex flex-col border-[1px] ">
        <span className="  items-center w-full gap-2 justify-between  pr-[1rem] pl-[.8rem]  py-[1rem] h-[7rem] rounded-md   flex flex-row  ">
          <div className=" flex flex-row items-start gap-3">
            <input
              checked={checkboxStates[checkboxId]}
              onChange={handleCheckboxChange(checkboxId)}
              className="h-5 w-5"
              type="checkbox"
              name=""
            />
            <div className=" flex flex-row gap-1  w-full  items-start ">
              <span className="   text-lg w-[4rem] h-[3rem] flex items-center text-white font-bold justify-center  rounded-[50%] bg-[#00FF8E] ">
                {from.split("")[0]}
              </span>
              <div className=" flex-col flex gap-1">
                <h1 className=" font-semibold">{From[0]}</h1>
                <p className=" text-[.8rem] text-sec ">{date}</p>
                <p className="text-[.8rem]">Subject :{subject}</p>
              </div>
            </div>
          </div>

          <div
            onClick={() => setIsOpen(!Isopen)}
            className=" h-5 w-5 flex items-center justify-center bg-[#1cd58221] rounded-md"
          >
            <KeyboardArrowDownIcon className=" scale-95 text-main " />
          </div>
        </span>
        {Isopen && (
          <div className=" h-[1000px]   overflow-auto py-36 ">
            <div
              className=" pb-40 scale-[.66] -mt-[17rem]  z-30 pt h-full w-full bg-white     "
              dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
            />
          </div>
        )}
      </span>
    </div>
  );
}

export default EmailReceipts;
