import { React, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
function Masseege({ msg, setMsg }) {
  useEffect(() => {
    if (msg?.text !== "") {
      const timer = setTimeout(() => {
        setMsg({ text: "", color: "" });
      }, 4000);

      return () => clearTimeout(timer);
    }
  }, [msg]);

  return (
    <div
      className={` fixed  text-center   ${msg?.color}    ${
        msg?.text == "" ? "-top-20 " : " top-5 "
      }  duration-300   ease-out  w-[80%] text-white font-[600] h-[3.6rem] right-1/2 translate-x-[50%]  z-20     px-3  rounded-md  items-center justify-center flex   `}
    >
      <span
        className=" absolute top-2 right-2 z-20"
        onClick={() => setMsg({ text: "", color: "" })}
      >
        {" "}
        <CloseIcon className=" scale-90 text-white" />{" "}
      </span>
      {msg?.text}
    </div>
  );
}

export default Masseege;
