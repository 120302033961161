import { React, useRef, useEffect, useState } from "react";
import '../../App.css'
import { useTranslation } from "react-i18next";
import Crediticon from '../../assest/crediticon.png'
function CreditO({ Period,filteredData}) {
 const  [Merch ,setMerch] = useState()
 const  [FilterMerch ,setFilterMerch] = useState()
 const  [TotalCredit ,setTotalCredit] = useState()

 const {t , i18n } = useTranslation();
 var isArabic = i18n.language.startsWith('ar');
 const [searchTerm, setSearchTerm] = useState('');
 
 useEffect(() => {
    setFilterMerch(Merch);
}, [Merch]);



const handleSearch = () => {
  const merchh = Merch?.filter(item => item.Name.toLowerCase().includes(searchTerm.toLowerCase()));
  setFilterMerch(merchh)
}

 useEffect(()=>{
   
handleSearch()
 }, [searchTerm])


const handleInputChange = (e) => {
  setSearchTerm(e.target.value);
  handleSearch()
};


useEffect(()=> {
  function calculateShopStats(data) {
    const shopStats = {};
    let totalCredits = 0

    data?.forEach(entry => {
       if(!entry?.json || entry?.json?.credit <= 0 ){
        return
       }
       const shopId = entry.shop;
        const shopName = entry?.json?.shop_details.shop_name || `Shop ${shopId}`;
        const credit = entry?.json?.credit;
        if (typeof credit === 'number' && !isNaN(credit)) {
            totalCredits += credit;
        }
        if (shopStats[shopId] === undefined) {
            shopStats[shopId] = {
                name: shopName,
                visitCount: 1,
                totalSpending: entry?.json?.total,
                totalCredit: entry.json?.credit,
                purchaseCount: entry?.json?.products.length || 0,
                // Add more fields as needed
            };
        } else {
            shopStats[shopId].visitCount++;
            shopStats[shopId].totalSpending += entry?.json?.total;
            shopStats[shopId].totalCredit += entry.json?.credit;
            shopStats[shopId].purchaseCount += entry?.json?.products.length || 0;
            // Update more fields as needed
        }
    });
    
    setTotalCredit(totalCredits)
    // Calculate average spending for each shop
    for (const shopId in shopStats) {
        const shop = shopStats[shopId];
        shop.averageSpending = shop.totalSpending / shop.visitCount;
    }

    return Object.values(shopStats);
}

const shopStatistics = calculateShopStats(filteredData);

const transformedData = shopStatistics.map(shop => ({
  Name: shop.name,
  VisitCount: shop.visitCount,
  AverageSpending:isNaN(shop.averageSpending) ? 0 : shop.averageSpending  ,
  TotalSpending:isNaN(shop.totalSpending) ? 0 : shop.totalSpending  ,
  TotalCredit: shop.totalCredit,
  PurchaseCount: shop.purchaseCount,
  // Add more fields as needed
}));

setMerch(transformedData)


},[Period ] )
 

  return (
    <div className=" text-left flex w-full flex-col h-full">
  
  <div  
      className=  { ` mb-2 bg-main text-white items-start justify-center flex ${isArabic ? ' flex-row-reverse' : ' flex-row'  }  font-[700] text-[1.4rem] py-[1.1rem] pl-[1.8rem] pr-[.8rem] w-full h-[6.4rem] rounded-[.4rem] `}>
        <div className= {` ${!isArabic ? ' text-left':'text-right' }  w-full  h-full flex flex-col gap-1`} >
          <h1 className=  {` text-[1.1rem] font-[500]`}  > {t('Stats.TCrédit')} </h1>
          <span>{TotalCredit} </span>
        </div>
        <span className=" ">
           <img className=" scale-125" src={Crediticon} alt="" />
        </span>
      </div>
  
      <span className=" relative w-full ">
        <p className= {` text-[#9C9C9C]  flex flex-row gap-[.35rem] items-center  absolute  top-[1.5rem]   ${isArabic ?' right-3' :' left-3' } `}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
          >
            <path
              d="M9.64153 8.21094H9.06072L8.85486 8.01243C9.31433 7.47865 9.65013 6.84991 9.83823 6.1712C10.0263 5.49249 10.0621 4.78059 9.94296 4.08645C9.59742 2.04259 7.89175 0.410438 5.83318 0.160469C5.10946 0.0689108 4.37438 0.144126 3.6842 0.38036C2.99402 0.616593 2.36703 1.00758 1.8512 1.52341C1.33537 2.03924 0.944382 2.66623 0.708149 3.35641C0.471916 4.04659 0.3967 4.78167 0.488258 5.50539C0.738227 7.56396 2.37038 9.26963 4.41424 9.61517C5.10838 9.73431 5.82028 9.69855 6.49899 9.51044C7.1777 9.32234 7.80644 8.98654 8.34022 8.52707L8.53873 8.73293V9.31374L11.6633 12.4384C11.9648 12.7398 12.4574 12.7398 12.7588 12.4384C13.0602 12.1369 13.0602 11.6443 12.7588 11.3429L9.64153 8.21094ZM5.23031 8.21094C3.39966 8.21094 1.9219 6.73318 1.9219 4.90252C1.9219 3.07187 3.39966 1.59411 5.23031 1.59411C7.06097 1.59411 8.53873 3.07187 8.53873 4.90252C8.53873 6.73318 7.06097 8.21094 5.23031 8.21094Z"
              fill="#C1C1C1"
            />
          </svg>{" "}
        </p>
        <input 
           value={searchTerm}
           onChange={handleInputChange}
              type="text"
              className={` text-[.75rem] px-8  ${isArabic ?'  text-right ' :'  text-left'}    bg-gray-500 bg-opacity-5  rounded-[.3rem] w-full h-[3.8rem] `}
              placeholder={t('profile.searchPlaceh') + ` `+ t('Stats.Merchant')}

            />
        
      </span>

      <div className=" w-full  h-full pt-[1.5rem]  ">
     
            <div className="custom-scroll-container  border-solid border-[1px] w-full flex flex-col   ">
              <div className= {` border-solid border-b-[1px] py-[1.4rem] font-[500] pb-4    flex  ${isArabic ?' flex-row-reverse ' :' flex-row' }  justify-around items-center  `}>
                <h1>{t('Stats.Merchant')}</h1>
                <h1> {t('Stats.TCrédit')}</h1>
                
              </div>

              <div className=  {`content  pt-5  flex flex-col  h-full      pb-5    overflow-auto   gap-5`} >
                {FilterMerch?.map((i) => (
                  <div className=  {`flex  ${isArabic ?' flex-row-reverse ' :' flex-row' }  justify-around items-center`}   >
                    <h1 className=" w-full   text-center   ">{i.Name}</h1>
                     
                    <h1 className=" w-full  text-center text-[#06C671]    ">
                      {i?.TotalCredit}{" "}
                    </h1>
                  </div>
                ))}
              </div>
            </div>
         
      </div>
    </div>
  );
}

export default CreditO;
