export  const handleReceiptsSearch = (Receiptes  , searchTerm ,setFiltredData ) => {
    const results = Receiptes?.filter((receipt) => {
      // If search term is empty, return all Receiptes
      if (!searchTerm) {
        return true;
      }
      // Search in products' names
      const NameMatch = receipt?.name
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase());
      // Search in store name

       // Search in products' names
       const productsMatch = receipt?.json?.products.some((product) =>
       product?.name?.toLowerCase().includes(searchTerm.toLowerCase())
     );

      const storeMatch = receipt?.json?.shop_details?.shop_name
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase());
        

      const sourceMatch = receipt?.source
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase());

      const sourceEmailMatch = receipt?.data?.from
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase());

      return NameMatch || storeMatch || sourceMatch || sourceEmailMatch || productsMatch ;
    });

    setFiltredData(results);
  };