import { React, useEffect, useState, useContext } from "react";
import axios from "axios";
import FacebookSVG from "../../assest/facebook.svg";
import GoogleSVG from "../../assest/google.svg";
import AppleSVG from "../../assest/appel.svg";
import LoadingBtn from "../LoadingBtn";
import { useTranslation } from "react-i18next";
import { themecontext } from "../../context/Themecontexte";

function SocialLogBtn({ Loginpage, From }) {
  const [isLoadingF, setisLoadingF] = useState(false);
  const [isLoadingG, setisLoadingG] = useState(false);
  const [isLoadingA, setisLoadingA] = useState(false);

  const { t, i18n } = useTranslation();
  const { theme } = useContext(themecontext);
  var isArabic = i18n.language.startsWith("ar");

  function Googlelogin() {
    setisLoadingG(true);
    axios
      .get("/api/clients/web_auth/googe_login", {})
      .then((response) => {
        const { results } = response.data;
        window.location.href = results;
      })
      .catch((err) => console.error());
  }

  function Facebooklogin() {
    setisLoadingF(true);
    axios
      .get("/api/clients/web_auth/facebook_login", {})
      .then((response) => {
        const { results } = response.data;
        window.location.href = results;
      })
      .catch((err) => console.error());
  }

  function Appellogin() {
    setisLoadingA(true);
    axios
      .get("/api/clients/web_auth/apple_login", {})
      .then((response) => {
        const { results } = response.data;
        window.location.href = results;
      })
      .catch((err) => console.error());
  }

  return (
    <div className=" text-[1rem]   flex flex-col gap-2 pt-[1.7rem] ">

      {/* Google */}

      <span
        onClick={() => Googlelogin()}
        className={`  relative text-sm rounded gap-6 items-center justify-center flex flex-row w-full  ${
          Loginpage ? " h-[3.6rem] " : " h-[3.2rem]"
        } border-solid ${theme ?'  border-[#F8F8F8] ' : '  border-[#424242] '} border-[1px]`}
      >
        <span
          className={` ${isArabic ? " right-10" : "left-7"}   ${
            Loginpage ? "top-4" : " top-3"
          }  absolute`}
        >
          <img className=" w-[1.4rem] " src={GoogleSVG} alt="" />
        </span>
             {t("profile.GSignin", { from: From  })} 
        <LoadingBtn
          isLoading={isLoadingG}
          small={true}
          bg={theme ? "bg-white" : "bg-[#212121] "}
          color={theme ? "#000" : "#fff"}
        />
      </span>

      {/* Appel */}

      <span
        onClick={() => Appellogin()}
        className={`  relative text-sm rounded gap-6 items-center justify-center flex flex-row w-full  ${
          Loginpage ? " h-[3.6rem] " : " h-[3.2rem]"
        } border-solid ${theme ?'  border-[#F8F8F8] ' : '  border-[#424242] '} border-[1px]`}
      >
        <span
          className={`${isArabic ? " right-10" : "left-7"}   ${
            Loginpage ? "top-4" : " top-3"
          }  absolute`}
        >
          <img className=" w-[1.4rem] " src={AppleSVG} alt="" />
        </span>
        {t("profile.ASignin", { from: From  })}
        <LoadingBtn
          isLoading={isLoadingA}
          small={true}
          bg={theme ? "bg-white" : "bg-[#212121] "}
          color={theme ? "#000" : "#fff"}
        />
      </span>

      {/* Facebook */}

      <span
        onClick={() => Facebooklogin()}
        className={`  relative text-sm rounded gap-6 items-center justify-center flex flex-row w-full  ${
          Loginpage ? " h-[3.6rem] " : " h-[3.2rem]"
        } border-solid ${theme ?'  border-[#F8F8F8] ' : '  border-[#424242] '} border-[1px]`}
      >
        <span
          className={`${isArabic ? " right-10" : "left-7"}  ${
            Loginpage ? "top-4" : " top-3"
          }  absolute`}
        >
          <img className=" w-[1.4rem] " src={FacebookSVG} alt="" />
        </span>
        {t("profile.FSignin", { from: From  })}
        <LoadingBtn
          isLoading={isLoadingF}
          small={true}
          bg={theme ? "bg-white" : "bg-[#212121] "}
          black={theme}
        />
      </span>
    </div>
  );
}

export default SocialLogBtn;
