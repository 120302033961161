import * as yup from 'yup';
import i18n from '../i18n';

const generateAddCardSchema = () => {
  return yup.object().shape({
    storename: yup.string().max(15).required(i18n.t('inputsVal.storeNameRequired')),
    cardnumber: yup.string().min(4).required(i18n.t('inputsVal.cardNumberRequired')),
    notes: yup.string().max(300).test('inputsVal.notesMaxLength', i18n.t('notesMaxLength'), val => !val || val.length <= 300),
  });
};

export default generateAddCardSchema;


 

