// Importing necessary modules and dependencies
import { React, useState, useEffect, useContext, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import TopBar from "../TopBar";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LoadingBtn from "../LoadingBtn";
import { useTranslation } from "react-i18next";
import { themecontext } from "../../context/Themecontexte";
import { CardsContext } from "../../context/Cardscontext";
import DeleteCard from "./DeleteCard";
import { useClickOutside } from "../../hooks/useClickOutside";
import DetailsInputs from "./DetailsInputs";
import DisplayBarcode from "./DisplayBarcode";

// CardDetails Component
function LoyaltyCardDetails() {
  // State variables
  const [isLoading, setisLoading] = useState(false);
  const [OptionOpen, setOptionOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [Edit, setEdit] = useState(false);
  const [OneCard, setOneCard] = useState();
  const OptionRef = useRef();
  const { setLoyaltyCard, Loyalty_Card } =
    useContext(CardsContext);
  const { theme } = useContext(themecontext);

  // React Router hook for navigation
  const { id } = useParams();

  // Translation hook
  const { t, i18n } = useTranslation();

  // Closing the Menu By clicking outSide it
  const closeOption = () => {
    setOptionOpen(false);
  };
  useClickOutside(OptionRef, closeOption);
  // ------------------------------------

  useEffect(() => {
    const oneCard = Loyalty_Card?.find((item) => item.id == id);
    setOneCard(oneCard);
  }, [Loyalty_Card]);

  return (
    <div className=" flex flex-col  pb-20  ">
      <span className=" flex flex-row py-[1.2rem] px-[1.3rem] justify-between">
        {/* TopBar */}
        <TopBar
          callback={() => setEdit(false)}
          isFunc={Edit}
          text={Edit ? t("card.EditC") : t("topbar.CardD")}
        />

        {/* More Options */}
        {!Edit && (
          <span
            ref={OptionRef}
            onClick={() => setOptionOpen(!OptionOpen)}
            className="relative"
          >
            <MoreVertIcon />
            {OptionOpen && (
              <div
                className={`  flex flex-col absolute top-6 right-3 justify-between h-[6rem] w-[8rem] rounded-md shadow-lg    items-start font-medium  ${
                  theme ? " bg-white" : " bg-[#212121] "
                } `}
              >
                {/* Edit Card Link */}
                <div
                  onClick={() => setEdit(!Edit)}
                  className=" p-[.8rem] border-solid border-b-[1px] font-[500] border-gray-300 w-full "
                >
                  {t("card.EditC")}
                </div>

                {/* Delete Card Button */}
                <h1
                  onClick={() => setDeleting(true)}
                  className=" p-[.8rem] text-end font-[500] "
                >
                  {t("card.DeleteC")}
                </h1>
              </div>
            )}
          </span>
        )}
      </span>

      {/* Barcode Card Display */}
      <DisplayBarcode OneCard={OneCard} />

      {/* DetailsInputs */}
      <DetailsInputs
        setEdit={setEdit}
        OneCard={OneCard}
        isLoading={isLoading}
        Edit={Edit}
      />

      {/* Delete Confirmation Modal */}
      {deleting && (
        <DeleteCard
          t={t}
          navTo={'/Cards/LoyaltyCards'}
          id={id}
          setisLoading={setisLoading}
          setDeleting={setDeleting}
          setCards={setLoyaltyCard}
          theme={theme}
        />
      )}

      {/* Loading Indicator when deleting card  */}
      {isLoading && (
        <div className=" flex items-center z-50 justify-center fixed bg-black opacity-30 w-full h-full">
          <LoadingBtn color={"#fff"} isLoading={isLoading} />
        </div>
      )}
    </div>
  );
}

// Exporting the CardDetails component
export default LoyaltyCardDetails;
