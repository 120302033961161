import React, { useContext, useEffect, useState } from "react";
import { themecontext } from "../../context/Themecontexte";
import { useTranslation } from "react-i18next";
import cmaera from "../../assest/camera.svg";
import LoadingBtn from "../LoadingBtn";
import { useFormik } from "formik";
import Masseege from "../masseege";
import ImageUpload from "../LoyaltyCard/ImageUpload";
import { HandelUpdateOrAddCard } from "../../Functions/HandelUpdateOrAddCard";
import useOnlineStatus from "../../hooks/useOnlineStatus";
import { CardsContext } from "../../context/Cardscontext";
import { InputField } from "./InputField";
import generateAddBCardSchema from "../../Validation/AddBcardVal";
import { Cropperr } from "../Cropper";
import { useNavigate } from "react-router-dom";
import { addCardOrUpdateToLocalDB } from "../../DB/BankingCardDB";

function DetailsInputs({ setEdit, Edit, OneCard, Add }) {
  const { theme } = useContext(themecontext);
  const { setBankingCardsList } = useContext(CardsContext);
  const { t, i18n } = useTranslation();
  const [msg, setMsg] = useState({ text: "", color: "" });


// states of the card image before corpping
const [frontImage, setFrontImage] = useState();
const [backImage, setBackImage] = useState();

// states of card image last result 'aftr corpping'
const [ResultOfFrontImg, setResultOfFrontImg] = useState();
const [ResultOfBackImg, setResultOfBackImg] = useState();

const [ IsLoadingCard,setIsLoadingCard] = useState(false);

const [isLoading, setisLoading] = useState();
var isArabic = i18n.language.startsWith("ar");
const isOnline = useOnlineStatus();


const NavTo = useNavigate()


  const AddCardSchema = generateAddBCardSchema();
  const formik = useFormik({
    initialValues: {
      cardholdername: "",
      cardnum: "",
      cvv: "",
      expdate: "",
    },
    validationSchema: AddCardSchema,
    onSubmit,
  });


  console.log(formik.values.cardnum)

  useEffect(() => {
    formik.resetForm();
  }, [Edit]);

  // updating the lang of the Schema
  useEffect(() => {
    const updatedSchema = generateAddBCardSchema();
  }, [i18n.language]);

  const handleFileChange = (e, setImage) => {
    const selectedFile = e.target?.files[0];
    const fileSizeInMB = selectedFile?.size / (1024 * 1024);

    // Check if the file size is less than 5MB
    if (fileSizeInMB >= 5) {
      setMsg({
        text: t("Msg.FileSizeExceed"),
        color: "bg-[#FF1203F5] text-[0.9rem]",
      });
      return;
    }
    setImage(selectedFile);
  };

  // when the submition is complited
  function handleSubmissionComplete() {
    setisLoading(false);
    setTimeout(() => {
      setEdit(false);
    }, 1000);
  }

 useEffect(()=>{
  formik.setValues({
    cardholdername:OneCard?.holdername,
    cardnum:OneCard?.code,
    cvv:OneCard?.cvv,
    expdate:OneCard?.expdate,
  })
  setResultOfBackImg(OneCard?.back_image)
  setResultOfFrontImg(OneCard?.front_image)
 } ,[OneCard , Edit] )
 
  function onSubmit() {
    setIsLoadingCard(true)
    const formData = {
      id: OneCard?.id || generateNewId(), 
      front_image: ResultOfFrontImg,
      back_image: ResultOfBackImg,
      holdername: formik.values.cardholdername,
      expdate: formik.values.expdate,
      code: formik.values.cardnum,
      cvv: formik.values.cvv,
    };

    addCardOrUpdateToLocalDB(formData)

    // Assuming formData is an object containing the card details
    if (OneCard?.id) {
      // Update existing card in the state
      setBankingCardsList((prevList) => {
        const updatedList = prevList.map((card) =>
          card.id === formData.id ? formData : card
        );
        return updatedList;
      });
      setTimeout(() => {
        setIsLoadingCard(false)
        NavTo(-1);
        setMsg({
          text: t("Msg.CardAddedSuccess"),
          color: "bg-[#FF1203F5] text-[0.9rem]",
        });
      }, 1000);
    } else {
      setBankingCardsList((prevList) => [formData, ...prevList]);
      setTimeout(() => {
        setMsg({
          text: "Card updated successfully",
          color: "bg-green-400 text-[0.9rem]",
        });
        setIsLoadingCard(false)
        NavTo(-1);
      }, 1000);
    }

  function generateNewId() {
    // Implement a function to generate a new unique ID, for example:
    return Date.now().toString();
  }



    // console.log('Submitting...');
    // setisLoading(true);

    // try {
    //   await HandelUpdateOrAddCard({
    //     isOnline,
    //     id: OneCard.id,
    //     values,
    //     CfrontImage,
    //     CbackImage,
    //     setLoyaltyCard,
    //     setMsg,
    //     setisLoading,
    //     handleSubmissionComplete,
    //   });

    //   console.log('Submission successful.');
    // } catch (error) {
    //   console.error('Error in onSubmit:', error);
    //   // Handle the error as needed
    // } finally {
    //   setisLoading(false);
    // }
  };

  return (
    <form
      className="flex w-full flex-col gap-[1rem] pt-5 "
      onSubmit={formik.handleSubmit}
    >
      <div
        className={`w-full flex ${
          isArabic ? "flex-row-reverse" : "flex-row"
        } gap-2 justify-center items-center    `}
      >
        {/* Front Image Upload */}
        <ImageUpload
          Edit={Edit}
          image={ResultOfFrontImg}
          setImage={setResultOfFrontImg}
          handleFileChange={(e) => handleFileChange(e, setFrontImage)}
          label={t("card.Front")}
        />

        {/* Back Image Upload */}
        <ImageUpload
          Edit={Edit}
          image={ResultOfBackImg}
          setImage={setResultOfBackImg}
          handleFileChange={(e) => handleFileChange(e, setBackImage)}
          label={t("card.Back")}
        />
      </div>

      <InputField
        label={t("card.cardholderName")}
        placeholder={t("card.writeYourName")}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.cardholdername}
        error={formik.errors.cardholdername}
        touched={formik.touched.cardholdername}
        Edit={!Edit}
        isArabic={isArabic}
        id="cardholdername"
      />

      <InputField
        label={t("card.Cnumber")}
        placeholder={t("card.CnumberPlach")}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.cardnum}
        error={formik.errors.cardnum}
        Edit={!Edit}
        touched={formik.touched.cardnum}
        isArabic={isArabic}
        id="cardnum"
        type="number"
        format='#### #### #### ####'
      />

      <div className="flex flex-row  w-full gap-5">
        <InputField
          label={t("card.expirationDate")}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.expdate}
          error={formik.errors.expdate}
          touched={formik.touched.expdate}
          placeholder={t("card.mmYy")}
          Edit={!Edit}
          isArabic={isArabic}
          id="expdate"
          format='##/##'
          type="number"
        />

        <InputField
          label={t("card.cvv")}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={formik.errors.cvv}
          touched={formik.touched.cvv}
          value={formik.values.cvv}
          placeholder={t("card.cvv")}
          Edit={!Edit}
          isArabic={isArabic}
          id="cvv"
          max={4}
          type="tel"
        />
      </div>
      {/* Form Buttons */}
      {Edit && (
        <div className=" w-full pt-5 flex flex-row gap-2 ">
          {/* Cancel Button */}

          <button
            onClick={() => Add ? NavTo(-1) : setEdit(false)}
            className={`h-[3.5rem]  text-[1.1rem]  ${
              theme ? "  border-[#F8F8F8] " : " border-[#424242] "
            }    border-solid border-[1px] text-gray-400   font-medium   w-full  rounded-[0.32rem]`}
          >
            {t("card.Cancel")}
          </button>

          {/* Submit Button */}
          <button
            disabled={IsLoadingCard}
            type="submit"
            className=" h-[3.5rem] relative  text-[1.1rem] font-medium text-white w-full bg-main rounded-[0.32rem] "
          >
            {t("card.Submit")}
            <LoadingBtn color={"#fff"} isLoading={IsLoadingCard} bg={"bg-main"} />
          </button>
        </div>
      )}


{backImage && (
        <Cropperr
          isLoading={isLoading}
           CardImg={backImage}
          setResult={setResultOfBackImg}
          setImage={setBackImage}
        />
      )}

      {frontImage && (
        <Cropperr
          isLoading={isLoading}
          CardImg={frontImage}
          setResult={setResultOfFrontImg}
          setImage={setFrontImage}
        />
      )}

      <Masseege msg={msg} setMsg={setMsg} />
    </form>
  );
}

export default DetailsInputs;
