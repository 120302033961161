import * as yup from 'yup';
import i18n from '../i18n';

const generateSignupSchema = () => {
  return yup.object().shape({
    firstname: yup.string().max(15).required(i18n.t('inputsVal.firstNameRequired')),
    lastname: yup.string().max(15).required(i18n.t('inputsVal.lastNameRequired')),
    email: yup.string().email(i18n.t('inputsVal.emailInvalid')).required(i18n.t('inputsVal.emailRequired')),
    password: yup.string().min(5).max(20).required(i18n.t('inputsVal.passwordRequired')),
  });
};

export default generateSignupSchema;
