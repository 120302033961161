import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LinedChart from "./LinedChart";
import star from "../../assest/star.svg";

function Eimpact({ filteredData, Period, PeriodArray }) {
  // State variables to store impact statistics
  const [Digital, setDigital] = useState();
  const [Empact, setEmpact] = useState();
  const [EStatistic, setTotals] = useState({
    digital: "",
    NmberTree: "",
  });

  // Translation hook for language support
  const { t, i18n } = useTranslation();
  // Check if the language is Arabic
  var isArabic = i18n.language.startsWith("ar");

  /**
   * useEffect hook to recalculate impact statistics when the selected period changes.
   */
  useEffect(() => {
    // Object to store receipts type data for each period
    const ReceiptsType = {};

  
    function getPeriod(timestamp) {
      const date = new Date(timestamp);
      // Return the appropriate value based on the selected period
      return Period === "Yesterday" || Period === "Today"
        ? date.getHours()
        : Period === "Last year" || Period === "This year"
        ? date.getMonth()
        : date.getDate();
    }

    // Iterate through filtered data to calculate impact statistics
    filteredData?.forEach((entry) => {
      const { created_at, file_type } = entry;
      // Determine file type and store the date, period, and count in the appropriate array
      const fileType = file_type === "pdf" ? "digital" : "unknown";
      const period = getPeriod(created_at);

      if (fileType !== "unknown") {
        if (!ReceiptsType[period]) {
          ReceiptsType[period] = {
            digital: 0,
            date: "",
          };
        }

        // Increment the count for the corresponding file type
        ReceiptsType[period][fileType]++;
        ReceiptsType[period].date = created_at; // Store the date and created_at in the result array
      }
    });

    // Calculate total digital count and trees saved per period
    let totalDigital = 0;
    let treesSavedPerPeriod = [];

    Object.values(ReceiptsType).forEach((PeriodData) => {
      totalDigital += PeriodData.digital;
      const treesSaved = Math.floor(PeriodData.digital / 3); // Assuming 1 tree is saved for every 3 digital receipts
      const hourTimestamp = PeriodData.date;

      treesSavedPerPeriod.push({ time: hourTimestamp, treesSaved: treesSaved });
    });

    // Total of Saved Tree(s)
    const totalTrees = totalDigital / 3;

    // Set state with the calculated totals
    setTotals({
      digital: totalDigital,
      NmberTree: totalTrees.toFixed(0),
    });

    // Set state with treesSavedPerPeriod data
    setEmpact({
      time: treesSavedPerPeriod.map((i) => i.time),
      data: treesSavedPerPeriod.map((i) => i.treesSaved),
    });

    // Convert the values of ReceiptsType into an array and set the state
    const result = Object.values(ReceiptsType);
    setDigital({
      data: result.map((i) => i.digital),
      time: result.map((i) => i.date),
    });
  }, [Period]);


  return (
    <div className=" w-full flex flex-col pb-7  ">
      <LinedChart
        state={"Eimpact"}
        Digital={Digital}
        Empact={Empact}
        Period={Period}
        colors={["#0625C6", "#06C671"]}
      />
      <div
        className={` flex flex-row w-full justify-between   pt-2    ${
          isArabic ? " flex-row-reverse" : " flex-row"
        }`}
      >
        <span
          className={` w-[80%] ${
            isArabic ? " flex-row-reverse" : " flex-row"
          }  py-4 flex flex-row gap-2  text-[.8rem]  items-center justify-start  font-[400] `}
        >
          <span className={`w-3 h-3  rounded-full bg-main  `}></span>{" "}
          <h1 className="  text-[.96rem]  ">{t("Stats.DR")} </h1>
        </span>

        <span
          className={` w-full ${
            isArabic ? " flex-row-reverse" : " flex-row"
          } justify-end py-4    flex flex-row gap-2 text-[.8rem]  text-center  items-center  font-[400] `}
        >
          <span className={`w-3 h-3 rounded-full bg-[#0625C6]  `}></span>{" "}
          <h1 className="  text-[.96rem]  ">{t("Stats.EImpact")}</h1>
        </span>
      </div>
      <div className="   flex flex-row  justify-center font-[600]  pt-[2.3rem] w-full items-center  text-center  ">
        {EStatistic?.digital == 0 ||
          (EStatistic?.NmberTree >= 0 && (
            <div className=" w-[65%] relative">
              <img
                className=" absolute top-1 w-[33px] -right-6 "
                src={star}
                alt=""
              />
              <img
                className=" absolute top-1  w-[33px]  -left-6"
                src={star}
                alt=""
              />

              <h1 className="text-[1.7rem] ">
                <span className=" text-main"> {t("Stats.Greatjob")} </span>{" "}
                <br /> {t("Stats.digitalUsed", { num: EStatistic?.digital })}{" "}
                <span className=" text-main">
                  {" "}
                  {t("Stats.saving", { number: EStatistic?.NmberTree })}{" "}
                  {PeriodArray.find((i) => i.value == Period).text}{" "}
                </span>
              </h1>
            </div>
          ))}
      </div>
    </div>
  );
}

export default Eimpact;
