import Cookies from 'js-cookie';

// Constant
const USER_TOKEN = 'access_token';
const EXTERNAL_ACCOUNT_ID = 'external_account_id';

// 1) Link email
const linkEmailWithAccount = async (user_token , email) => {
  const LINK_EMAIL_API_URL =
    'https://django-cloudrun-5cc2zmgpqq-lz.a.run.app/api/clients/integrations/'+email+'/link';

  try {
    const response = await fetch(LINK_EMAIL_API_URL, {
      method: 'get',
      headers: {
        Authorization: `${user_token}`,
      },
    });
    const body = await response.json();
    if (response.status === 200 && !body.error) {
      return body.results;
    } else {
      alert('something went wrong');
      return null;
    }
  } catch (error) {
    console.error(error);
  }
};

// 2) Navigate to import page with accountId
const setIdAndNavigateToImportPage = (location) => {
  const searchParams = new URLSearchParams(location.search);
  const isLinked = searchParams.get('linked');
  const externalAccountId = searchParams.get(EXTERNAL_ACCOUNT_ID);

  if (isLinked === 'true') {
 
    // Reset the URL parameters
    // window.history.replaceState({}, document.title, location.pathname);
    if (!Cookies.get(EXTERNAL_ACCOUNT_ID))
      Cookies.set(EXTERNAL_ACCOUNT_ID, externalAccountId);
    return externalAccountId;
  }
  return null;
};

// On importEmailsRecieptspage

// 3) get user access id
const getExternalAccountId = () => {
  const externalAccountId = Cookies.get(EXTERNAL_ACCOUNT_ID);
  if (externalAccountId) {
    return externalAccountId;
  }

  return null;
};

// 4) unlink email
const unlinkEmail = async (external_account_id , email) => {
  try {
    const Token = Cookies.get('access_token')
    const res = await fetch(  `https://django-cloudrun-5cc2zmgpqq-lz.a.run.app/api/clients/integrations/${email}/unlink`, {
      method: 'post',
      headers: {
        Authorization: `${Token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        external_account_id: external_account_id,
      }),
    });

    if (!res.ok) {
      throw new Error(`Request failed with status ${res.status}`);
    }
  } catch (error) {
    console.error('Error:', error.message);
  }
};

// 5) get email receipts
// 1) Link email


const GetAllLinkedAccunts = async (user_token) => {
  const LINK_EMAIL_API_URL =
    'https://django-cloudrun-5cc2zmgpqq-lz.a.run.app/api/clients/integrations/state';
 
  try {
    const response = await fetch(LINK_EMAIL_API_URL, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${user_token}`,
      },
    });
    const body = await response.json();
    if (response.status === 200 && !body.error) {
      console.log(body)
      return body;
    } else {
      alert('something went wrong');
      return null;
    }
  } catch (error) {
    console.error(error);
  }
};


const GetReceiptFromEmail = async (user_token , email ,externalAccountId  ) => {
  const LINK_EMAIL_API_URL =
    'https://django-cloudrun-5cc2zmgpqq-lz.a.run.app/api/clients/integrations/'+email+'/emails';
 
  try {
    const response = await fetch(LINK_EMAIL_API_URL, {
      method: 'post',
      body: JSON.stringify({ external_account_id: externalAccountId }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${user_token}`,
      },
    });
    const body = await response.json();
    if (response.status === 200 && !body.error) {
      return body.message;
    } else {
      alert('something went wrong');
      return null;
    }
  } catch (error) {
    console.error(error);
  }
};

export {
  linkEmailWithAccount,
  setIdAndNavigateToImportPage,
  getExternalAccountId,
  GetReceiptFromEmail,
  GetAllLinkedAccunts,
  unlinkEmail,
  USER_TOKEN,
  EXTERNAL_ACCOUNT_ID,
};
