import React, { useState , useContext  } from "react";
import ConfirmeDeleteReceipt from "./ConfirmeDeleteReceipt";
import {
  AddOneReceiptToDB,
  deleteReceiptFromLocalDB,
} from "../../DB/ReceiptDB.js";
import EditReceipt from "./EditReceipt.jsx";
import { themecontext } from "../../context/Themecontexte";
import CloseBnt from "../../assest/CloseBtn.svg";

function MangeReceipt({
  Action,
  id,
  setMassage,
  FiltredData,
  setReceiptes,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const {theme} = useContext(themecontext)
  const handleEdit = async (id, newName) => {
    setIsLoading(true);

    try {
      // Find the index of the receipt with the given id
      const index = FiltredData.findIndex((item) => item.id === id);
      console.log("receipt");
      console.log(index);

      // If the receipt is found
      if (index !== -1) {
        // Create a copy of the FiltredData array
        const updatedData = [...FiltredData];

        // Update the name property of the receipt at the found index
        updatedData[index] = {
          ...updatedData[index],
          name: newName,
        };

        await AddOneReceiptToDB({ ...updatedData[index], name: newName });

        // Update the state with the modified array
        setReceiptes(updatedData);

        console.log(FiltredData[index]);

        console.log("Edit: " + id);
      }

      setTimeout(() => {
        setIsLoading(false);
        setMassage(null);
      }, 1000);
    } catch {
      setIsLoading(false);
      console.log("Edit canceled.");
    }
  };

  const handleDelete = async (id) => {
    setIsLoading(true);
    try {
      const updatedData = FiltredData.filter((item) => item.id !== id);
      await deleteReceiptFromLocalDB(id);
      setReceiptes(updatedData);
      console.log("Deletion " + id);
      setTimeout(() => {
        setIsLoading(false);
        setMassage(null);
      }, 1000);
    } catch {
      setIsLoading(false);
      console.log("Deletion canceled.");
    }
  };

  return (
    <div
      className={`duration-150 ${
        id ? "top-[50%]" : " top-[-100%] -z-10 "
      } flex z-40 fixed h-full w-full   `}
    >
      {/* Overlay for background dimming */}
      <div
        onClick={() => setMassage(null)}
        className={`flex fixed h-full w-full    ${
          id ? "opacity-50 top-0  " : "opacity-0  top-[-100%] -z-10  "
        } right-0 bg-black `}
      ></div>

      {/* Message container */}
      <div
        className={`${
          id ? "z-30 top-[50%] opacity-100 " : " opacity-0 -top-[50%] -z-10    "
        }   duration-150 transform flex translate-x-[-50%] translate-y-[-50%] ${
          Action == "edit" ? "h-[15rem]" : "h-[23rem]"
        }   left-[50%] fixed w-[80%] flex-col  justify-between pt-[1.6rem] pb-[3.5rem] px-[.74rem] rounded-[.31rem] ${
          theme ? "bg-white" : "bg-[#212121]"
        }`}
      >
        <span
          onClick={() => setMassage(null)}
          className=" absolute top-3 right-3 "
        >
          <img className="  h-[1.3rem] " src={CloseBnt} alt="" />
        </span>
        {Action == "delete" ? (
          <ConfirmeDeleteReceipt
            isLoading={isLoading}
            setIsOpen={setMassage}
            handleDelete={handleDelete}
            id={id}
          />
        ) : (
          <EditReceipt
            handleEdit={handleEdit}
            setIsOpen={setMassage}
            FiltredData={FiltredData}
            isLoading={isLoading}
            id={id}
          />
        )}
      </div>
    </div>
  );
}

export default MangeReceipt;
