import { React, useContext, useState , useEffect } from "react";
import LoadingBtn from "../LoadingBtn";
import { useFormik } from "formik";
import axios from "axios";
import  generateResetPassSchema2 from "../../Validation/RestPassVal2";
import Masseege from "../masseege";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { themecontext } from "../../context/Themecontexte";

function UpdatePassword({ msg, setMsg }) {
  const [isLoading, setIsLoading] = useState(false);
  const [PassVis, setPassVis] = useState(true);
  const [CPassVis, setCPassVis] = useState(true);
  const {t , i18n} = useTranslation()
const {theme}  = useContext(themecontext)
  const Navto = useNavigate();
var isArabic = i18n.language.startsWith('ar');
    const RestPassVal2 = generateResetPassSchema2();

  const { values, handleBlur, errors, touched, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        password: "",
        confirmpassword: "",
      },
      validationSchema: RestPassVal2,
      onSubmit,
    });



    // updating the lang of the Schema
    useEffect(() => {
      const updatedSchema = generateResetPassSchema2();
    }, [i18n.language]);


  function onSubmit() {
    setIsLoading(true);
    const token = localStorage.getItem('token')
    axios
      .put(
        "/api/clients/me",
        {
          password: values.password,
        },
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        localStorage.removeItem('token')
        setMsg({
          text: t('Msg.PasswordChanged'),
          color: "bg-[#4fd837] text-[0.9rem]",
        });
        setIsLoading(false);
        Navto('/Signin')
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false);
        if (error.response.data) {
          setMsg({
            text: error.response.data.message,
            color: "bg-[#FF1203F5] text-[0.9rem] ",
          });
        }
      });
  }

  return (
    <div className=  {`pt-[6rem] pb-[3.4rem]    fixed top-0 px-[1.2rem] right-0 z-30 w-full h-full flex flex-col justify-between  ${theme ? ' bg-white' :' bg-[#212121] '} `} >
      <div className=" h-[76%] w-full flex flex-col justify-between ">
        <div className=" w-full flex flex-col items-center gap-[1rem]  ">
          <h1 className=" text-[1.6rem] font-[700] "> {t('Signin.CreatePass')}</h1>
          <p className=" text-center text-sec text-[.92rem] ">
          {t('Signin.CreatePassP')}
          </p>
        </div>

        <form onSubmit={handleSubmit}>
          <div className= {`  ${isArabic ? ' text-right'  :' text-left'} flex flex-col gap-2  `} >
            <h1 className="   pt-[1.5rem]  text-[.9rem] font-medium">
            {t('Signup.psw')}
            </h1>
            <span className=" relative ">
              <input
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                className={`  ${isArabic ? ' text-right'  :' text-left'}  ${
                  errors.password && touched.password
                    ? " border-[#fc8181] text-red-500  border-[2px]"
                    : ""
                } text-sec w-full h-[3.7rem]   px-[1rem] bg-transparent   text-sm  border-solid border-[1px] rounded-[0.32rem]`}
                type={PassVis ? "password" : "text"}
                placeholder={t('Signup.psw')}
                name=""
                id="password"
              />
              {errors.password && touched.password && (
                <p className=" pt-1 px-1 text-xs text-red-400">
                  {" "}
                  {errors.password}{" "}
                </p>
              )}
              <span
                onClick={() => setPassVis(!PassVis)}
                className={`  absolute ${isArabic ? ' left-4'  :'right-4'}    top-5 `}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 17 12"
                  fill="none"
                >
                  <path
                    d="M8.63413 0.0593017C4.99778 0.0496582 1.88628 2.32208 0.619569 5.53807C1.86921 8.76073 4.9686 11.0496 8.60496 11.0593C12.2413 11.0689 15.3528 8.79648 16.6195 5.5805C15.3699 2.35784 12.2705 0.0689453 8.63413 0.0593017ZM8.60982 9.22594C6.60255 9.22061 4.97782 7.57363 4.98319 5.54964C4.98856 3.52565 6.622 1.88731 8.62927 1.89263C10.6365 1.89795 12.2613 3.54493 12.2559 5.56893C12.2505 7.59292 10.6171 9.23126 8.60982 9.22594ZM8.62538 3.35929C7.41811 3.35609 6.44096 4.33617 6.43773 5.5535C6.4345 6.77083 7.40644 7.75607 8.61371 7.75927C9.82098 7.76248 10.7981 6.7824 10.8014 5.56507C10.8046 4.34774 9.83264 3.36249 8.62538 3.35929Z"
                    fill={!PassVis ? "#06C671" : "#CBCBCB"}
                  />
                </svg>
              </span>
            </span>
          </div>
          <div className={`  ${isArabic ? ' text-right'  :' text-left'} flex flex-col gap-2  `} >
            <h1 className="   pt-[1.5rem]  text-[.9rem] font-medium">
            {t('Signin.Cpass')}
            </h1>
            <span className=" relative ">
              <input
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.confirmpassword}
                className={`  ${isArabic ? ' text-right'  :' text-left'} ${
                  errors.confirmpassword && touched.confirmpassword
                    ? " border-[#fc8181] text-red-500  border-[2px]"
                    : ""
                } text-sec w-full h-[3.7rem]   px-[1rem]  bg-transparent  text-sm  border-solid border-[1px] rounded-[0.32rem]`}
                type={CPassVis ? "password" : "text"}
                placeholder={t('Signin.Cpass')}
                name=""
                id="confirmpassword"
              />
              {errors.confirmpassword && touched.confirmpassword && (
                <p className=" pt-1 px-1 text-xs text-red-400">
                  {" "}
                  {errors.confirmpassword}{" "}
                </p>
              )}
              <span
                onClick={() => setCPassVis(!CPassVis)}
                className={`  absolute ${isArabic ? ' left-4'  :'right-4'}    top-5 `}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 17 12"
                  fill="none"
                >
                  <path
                    d="M8.63413 0.0593017C4.99778 0.0496582 1.88628 2.32208 0.619569 5.53807C1.86921 8.76073 4.9686 11.0496 8.60496 11.0593C12.2413 11.0689 15.3528 8.79648 16.6195 5.5805C15.3699 2.35784 12.2705 0.0689453 8.63413 0.0593017ZM8.60982 9.22594C6.60255 9.22061 4.97782 7.57363 4.98319 5.54964C4.98856 3.52565 6.622 1.88731 8.62927 1.89263C10.6365 1.89795 12.2613 3.54493 12.2559 5.56893C12.2505 7.59292 10.6171 9.23126 8.60982 9.22594ZM8.62538 3.35929C7.41811 3.35609 6.44096 4.33617 6.43773 5.5535C6.4345 6.77083 7.40644 7.75607 8.61371 7.75927C9.82098 7.76248 10.7981 6.7824 10.8014 5.56507C10.8046 4.34774 9.83264 3.36249 8.62538 3.35929Z"
                    fill={!CPassVis ? "#06C671" : "#CBCBCB"}
                  />
                </svg>
              </span>
            </span>
          </div>
          <div className=" w-full">
            <button
              type="submit"
              className=" h-[3.6rem] relative mt-[1.58rem]  text-[1.1rem] font-medium text-white w-full bg-main rounded-[0.32rem] "
            >
              {t('Signin.Reset')}
              <LoadingBtn bg={'bg-main'} isLoading={isLoading} small={true} color={'#FFFFFF'} />
            </button>
          </div>
        </form>
      </div>

      <Masseege msg={msg} setMsg={setMsg} />
    </div>
  );
}

export default UpdatePassword;
