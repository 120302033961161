import Dexie from "dexie";

  // Creating the LocalDB using Dexie
  const Database = new Dexie("Database");
  Database.version(1).stores({
    receipts: "id",
    users: "id",
    loyaltycards: "id",
    bankingcards: "id",
  });
 

// Storing one Card in the localDB
export const addCardOrUpdateToLocalDB = async (newCard) => {
  try {
 

    await Database.transaction("rw", Database.loyaltycards, async () => {
      // Check if the card already exists in the database
      const existingCard = await Database.loyaltycards.get(newCard?.id);

      if (existingCard) {
        // If the card exists, update the existing card instead of adding a new one
        await Database.loyaltycards.update(newCard?.id, newCard);
        console.log( 'The Updated ' + newCard?.id)
      } else {
        // If the card doesn't exist, add the new card to the database
        await Database.loyaltycards.add(newCard);

      }
    });
  } catch (error) {
    // Handle any errors that occur during the transaction
    console.error("Error adding/updating card:", error);
  }
};

//  Deleting one card from the localDB
export const deleteCardFromLocalDB = async (cardId) => {
  const numericCardId = Number(cardId);
  console.log(numericCardId)
  console.log( typeof numericCardId)
  try {
    await Database.loyaltycards.where({ id: numericCardId }).delete();
    console.log(`this card with the id  ${numericCardId}  was removed `);
  } catch (error) {
    console.error("Error deleting card from local database:", error);
  }
};

// Storing all the loyaltycards of the user in the localDB
export const checkAndAddCardsToDB = async (card) => {
  for (const cardItem of card) {
    const existingCard = await Database.loyaltycards.get(cardItem?.id);
    if (!existingCard) {
      // Card doesn't exist in the local DB, add it
      await Database?.loyaltycards.put(cardItem);
    } else {
      await Database.loyaltycards.update(cardItem?.id, cardItem);

    }
  }
};