import { React, useState, useContext, useEffect } from "react";
import Receipt from "../compenent/Receipts/Receipt";
import Filter from "../compenent/Receipts/Filter";
import FilterListIcon from "@mui/icons-material/FilterList";
import { motion } from "framer-motion";
import NoReceipts from "../compenent/Receipts/NoReceipts";
import DisplayReceipt from "../compenent/Receipts/ReceiptDetils";
import Navbar from "../compenent/Receipts/Navbar";
import SearchIcon from "@mui/icons-material/Search";
import { Usercontext } from "../context/Usercontexte";
import PDFViewer from "../compenent/Receipts/PDFveiw";
import ReceiptsSkeleton from "../compenent/LoadingSkeleton/ReceiptsSkeleton";
import { useTranslation } from "react-i18next";
import { themecontext } from "../context/Themecontexte";
import FakeReceipt from "../compenent/Receipts/FakeReceipt";
import MangeReceipt from "../compenent/Receipts/MangeReceipt.jsx";
import Cookies from "js-cookie";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import "../App.css";
import {
  GetReceiptFromEmail,
  setIdAndNavigateToImportPage,
} from "../API/importEmails";
import {
  SwipeableList,
  SwipeableListItem,
  TrailingActions,
  LeadingActions,
  SwipeAction,
} from "react-swipeable-list";
import "react-swipeable-list/dist/styles.css";
import Social from "../compenent/Socials";
import ConfirmReceipts from "../compenent/Receipts/ConfirmReceiptsOfEmail";
import { Receiptscontext } from "../context/Receiptscontext";
import Masseege from "../compenent/masseege";
import "swiper/swiper-bundle.css";
import deleteImg from "../assest/delete.svg";
import Edit from "../assest/modify.svg";
import { handleReceiptsSearch } from "../Functions/SearchReceipts.js";
function Receipts() {
  const [ImgOpen, setImgOpen] = useState(false);
  const [FilterOpen, setFilterOpen] = useState(false);
  const [intigration, setIntigration] = useState(null);
  const [loadingReceipt, setloadingReceipt] = useState(false);
  const [ConfirmIsOpen, setConfirmIsOpen] = useState(false);
  const [Massage, setMassage] = useState({ id: "", action: "" });
  const { t, i18n } = useTranslation();
  const { User } = useContext(Usercontext);
  const { Receiptes, isLoading, setReceiptes } = useContext(Receiptscontext);
  const { theme } = useContext(themecontext);
  const [FiltredData, setFiltredData] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [msg, setMsg] = useState({ text: "", color: "" });
  var isArabic = i18n.language.startsWith("ar");
  const [EmailReceipts, setEmailReceipts] = useState(null);

  useEffect(() => {
    if (searchTerm == "") {
      setFiltredData(Receiptes);
    }
  }, [searchTerm]);
  // Geting  externalAccountId & integration from searchParams
  useEffect(() => {
    const location = window.location;
    const searchParams = new URLSearchParams(location.search);
    const integration = searchParams.get("integration");
    setIntigration(integration);
    const externalAccountId = setIdAndNavigateToImportPage(location);
    if (externalAccountId) {
      const access_token = Cookies.get("access_token");
      setTimeout(() => {
        setloadingReceipt(true);
      }, 1000);
      if (integration == "gmail") {
        async function getReceipt() {
          const message = await GetReceiptFromEmail(
            access_token,
            "gmail",
            externalAccountId
          );
          if (message == "error") {
            setMsg({
              text: t("This Email Doesn't contain Receipts"),
              color: "bg-[#FF1203F5] text-[0.9rem]",
            });
            setloadingReceipt(false);
          } else {
            setEmailReceipts({
              data: message,
              source: "gmail",
            });
            setTimeout(() => {
              setConfirmIsOpen(true);
            }, 5000);
          }
        }
        getReceipt();
      } else {
        async function getReceipt() {
          const message = await GetReceiptFromEmail(
            access_token,
            "outlook",
            externalAccountId
          );
          if (message == "error") {
            setMsg({
              text: t("This Email Doesn't contain Receipts"),
              color: "bg-[#FF1203F5] text-[0.9rem]",
            });
          } else {
            setEmailReceipts({
              data: message,
              source: "outlook",
            });
            setTimeout(() => {
              setConfirmIsOpen(true);
            }, 5000);
          }
        }
        getReceipt();
      }
    }
  }, []);

  // search functionaly -------------
  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    handleReceiptsSearch(Receiptes, searchTerm, setFiltredData);
  };
  useEffect(() => {
    handleReceiptsSearch(Receiptes, searchTerm, setFiltredData);
  }, [searchTerm]);
  // ---------------------------------

  useEffect(() => {
    // Set FiltredData once Receiptes is available
    if (Receiptes) {
      setFiltredData(Receiptes);
    }
  }, [Receiptes]);

  // rest the filter and the search
  function SeeAllReciptce() {
    setFiltredData(Receiptes);
    setSearchTerm("");
  }

  // cheking if the first Visit of the app to display the fake Receipt
  const tour = JSON.parse(localStorage.getItem("tour"));

  //  Swipe to edit the imported receipt
  const trailingActions = (id) => {
    return (
      <TrailingActions>
        <SwipeAction
          onClick={() => {
            setMassage({ id: id, action: "edit" });
          }}
          style={{ backgroundColor: "#ff0000", color: "#fff" }}
        >
          <div className="  flex items-center rounded-r-md  w-full h-full bg-main ">
            <img className=" mx-[15%] w-[1.2rem]  " src={Edit} alt="" />
          </div>
        </SwipeAction>
      </TrailingActions>
    );
  };

  // Swipe to Delete imported receipt
  const leadingActions = (id) => (
    <LeadingActions>
      <SwipeAction
        onClick={() => {
          setMassage({ id: id, action: "delete" });
        }}
      >
        <div className="  flex items-center rounded-l-md  w-full h-full bg-red-500">
          <img className=" mx-[15%] w-[1.2rem]  " src={deleteImg} alt="" />
        </div>
      </SwipeAction>
    </LeadingActions>
  );

  return (
    <>
      <Navbar />
      <div className="  overflow-hidden  flex flex-col px-[1.5rem] pb-20  gap-3  ">
        {/* Filter */}
        <Filter
          theme={theme}
          Receiptes={Receiptes}
          FiltredData={FiltredData}
          setFiltredData={setFiltredData}
          setFilterOpen={setFilterOpen}
          FilterOpen={FilterOpen}
        />

        <h1
          className={`  font-[700] w-full  gap-2 text-[1.3rem] flex ${
            isArabic ? " flex-row-reverse " : " flex-row"
          } flex`}
        >
          {t("receipts.title1")} <span className=" text-main">FTR Client</span>{" "}
        </h1>
        <p className=" text-sec text-[.8rem] pt-[.6rem] ">
          {t("receipts.par")}
        </p>
        <div
          className={`pt-[1.8rem] w-full  h-full flex ${
            isArabic ? " flex-row-reverse " : " flex-row"
          } gap-1 `}
        >
          {/* Search input */}
          <span className=" relative w-full ">
            <p
              className={`  text-[#9C9C9C]  flex flex-row gap-[.35rem] items-center  absolute  top-[1.5rem]  ${
                isArabic ? "   right-6 " : " left-3 "
              }  `}
            >
              <SearchIcon className=" scale-75 absolute  -top-1 -right-5 " />
            </p>
            <input
              value={searchTerm}
              onChange={handleInputChange}
              type="text"
              className={` text-[.75rem] px-8  ${
                isArabic ? "  text-right " : "  text-left"
              }    bg-gray-500 bg-opacity-5  rounded-[.3rem] w-full h-[3.8rem] `}
              placeholder={t("receipts.placeholder")}
            />
          </span>
          {/* Display Filter Button */}
          <button
            onClick={() => setFilterOpen(true)}
            className=" bg-main rounded-[.3rem]   py-[1rem] px-[.7rem]  justify-center items-center flex "
          >
            <FilterListIcon className=" text-white" />
          </button>
        </div>

        <div
          className={`pt-[1.1rem] flex ${
            isArabic ? " flex-row-reverse" : " flex-row"
          } justify-between w-full`}
        >
          <h1 className=" text-[1rem]  font-[600]  ">{t("receipts.title2")}</h1>

          {/* Clear the Filter */}
          {Receiptes && (
            <div
              onClick={SeeAllReciptce}
              className={`flex ${
                isArabic ? " flex-row-reverse " : " flex-row"
              } justify-center items-center gap-1 text-[0.75rem] font-medium`}
            >
              <p>{t("receipts.seeall")}</p>

              <ChevronRightIcon fontSize="" />
            </div>
          )}
        </div>
        {/* Loading Animation */}
        {isLoading ? (
          <ReceiptsSkeleton receipts={8} />
        ) : (
          <motion.div>
            {/* Fake Receipt For the Tour */}
            {!tour && <FakeReceipt />}

            {Receiptes?.length <= 0 || !User ? (
              <NoReceipts />
            ) : (
              <SwipeableList className=" flex flex-col gap-2">
                {FiltredData?.map((item, index) =>
                  // Check if the source is true before rendering the SwipeableListItem
                  item?.source ? (
                    <SwipeableListItem
                      key={index}
                      trailingActions={trailingActions(item.id)}
                      leadingActions={leadingActions(item.id)}
                    >
                      <Receipt
                        FilterOpen={FilterOpen}
                        key={index}
                        name={item?.name}
                        data={item?.data}
                        source={item?.source}
                        filtredata={FiltredData}
                        image={item?.image}
                        type={item?.file_type}
                        receipt={item.receipt}
                        id={item.id}
                        json={item.json}
                        date={item.created_at}
                      />
                    </SwipeableListItem>
                  ) : (
                    <div key={index}>
                      <Receipt
                        FilterOpen={FilterOpen}
                        key={index}
                        data={item?.data}
                        source={item?.source}
                        filtredata={FiltredData}
                        image={item?.image}
                        type={item?.file_type}
                        receipt={item.receipt}
                        id={item.id}
                        json={item.json}
                        date={item.created_at}
                      />
                    </div>
                  )
                )}
              </SwipeableList>
            )}
          </motion.div>
        )}
      </div>
      {/* Form to choose the Way to import receipts from email */}
      {ImgOpen && <DisplayReceipt setImgOpen={setImgOpen} ImgOpen={ImgOpen} />}
      <Social
        EmailReceipts={EmailReceipts}
        integration={intigration}
        setIsOpen={setloadingReceipt}
        isOpen={loadingReceipt}
      />
      {/* Confirme imported Receipts From Email */}
      {ConfirmIsOpen && (
        <ConfirmReceipts
          setloadingReceipt={setloadingReceipt}
          data={EmailReceipts}
          setConfirmIsOpen={setConfirmIsOpen}
        />
      )}
      {/* Massege */}
      <Masseege msg={msg} setMsg={setMsg} />
      {/* Delete or Edit Imported Receipt */}
      <MangeReceipt
        setMassage={setMassage}
        Action={Massage?.action}
        id={Massage?.id}
        setReceiptes={setReceiptes}
        FiltredData={FiltredData}
      />
    </>
  );
}

export default Receipts;
