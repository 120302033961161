// Importing necessary modules and dependencies
import { React, useState, useContext, useEffect } from "react";
import TopBar from "../TopBar";
import CloseBtn from "../../assest/CloseBtn.svg";
import generateAddCardSchema from "../../Validation/AddCardVal";
import { useFormik } from "formik";
import LoadingBtn from "../LoadingBtn";
import Masseege from "../masseege";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getCodeBarFromPic } from "../../Functions/getCodeBarFromPic";
import { CardsContext } from "../../context/Cardscontext";
import AddCardOrUpdate from "../../API/CardAPI";
import { addCardOrUpdateToLocalDB } from "../../DB/LoyaltyCardDB";
import cameraImg from "../../assest/camera.svg";
import useOnlineStatus from "../../hooks/useOnlineStatus";
import { Cropperr } from "../Cropper";
import { themecontext } from "../../context/Themecontexte";
import { HandelUpdateOrAddCard } from "../../Functions/HandelUpdateOrAddCard";
import ImageUpload from "./ImageUpload";
// AddLoyaltyCard Component
function AddLoyaltyCard() {
  // State variables
  const [frontImage, setFrontImage] = useState(null);
  const [ResultOfFrontImg, setResultOfFrontImg] = useState(null);
  const [backImage, setBackImage] = useState(null);
  const [ResultOfBackImg, setResultOfBackImg] = useState(null);
  const [msg, setMsg] = useState({ text: "", color: "" });
  const [Code, setCode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  
  const { setLoyaltyCard, card } = useContext(CardsContext);
  const { theme } = useContext(themecontext);

  const Navto = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const brand = searchParams.get("brand");
  const isOnline = useOnlineStatus();
  const { id } = useParams();
  const oneCard = card?.find((item) => item.id == id);
  
  const { t, i18n } = useTranslation();
  let isArabic = i18n.language.startsWith("ar");
    // geting validation Schema 
  const AddCardSchema = generateAddCardSchema();

  
    // geting the barcode value from the back image
  useEffect(() => {
    const hundelgetCodeBarFromPic = async () => {
      if (ResultOfBackImg) {
        try {
          const code = await getCodeBarFromPic(ResultOfBackImg);
          setCode(code);
        } catch (error) {}
      }
    };
    hundelgetCodeBarFromPic();
  }, [ResultOfBackImg]);


    // updating the lang of the Schema
    useEffect(() => {
      const updatedSchema = generateAddCardSchema();
    }, [i18n.language]);
  

  // Formik form setup
  const {
    setValues,
    values,
    handleBlur,
    errors,
    touched,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      storename: "",
      cardnumber: "",
      notes: "",
    },
    validationSchema: AddCardSchema,
    onSubmit,
  });


  // Effect to update values when Code changes
  useEffect(() => {
    setValues({
      ...values,
      cardnumber: Code,
    });
  }, [Code]);
 
  // File change handler for front image
  const handleFileChange = async (e, setImage ) => {
    const selectedFile = e.target.files[0];
    const fileSizeInMB = selectedFile?.size / (1024 * 1024);  

    // Check if the file size is less than 2MB
    if (fileSizeInMB >= 5) {
      setMsg({
        text: t("Msg.FileSizeExceed"),
        color: "bg-[#FF1203F5] text-[0.9rem]",
      });
      return;
    }
    setImage(selectedFile);
    if (!selectedFile) {
      return;
    }
  };
 
  // when the submition is complited
  function handleSubmissionComplete() {
    setIsLoading(false);
    setTimeout(() => {
      Navto("/Cards/LoyaltyCards");
    }, 1000);
  }
  // Form submission handler
  async function onSubmit() {
    console.log('Submitting...');
    setIsLoading(true);
  
    try {
      await HandelUpdateOrAddCard({
        isOnline,
        id,
        values,
        CfrontImage:ResultOfFrontImg,
        CbackImage: ResultOfBackImg,
        setLoyaltyCard,
        setMsg,
        setIsLoading,
        handleSubmissionComplete,
      });
  
      console.log('Submission successful.');
    } catch (error) {
      console.error('Error in onSubmit:', error);
      // Handle the error as needed
    } finally {
      setIsLoading(false);
    }
  }
   
  // Effect to get already existing card
  useEffect(() => {
    setValues({
      storename: brand,
    });
  }, [brand]);

  return (
    <div className= {`fixed top-0 right-0 ${!theme ?' bg-[#212121] ' : 'bg-white'}  w-full h-full z-50 overflow-auto px-[1.2rem] pt-[1.8rem] flex flex-col`}  >
      <TopBar text={id ? t("card.EditC") : t("card.addC")} />

      <form className="  py-12" onSubmit={handleSubmit}>
        <div
          className={` w-full flex ${
            isArabic ? "  flex-row-reverse " : "  flex-row"
          } gap-2 justify-center items-center `}
        >
            {/* Front Image Upload */}
        <ImageUpload
        Edit={true}
          image={ResultOfFrontImg}
          setImage={setResultOfFrontImg}
          handleFileChange={(e) => handleFileChange(e, setFrontImage)}
          label={t("card.Front")}
        />

        {/* Back Image Upload */}
        <ImageUpload
        Edit={true}
          image={ResultOfBackImg}
          setImage={setResultOfBackImg}
          handleFileChange={(e) => handleFileChange(e, setBackImage)}
          label={t("card.Back")}
        />
        </div>

        <div id="qr-code-container"></div>

        {/* Store Name Input */}
       
          <h1 className=" font-medium  pb-2   pt-[1.5rem] text-[.9rem] ">
            {t("card.Sname")}
          </h1>
          <input
            placeholder={t("card.SnamePlaceh")}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.storename}
            className={` focus-visible:ring-2 focus-visible:outline-none focus-visible:ring-main   ${isArabic ? "   text-right":"text-left"}  ${
              errors.storename && touched.storename
                ? "   focus-visible:ring-red-500  text-red-500 border-[2px]"
                : ""
            } text-sec w-full h-[4rem] bg-transparent px-[1rem] text-sm  border-solid border-[1px] rounded-[0.32rem]`}
            type="text"
            name=""
            id="storename"
          />
          {errors.storename && touched.storename && (
            <p className="   pt-1 px-1 text-xs text-red-400">
              
              {errors.storename} 
            </p>
          )}
        

        {/* Card Number Input */}
          <h1 className="pb-2  pt-[1.5rem] text-[.9rem] font-medium ">
           
          </h1>
          <input
            placeholder={t("card.CnumberPlach")}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.cardnumber}
            className={`  ${isArabic ? "  text-right " : "  text-left"}  ${
              errors.cardnumber && touched.cardnumber
                ? " border-[#fc8181] text-red-500  border-[2px]"
                : ""
            } text-sec w-full h-[4rem] bg-transparent   px-[1rem]   text-sm  border-solid border-[1px] rounded-[0.32rem]`}
            type="text"
            id="cardnumber"
          />
          {errors.cardnumber && touched.cardnumber &&  (
            <p className=" pt-1 px-1 text-xs text-red-400">
              {" "}
              {errors.cardnumber}{" "}
            </p>
          )}

        {/* Notes Input */}
        <div className=" flex flex-col   ">
          <h1 className="pb-2   pt-[1.5rem] text-[.9rem] font-medium  ">
            {t("card.Anotes")}
          </h1>
          <input
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.notes}
            placeholder={t("card.Notes")}
            className={`   ${
              isArabic ? "  text-right " : "  text-left"
            } bg-transparent   text-sec w-full h-[4rem]   px-[1rem]   text-sm  border-solid border-[1px] rounded-[0.32rem]`}
            id="notes"
          />

          <p className=" pt-1 px-1 text-xs text-gray-400">
            {" "}
            {t("card.addNote")}{" "}
          </p>
        </div>

        {/* Form Buttons */}
        <div className=" w-full pt-10 flex flex-row gap-2 ">
          {/* Cancel Button */}
          <button
            onClick={(e) => {
              e.preventDefault();
              Navto(-1);
            }}
            className=  {`h-[3.5rem]  text-[1.1rem]  ${theme ?'  border-[#F8F8F8] ' :  ' border-[#424242] '}    border-solid border-[1px] text-gray-400   font-medium   w-full  rounded-[0.32rem]`} 
          >
            {t("card.Cancel")}
          </button>

          {/* Submit Button */}
          <button
            disabled={isLoading}
            type="submit"
            className=" h-[3.5rem] relative  text-[1.1rem] font-medium text-white w-full bg-main rounded-[0.32rem] "
          >
            {t("card.Submit")}
            <LoadingBtn color={"#fff"} isLoading={isLoading} bg={"bg-main"} />
          </button>
        </div>
      </form>
      {backImage && (
        <Cropperr
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          CardImg={backImage}
          setResult={setResultOfBackImg}
          setImage={setBackImage}
        />
      )}

      {frontImage && (
        <Cropperr
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          CardImg={frontImage}
          setResult={setResultOfFrontImg}
          setImage={setFrontImage}
        />
      )}
      {/* Message Component */}
      <Masseege msg={msg} setMsg={setMsg} />
    </div>
  );
}

// Exporting the AddCardManully component
export default AddLoyaltyCard;
