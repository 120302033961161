import { React, useEffect, useState, useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { themecontext } from "../../context/Themecontexte";
import { ProcessChartData } from "../../Functions/ProcessChartData";
import { LinedChartOption } from "../../assest/data/ChartOption";
const LinedChart = ({
  Physical,
  Digital,
  Empact,
  state,
  Period,
  colors,
  TrendsChartD,
}) => {
  const { theme } = useContext(themecontext);
  const [PhysicalChartD, setPhysicalChartD] = useState();
  const [DigitalChartD, setDigitalChartD] = useState();
  const [TrendsChart, setTrendsChart] = useState();
  const [EmpactChartD, setEmpactChartD] = useState();

  // prossecing data for Chart -----------------------
  useEffect(() => {
    const { processedData } = ProcessChartData(Digital, Period, false);
    setDigitalChartD(processedData);
  }, [Digital]);

  useEffect(() => {
    const { processedData } = ProcessChartData(Physical, Period, false);
    setPhysicalChartD(processedData);
  }, [Physical]);

  useEffect(() => {
    const { processedData } = ProcessChartData(Empact, Period, false);
    setEmpactChartD(processedData);
  }, [Empact]);

  useEffect(() => {
    const { processedData } = ProcessChartData(TrendsChartD, Period, false);
    setTrendsChart(processedData);
  }, [Period, TrendsChartD]);
  // --------------------------------------------------------------------------

  // Lined Chart Config
  const Option = LinedChartOption(
    state,
    theme,
    colors,
    PhysicalChartD,
    DigitalChartD,
    TrendsChart,
    EmpactChartD
  );

  return (
    <div className=" relative w-full h-full  mt-[.7rem] ">
      <div className="  w-full flex  pb-[-1rem]  rounded-[.32rem] pt-[.5rem] pl-[.4rem] pr-[1.5rem] border-solid border-[1px]    flex-col   h-[250px] ">
        <ReactApexChart
          options={Option}
          series={Option.series}
          type="line"
          height={"100%"}
          width={"102%"}
        />
      </div>
    </div>
  );
};

export default LinedChart;
