import React from 'react'
import noReceiptsIcon from '../../assest/Noreceipt.svg'
import { useTranslation } from 'react-i18next'
function NoReceipts() {
  const {t} = useTranslation()
  return (
    <div className=' mt-2 flex-col gap-8 w-full h-[50vh]  flex  items-center justify-center   '>
        <img className=' h-[13rem] w-[13rem]  ' src={noReceiptsIcon} alt="" />
        <p className=' opacity-90 font-medium '> {t('receipts.Oops')} </p>
    </div>
  )
}

export default NoReceipts