// Importing necessary modules and dependencies
import { createContext, useState, useRef, useEffect, useContext } from "react";
// import { initializeFirebaseMessaging, onFirebaseMessage } from "../Firebase";
import axios from "axios";
import Cookies from "js-cookie";
import { addNewItemID } from "../Functions/addNewItemID";
import { Usercontext } from "./Usercontexte";
import { CheckNotification } from "../Notification/CheckNotification";
import { clearDataAndLogout } from "../Functions/Logout";
import { CardsContext } from "./Cardscontext";
import {
  AddOneReceiptToDB,
  checkAndAddAllReceiptsToDB,
  fetchLocalData,
} from "../DB/ReceiptDB";

// Creating a context for managing receipts-related state
export const Receiptscontext = createContext();

// Receiptsprovider Component
const Receiptsprovider = ({ children }) => {
  // State variables
  const [Token, setToken] = useState("");
  const [Receiptes, setReceiptes] = useState(null);
  const [FirstFetch, setFirstFetch] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [highlightedReceipts, setHighlightedReceipts] = useState([]);
  const [Massage, setMassage] = useState(null);
  const [data, setData] = useState([]);
  const [TabVisibol, setTabVisibol] = useState(false);
  const { User, setUser } = useContext(Usercontext);
  const { setLoyaltyCard } = useContext(CardsContext);

  // Get all the receipts from the server
  // useEffect(() => {
  //   if (User && FirstFetch) {

  // }, [User]);



  // geting notification of the receipts
  useEffect(() => {
    // Fetching Firebase token
    // const fetchFcmToken = async (setToken) => {
    //   try {
    //     const currentToken = await initializeFirebaseMessaging(setToken);
    //   } catch (error) {
    //     console.error("Error fetching Firebase token:", error);
    //   }
    // };

    // fetchFcmToken(setToken);

    // Firebase message listener for notifications
    const messageListener = (payload) => {
      // Formating the incoming data
      const receiptData = {
        json: JSON.parse(payload.data.json.replace(/'/g, '"')),
        id: parseInt(payload.data.id),
        shop: parseInt(payload.data.shop),
        created_at: payload.data.created_at,
        receipt: payload.data.receipt,
        updated_at: payload.data.updated_at,
        file_type: payload.data.file_type,
      };

      // Checking and adding receipts to the local DB
      AddOneReceiptToDB(receiptData);
      // Adding a new item ID to the highlighted items state
      addNewItemID(receiptData?.id, setHighlightedReceipts);
      // Updating the receipts State by adding a new receipt
      setReceiptes((prevReceiptes) => {
        // Check if prevReceiptes is null (initial state)
        if (prevReceiptes === null) {
          // If it's null, return a new array with only the new receiptData
          return [receiptData];
        } else {
          // If it's not null, return a new array with the new receiptData at the beginning
          return [receiptData, ...prevReceiptes];
        }
      });
      setMassage("New Ntification");
    };

    // onFirebaseMessage(messageListener);

    // Cleanup function
    return () => {};
  }, [User]);

  // Get all the notifications and Store & display them
  useEffect(() => {
    if (data.length > 0) {
      setMassage("New Ntification");
      data?.forEach((receipt) => {
        const jsonReceipt = JSON.parse(receipt.data);
      

        const RececiptData = {
          json: JSON.parse(jsonReceipt.json.replace(/'/g, '"')),
          id: parseInt(jsonReceipt.id),
          shop: parseInt(jsonReceipt.shop),
          created_at: jsonReceipt.created_at,
          receipt: jsonReceipt.receipt,
          updated_at: jsonReceipt.updated_at,
          file_type: jsonReceipt.file_type,
        };
        

         // Checking and adding receipts to the local DB
      // Adding a new item ID to the highlighted items state
      addNewItemID(RececiptData?.id, setHighlightedReceipts);
      // Updating the receipts State by adding a new receipt
      setReceiptes((prevReceiptes) => {
        // Check if prevReceiptes is null (initial state)
        if (prevReceiptes === null) {
          // If it's null, return a new array with only the new receiptData
          return [RececiptData];
        } else {
          // If it's not null, return a new array with the new receiptData at the beginning
          return [RececiptData, ...prevReceiptes];
        }
      });
      AddOneReceiptToDB(RececiptData);

      setMassage("New Ntification");
 
      });
    }
  }, [ TabVisibol ,User ]);

  // Sending the Fcm_Token to the Server
  useEffect(() => {
    const token = Cookies.get("access_token");
    if (token) {
      axios
        .put(
          "/api/clients/me",
          {
            fcm_token: Token,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then(({ data }) => {})
        .catch((err) => {
          console.error("Error sending Fcm_Token to the server:", err);
        });
    }
  }, [Token]);
 

  // Geting the Receipts From the local DB OR Server
  useEffect(() => {
    const getReceiptes = async () => {
      if (User) {
        try {
          const Receipts = await fetchLocalData();
          if (Receipts?.length > 0) {
            setIsLoading(false);
            setReceiptes(Receipts?.reverse());
          } else {
            const token = Cookies.get("access_token");
            axios
              .get("/api/clients/receipts", {
                headers: {
                  Authorization: token,
                },
              })
              .then((response) => {
                const { data } = response;
                setReceiptes(data?.reverse());
                setIsLoading(false);
              })
              .catch((error) => {
                console.error(
                  "Error fetching receipts from the server:",
                  error
                );
                setReceiptes(null);
              });
          }
        } catch (error) {
          console.error("Error fetching receipts ", error);
        }
      }
    };
    getReceiptes();
  }, [User]);

  // Saving the Receipts in the local DB
  useEffect(() => {
    if (Receiptes) {
      checkAndAddAllReceiptsToDB(Receiptes);
    }
  }, [User, Receiptes]);

  // Creating the local DB for notifications
  useEffect(() => {
    var request = indexedDB.open("notificationDB", 1);
    request.onupgradeneeded = function (event) {
      const db = event.target.result;
      const objectStore = db.createObjectStore("notifications", {
        keyPath: "id",
      });
    };
    CheckNotification(setData);
  }, []);

  // Geting the token from the URL and Store it
  useEffect(() => {
    const url = new URL(window.location.href);
    const token = url.searchParams.get("token");
    if (User) return;
    if (token) {
      clearDataAndLogout(setLoyaltyCard, setReceiptes, setUser, setFirstFetch);
      Cookies.set("access_token", "Token " + token);
      const access_token = "Token " + token;
      axios
        .get("/api/clients/me", {
          headers: { Authorization: access_token },
        })
        .then(({ data }) => {
          setUser(data);
        })
        .catch((err) => {});
    }
  }, []);

  // Providing the Receiptscontext to the component tree
  return (
    <Receiptscontext.Provider
      value={{
        Receiptes,
        setReceiptes,
        setIsLoading,
        highlightedReceipts,
        setHighlightedReceipts,
        Massage,
        setMassage,
        isLoading,
      }}
    >
      {children}
    </Receiptscontext.Provider>
  );
};

// Exporting the Receiptsprovider component
export default Receiptsprovider;
