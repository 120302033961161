// Importing necessary dependencies and components
import React, { useContext, useEffect, useState } from "react";
import { themecontext } from "../../context/Themecontexte";
import colseBtn from "../../assest/CloseBtn.svg";
import SearchIcon from "@mui/icons-material/Search";
import EmailReceipts from "./EmailReceipt";
import { addNewItemID } from "../../Functions/addNewItemID";
import { AddImportedReceiptsToDB } from "../../Functions/AddImportedReceiptsToDB";
import "../../App.css";
import LoadingBtn from "../LoadingBtn";
import { useTranslation } from "react-i18next";
import { Receiptscontext } from "../../context/Receiptscontext";

// Functional component for confirming receipts
function ConfirmReceipts({ data, setConfirmIsOpen }) {
  // Context and state variables
  const { theme } = useContext(themecontext);
  const { setMassage, setReceiptes, setHighlightedReceipts } =
    useContext(Receiptscontext);
  const [EachData, setEachData] = useState(null);
  const [masterCheckbox, setMasterCheckbox] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);
  const [checkboxStates, setCheckboxStates] = useState({});
  const [FilterData, setFilterData] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const { t, i18n } = useTranslation();
  var isArabic = i18n.language.startsWith("ar");

  // Search Functionality --------
  useEffect(() => {
    setFilterData(EachData);
  }, [EachData]);

  useEffect(() => {
    handleSearch();
  }, [searchTerm]);

  const handleSearch = () => {
    const cards = EachData?.filter((item) =>
      item?.from?.toLowerCase()?.includes(searchTerm?.toLowerCase())
    );
    setFilterData(cards);
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    handleSearch();
  };
  // --------------------

  useEffect(() => {
    // Assuming EachData is an array of objects with unique identifiers
    EachData?.forEach((data) => {
      // Initialize checkbox state for each data item
      setCheckboxStates((prevStates) => ({
        ...prevStates,
        [data.id]: true, // Assuming each data item has a unique identifier, adjust accordingly
      }));
    });
  }, [EachData]);

  // function to control all the checkBoxes
  const handleMasterCheckboxChange = () => {
    setMasterCheckbox(!masterCheckbox);
    setCheckboxStates((prevStates) => {
      const updatedStates = {};
      Object.keys(prevStates).forEach((id) => {
        updatedStates[id] = !masterCheckbox;
      });
      return updatedStates;
    });
  };

  // changing all checkBoxs
  useEffect(() => {
    const allCheckboxesChecked = Object.values(checkboxStates).every(Boolean);
    if (allCheckboxesChecked) {
      setMasterCheckbox(true);
    } else {
      setMasterCheckbox(false);
    }
  }, [checkboxStates]);

  // changing singel checkBox
  const handleCheckboxChange = (checkboxName) => (event) => {
    const { checked } = event.target;
    setCheckboxStates((prevState) => ({
      ...prevState,
      [checkboxName]: checked,
    }));
    console.log(checkboxStates);
  };

  // Function to extract all data from all messages in the JSON data
  function extractAllData(jsonData) {
    const extractedData = jsonData?.flatMap((message) => {
      const messageObj = message[0];
      if (messageObj) {
        return {
          id: messageObj.message_id,
          snippet: messageObj.snippet,
          body: messageObj.body,
          from: messageObj.from,
          date: messageObj.date,
          subject: messageObj.subject,
        };
      }

      return null;
    });

    return extractedData.filter((data) => data !== null); // Remove any null values
  }

  useEffect(() => {
    // Checking if there is email receipts
    if (data?.data == "error") {
      setConfirmIsOpen(false);
      return;
    }

    const allDataArray = extractAllData(data?.data);
    setEachData(allDataArray);
  }, []);

  // generateUniqueId to Store it in the localDB
  function generateUniqueId() {
    return "_" + Math.random().toString(36).substr(2, 9);
  }

  // Function to confirm selected data
  const ConfirmSelectedData = () => {
    setIsLoading(true);
    // Filtring the selected receipts
    const selectedData = EachData.filter((data) => checkboxStates[data.id]);
    // fromationg the receipts data to store it in the localDB
    selectedData.forEach((item) => {
      const receiptData = {
        id: generateUniqueId(),
        source: data.source,
        data: item,
        file_type: "email",
      };
      // Storing the receipts in the localDB & Display them
      AddImportedReceiptsToDB(
        receiptData,
        setMassage,
        addNewItemID,
        setReceiptes,
        setHighlightedReceipts
      );
    });
    // Seting time and close the confimation page
    setTimeout(() => {
      setConfirmIsOpen(false);
    }, 1000);
  };
  return (
    <>
      <div
        className={` px-[1.2rem] pt-[1.7rem]  w-full h-full fixed top-0 right-0  z-40 ${
          !theme ? " bg-[#212121]  " : " bg-white"
        } `}
      >
        <img
          onClick={() => setConfirmIsOpen(false)}
          className={` absolute top-5  ${isArabic ? " left-5" : " right-5"} `}
          src={colseBtn}
          alt=""
        />
        <h1 className=" text-[1.1rem] font-semibold ">
          {" "}
          {t("importReFromEmail.MyReceipts")}{" "}
        </h1>
        <p className="  font-medium  py-4 text-sec text-[.9rem] ">
          {t("importReFromEmail.ConfirmEmails")}
        </p>
        <span className=" relative">
          <SearchIcon
            className={` text-gray-400 absolute ${
              isArabic ? " right-3" : " left-3"
            } top-1`}
          />
          <input
            onChange={handleInputChange}
            placeholder={t("importReFromEmail.SearchEmails")}
            className={` px-[2.5rem] w-full h-[3.5rem] border-solid border-[1px] rounded-md   bg-transparent bg-opacity-40 ${
              isArabic ? "text-right" : " text-left"
            }  `}
            type="text"
          />
        </span>

        <span className="  text-sm font-semibold flex flex-row gap-2 pt-6 pb-3 border-solid border-b-[1px] items-center ">
          <input
            checked={masterCheckbox}
            onChange={handleMasterCheckboxChange}
            className="  h-5 w-5   "
            type="checkbox"
            name="selectall"
            id="selectall"
          />
          <label htmlFor="selectall"> {t("importReFromEmail.SelectAll")}</label>
        </span>

        <div className=" pt-[1.6rem] h-full pb-[19rem] my-custom-scrollbar pr-2   overflow-y-auto w-full overflow-x-hidden flex flex-col gap-2">
          {FilterData?.map((item, index) => (
            <EmailReceipts
              key={index}
              checkboxId={item?.id}
              checkboxStates={checkboxStates}
              handleCheckboxChange={handleCheckboxChange}
              date={item.date}
              subject={item.subject}
              body={item.body}
              from={item.from}
            />
          ))}
        </div>
        <div
          className={` ${
            theme ? " bg-white" : "bg-[#212121]"
          } absolute bottom-0  h-20 right-0 flex w-full   justify-center items-center`}
        >
          <button
            onClick={ConfirmSelectedData}
            className="   text-lg text-white  font-semibold w-[90%] h-14 rounded-md   relative  bg-main "
          >
            {t("importReFromEmail.ConfirmAction")}
            <LoadingBtn
              bg={"bg-main"}
              color={"#fff"}
              isLoading={IsLoading}
              small={true}
            />
          </button>
        </div>
      </div>
    </>
  );
}

export default ConfirmReceipts;
