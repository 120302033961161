import * as yup from 'yup';
import i18n from '../i18n';

const generateResetPassSchema = () => {
  return yup.object().shape({
    email: yup.string().email(i18n.t('inputsVal.emailInvalid')).required(i18n.t('inputsVal.emailRequired')),
  });
};

export default generateResetPassSchema;
