import React, { useEffect , useState} from "react";
import Barcode from "react-barcode";

function DisplayBarcode({ OneCard }) {
  const [Gcolor, setGColor] = useState("#FFFFFF");

  useEffect(() => {
    const newColor = generateColor(OneCard?.store_name);
    setGColor(newColor);
  }, [OneCard]);

  // Function to generate color based on input text
  const generateColor = (inputText) => {
    // Simple hashing function
    const hashCode = (str) => {
      let hash = 0;
      for (let i = 0; i < str?.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      return hash;
    };

    // Convert the hashed value to a color
    const intToRGB = (i) => {
      const c = (i & 0x00ffffff).toString(16).toUpperCase();
      return "00000".substring(0, 6 - c.length) + c;
    };

    const hash = hashCode(inputText);
    const generatedColor = `#${intToRGB(hash)}`;

    return generatedColor;
  };

  return (
    <div
      style={
        !OneCard?.front_image
          ? { backgroundColor: Gcolor }
          : {
              backgroundImage: `url(${
                OneCard?.front_image ||
                URL.createObjectURL(OneCard?.front_image)
              })`,
              backgroundSize: "250%",
            }
      }
      className={` flex flex-col gap-6 bg-no-repeat  items-center justify-start  bg-cover bg-top   text-white  font-[600] text-[1.7rem] p-[1.2rem]    w-full h-[10rem]  `}
    >
      <h1>{OneCard?.store_name} </h1>

      {/* Barcode Display */}
      <div className="  rounded-xl text-xs   max-w-full p-2  bg-white  border-solid border-[1px]  h-[10rem]  flex items-center justify-center  ">
        <span className={` scale-[.85]    sm:scale-[1.6]`}>
          <Barcode
            value={OneCard?.code}
            height={"100%"}
            displayValue={false}
            width={OneCard?.code.length > 8 ? "2rem" : "3rem"}
          />
        </span>
      </div>
    </div>
  );
}

export default DisplayBarcode;
