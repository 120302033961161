import offer1 from '../../assest/offer1.png'
import offer2 from '../../assest/offer2.png'
import offer3 from '../../assest/offer3.png'
import offer4 from '../../assest/offer4.png'
import offer5 from '../../assest/offer5.png'





export  const  Fdata = [
   {
       id:1,
       img:offer1,
       text:'Addidas',
        p:"Valid for 7 days"
   },
   {
       id:2,
       img:offer2,
       text:'Addidas',
       p:"Valid for 7 days"
   },{
       id:3,
       img:offer3,
       text:'Addidas',
       p:"Valid for 7 days"
   },{
       id:4,
       img:offer4,
       text:'Addidas',
       p:"Valid for 7 days"
   },{
       id:5,
       img:offer5,
       text:'Addidas',
        p:"Valid for 7 days"
   },
    {
       id:2,
       img:offer2,
       text:'Addidas',
       p:"Valid for 7 days"
   },
 {
       id:4,
       img:offer4,
       text:'Addidas',
       p:"Valid for 7 days"
   },
]