import { React, useContext, useState } from "react";
import { Usercontext } from "../context/Usercontexte";
import LogoutIcon from "@mui/icons-material/Logout";
import Social_login from "../compenent/Signin/Social_login";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Mode from "../compenent/Account/Mode";
import Handedness from "../compenent/Account/Handedness";
import ShareWith from "../compenent/Account/ShareWith";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { clearDataAndLogout } from "../Functions/Logout";
import { sendFcmT } from "../API/SendFcmT";
import Linked from "../../src/assest/linked.svg";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { Receiptscontext } from "../context/Receiptscontext";
import Hand from "../assest/hand.svg";
import LightModeIcon from "@mui/icons-material/LightMode";
import rate from "../assest/rateStar.svg";
import languag from "../assest/languag.svg";
import darkMode from "../assest/Darkmode.svg";
import policy from "../assest/policy.svg";
import { CardsContext } from "../context/Cardscontext";
import { themecontext } from "../context/Themecontexte";
import UserExist from "../compenent/Account/UserExist";

function Account() {
  const { User, setUser, update, setUpdate, setFirstFetch } =
    useContext(Usercontext);
  const { setLoyaltyCard } = useContext(CardsContext);
  const { setReceiptes } = useContext(Receiptscontext);
  const { theme } = useContext(themecontext);
  const [OpenMode, setOpenMode] = useState(true);
  const [OpenHande, setOpenHande] = useState(true);
  const [OpenShare, setOpenShare] = useState(true);
  const { t, i18n } = useTranslation();
  var isArabic = i18n.language.startsWith("ar");

  const Navto = useNavigate();

  // log out and clear all the data of the user
  async function Logout() {
    clearDataAndLogout(setLoyaltyCard, setReceiptes, setUser, setFirstFetch);
    Navto("/");
    // Geting the fcm token and send it to the server
    setReceiptes([]);
    const FCMTOKEN = localStorage.getItem("FCMTOKEN");
    console.log("Receiptes from logout");

    axios
      .post("/api/clients/anonymous", {})
      .then((response) => {
        const { data } = response;
        Cookies.set("access_token", "Token " + data.access_token);
        setUser(data.user);
        sendFcmT("Token " + data.access_token);
      })
      .catch((error) => {
        console.error();
      });
  }

  return (
    <div className=" w-full flex  relative    flex-col p-[1.9rem] pb-28  ">
      <Handedness OpenHande={OpenHande} setOpenHande={setOpenHande} />
      <Mode OpenMode={OpenMode} setOpenMode={setOpenMode} />
      <ShareWith OpenShare={OpenShare} setOpenShare={setOpenShare} />
     
      {!User?.is_anonymous && User ? (
        <UserExist
          User={User}
          setUser={setUser}
          setUpdate={setUpdate}
          update={update}
        />
      ) : (
        <Social_login isArabic={isArabic} t={t} />
      )}

      <div
        className={`  ${
          !User?.is_anonymous && User ? "pt-[14rem]" : "pt-[2rem]"
        }  flex flex-col   font-[500]`}
      >
        {/*  Share  */}
        <span
          onClick={() => setOpenShare(!OpenShare)}
          className={` flex ${
            isArabic ? " flex-row-reverse  justify-end " : " flex-row"
          }   justify-between items-center`}
        >
          <div
            className={`flex w-full ${
              isArabic ? " flex-row-reverse   " : " flex-row"
            } gap-[.8rem] items-center`}
          >
            <span className=" w-[2.2rem] items-center justify-center flex  h-[2.2rem] bg-[#E9E8FF] rounded-full ">
              <ShareOutlinedIcon className=" text-[#4F06C6] scale-90 " />
            </span>

            <h1 className=" text-[.9rem]  ">{t("profile.Share")} </h1>
          </div>

          <ChevronRightIcon
            className={` ${isArabic ? " rotate-180" : " rotate-0"}  scale-90`}
          />
        </span>

        <h1 className="  py-[1.4rem] text-[.9rem] text-sec ">
          {t("profile.Settings")}{" "}
        </h1>
        {/*  Personal Info  */}
        <div className=" flex flex-col gap-[1.8rem] ">
          <Link
            to={"/PersonalInfo"}
            className={`    ${
              User && User?.is_anonymous == false ? " flex" : " hidden"
            }  ${
              isArabic ? " flex-row-reverse  justify-end " : " flex-row"
            }  justify-between items-center`}
          >
            <div
              className={`flex w-full ${
                isArabic ? " flex-row-reverse   " : " flex-row"
              } gap-[.8rem] items-center`}
            >
              <span className=" w-[2.2rem] items-center justify-center flex  h-[2.2rem] bg-[#D9FFDD] rounded-full ">
                <PersonOutlineOutlinedIcon className=" scale-90 text-main" />
              </span>
              {/*  */}
              <h1 className=" text-[.9rem]  "> {t("profile.Personal")}</h1>
            </div>

            <ChevronRightIcon
              className={` ${isArabic ? " rotate-180" : " rotate-0"}  scale-90`}
            />
          </Link>

          {/*   Linked Accounts  */}

          <Link
            to={"/linkedaccounts"}
            className={`    ${
              User && User?.is_anonymous == false ? " flex" : " hidden"
            }  ${
              isArabic ? " flex-row-reverse  justify-end " : " flex-row"
            }  justify-between items-center`}
          >
            <div
              className={`flex w-full ${
                isArabic ? " flex-row-reverse   " : " flex-row"
              } gap-[.8rem] items-center`}
            >
              <span className=" w-[2.2rem] items-center justify-center flex  h-[2.2rem] bg-[#FFE0FE] rounded-full ">
                <img src={Linked} alt="Linked account" />
              </span>
              {/*  */}
              <h1 className=" text-[.9rem]  ">
                {" "}
                {t("importReFromEmail.MyLinkedAccounts")}
              </h1>
            </div>

            <ChevronRightIcon
              className={` ${isArabic ? " rotate-180" : " rotate-0"}  scale-90`}
            />
          </Link>

          {/*  Currency  */}

          {/* <Link
            to={"/Currency"}
            className={` flex ${
              isArabic ? " flex-row-reverse  justify-end " : " flex-row"
            }   justify-between items-center`}
          >
            <div
              className={`flex w-full ${
                isArabic ? " flex-row-reverse   " : " flex-row"
              } gap-[.8rem] items-center`}
            >
              <span className=" w-[2.2rem] items-center justify-center flex  h-[2.2rem] bg-[#FFFACA] rounded-full ">
               
              </span>

              <h1 className=" text-[.9rem]  ">{t("topbar.Ctoptitle")} </h1>
            </div>

            <ChevronRightIcon
              className={` ${isArabic ? " rotate-180" : " rotate-0"}  scale-90`}
            />
          </Link> */}

          {/*  Hand Orientation  */}

          <span
            onClick={() => setOpenHande(!OpenHande)}
            className={` flex ${
              isArabic ? " flex-row-reverse  justify-end " : " flex-row"
            }   justify-between items-center`}
          >
            <div
              className={`flex w-full ${
                isArabic ? " flex-row-reverse   " : " flex-row"
              } gap-[.8rem] items-center`}
            >
              <span className=" w-[2.2rem] items-center justify-center flex  h-[2.2rem] bg-[#D5FFCA] rounded-full ">
                <img src={Hand} alt="" />
              </span>

              <h1 className=" text-[.9rem]  ">{t("profile.Hand")} </h1>
            </div>

            <ChevronRightIcon
              className={` ${isArabic ? " rotate-180" : " rotate-0"}  scale-90`}
            />
          </span>

          {/*  Language  */}

          <Link
            to={"/Language"}
            className={` flex ${
              isArabic ? " flex-row-reverse  justify-end " : " flex-row"
            }   justify-between items-center`}
          >
            <div
              className={`flex w-full ${
                isArabic ? " flex-row-reverse   " : " flex-row"
              } gap-[.8rem] items-center`}
            >
              <span className=" w-[2.2rem] items-center justify-center flex  h-[2.2rem] bg-[#FFD3CA] rounded-full ">
                <img src={languag} alt="languag" />
              </span>

              <h1 className=" text-[.9rem]  ">{t("profile.Language")}</h1>
            </div>

            <ChevronRightIcon
              className={` ${isArabic ? " rotate-180" : " rotate-0"}  scale-90`}
            />
          </Link>

          {/*  Mode  */}

          <span
            onClick={() => setOpenMode(!OpenMode)}
            className={` flex ${
              isArabic ? " flex-row-reverse  justify-end " : " flex-row"
            }   justify-between items-center`}
          >
            <div
              className={`flex w-full ${
                isArabic ? " flex-row-reverse   " : " flex-row"
              } gap-[.8rem] items-center`}
            >
              <span
                className={` w-[2.2rem] items-center justify-center flex  h-[2.2rem] bg-[#000]   text-white  rounded-full `}
              >
                {theme ? <LightModeIcon /> : <img src={darkMode} alt="" />}
              </span>

              <h1 className=" text-[.9rem]  ">{t("profile.Mode")}</h1>
            </div>

            <ChevronRightIcon
              className={` ${isArabic ? " rotate-180" : " rotate-0"}  scale-90`}
            />
          </span>
        </div>
        <h1 className="  py-[1.4rem] text-[.9rem] text-sec ">
          {t("profile.Other")}
        </h1>

        <div className=" flex flex-col gap-[1.8rem]">
          <span
            className={` flex ${
              isArabic ? " flex-row-reverse  justify-end " : " flex-row"
            }   justify-between items-center`}
          >
            {/*  Privacy  */}

            <Link
              to={"https://fintechracy.org/privacy&policy"}
              className={`flex w-full ${
                isArabic ? " flex-row-reverse   " : " flex-row"
              } gap-[.8rem] items-center`}
            >
              <span className=" w-[2.2rem] items-center justify-center flex  h-[2.2rem] bg-[#CAFFF9] rounded-full ">
                <img src={policy} alt="" />
              </span>

              <h1 className=" text-[.9rem]  ">{t("profile.Privacy")}</h1>
            </Link>

            <ChevronRightIcon
              className={` ${isArabic ? " rotate-180" : " rotate-0"}  scale-90`}
            />
          </span>
         
          {/*  Rate  */}

          <Link to={'https://play.google.com/store/apps/details?id=com.fintechracy.user&hl=en_US'}
            className={` flex ${
              isArabic ? " flex-row-reverse  justify-end " : " flex-row"
            }   justify-between items-center`}
          >
            <div
              className={`flex w-full ${
                isArabic ? " flex-row-reverse   " : " flex-row"
              } gap-[.8rem] items-center`}
            >
              <span className=" w-[2.2rem] items-center justify-center flex  h-[2.2rem] bg-[#E4CAFF] rounded-full ">
                <img src={rate} alt="" />
              </span>

              <h1 className=" text-[.9rem]  ">{t("profile.Rate")}</h1>
            </div>

            <ChevronRightIcon
              className={` ${isArabic ? " rotate-180" : " rotate-0"}  scale-90`}
            />
          </Link>
          
            {/*  Logout  */}

          <div
            onClick={Logout}
            className={`    ${
              User && User?.is_anonymous == false ? " flex" : " hidden"
            }  flex-row  justify-between items-center pt-4`}
          >
            <div
              className={`flex w-full ${
                isArabic ? " flex-row-reverse   " : " flex-row"
              } gap-[.8rem] items-center`}
            >
              <span className=" rotate-180  text-red-700 w-[2.2rem] items-center justify-center flex  h-[2.2rem] bg-red-200 rounded-full ">
                <LogoutIcon fontSize="" />
              </span>
              <h1 className="  text-[.9rem]  ">{t("profile.logout")}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Account;
