import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

function Card({ img, id, Sname  , BankingCards}) {
  const item = {
    hidden: { y: -10, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <motion.div
      className=" w-full
     h-[6.4rem] "
      variants={item}
    >
      <Link
        className= {`w-full relative ${BankingCards ? ' h-[13rem] mt-7 ':'h-[6.4rem]' }  `}
        to={ BankingCards ? `/Cards/BankingCardDetails/${id}` : `/Cards/LoyaltyCardDetails/${id}`}
      >
        {!img ? (
          <span className="  flex justify-center items-center text-[2.4rem] font-bold text-white   w-full h-full   rounded-[.30rem]  bg-main ">
            {Sname?.charAt(0)}
          </span>
        ) : (
          <img
            className=   {`w-full rounded-md ${BankingCards ? ' h-[12rem] ':'h-[6.4rem]' } object-cover `}
            src={ (typeof img === 'string' ? img  : URL.createObjectURL(img))}
            alt=""
          />
        )}
      </Link>
    </motion.div>
  );
}

export default Card;
