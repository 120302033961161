// Importing necessary dependencies and assets
import React, { useEffect, useContext } from "react";
import congratImg from "../assest/congrats.png";
import congratImgB from "../assest/congratsB.png";
import congratFR from "../assest/congratFR.png";
import congratFRWhite from "../assest/congratFRWhite.png";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import congratAR from "../../src/assest/congtarAR.png"
import congtarARWhite from "../../src/assest/congtarARWhite.png"
import { themecontext } from "../context/Themecontexte";
import closeBtn from '../../src/assest/CloseBtn.svg'
import { Receiptscontext } from "../context/Receiptscontext";

// Define the Massege component
function Massege() {
  // Accessing theme context
  const { theme } = useContext(themecontext);

  // Accessing Usercontext for managing messages
  const { setMassage, Massage } = useContext(Receiptscontext);

  // Accessing i18n for translation
  const { t, i18n } = useTranslation();

  // Checking if the language is Arabic
  const isArabic = i18n.language.startsWith('ar');
  const isFR = i18n.language.startsWith('fr');
 
  // Auto-hide the message after 5 seconds
  useEffect(() => {
    setTimeout(() => {
      setMassage(null)
    }, 5000);
  }, [])

  return (
    <div
      className={`duration-150 ${
        Massage ? "top-[50%]" : " top-[-100%] -z-10 "
      } flex z-40 fixed h-full w-full   `}
    >
      {/* Overlay for background dimming */}
      <div
        onClick={() => setMassage(null)}
        className={`flex fixed h-full w-full   ${
          Massage ? "opacity-50 top-0  " : "opacity-0  top-[-100%] -z-10  "
        } right-0 bg-black `}
      ></div>

      {/* Message container */}
      <div
        className={`${
          Massage ? "z-30 top-[50%] opacity-100 " : " opacity-0 -top-[50%] -z-10    "
        } duration-150 transform flex translate-x-[-50%] translate-y-[-50%] h-[58%] left-[50%] fixed w-[80%] flex-col items-center justify-between pt-[1.6rem] pb-[3.5rem] px-[.74rem] rounded-[.31rem] ${theme ? 'bg-white' : 'bg-[#212121]'}`}
      >
        {/* Close button */}
        <div className="absolute top-0 w-full flex items-center right-0">
          <span onClick={() => setMassage(null)} className="absolute top-4 right-4 cursor-pointer">
            <img src={closeBtn} alt="" />
          </span>
        </div>

        {/* Display congratulatory image */}
        <div
          className="w-[9rem] h-[10rem] bg-no-repeat bg-cover"
          style={{ backgroundImage: `url(${theme ? (isArabic ?  congratAR : isFR ?  congratFR: congratImg) : (isArabic ?  congtarARWhite : isFR ?  congratFRWhite   : congratImgB)
          })` }}
        ></div>

        {/* Message text */}
        <h1 className={`${theme ? 'text-[#25215e]' : 'text-white'} ${isArabic ? 'text-[.94rem]' : 'text-[1.1rem]'} font-semibold w-[70%] text-center`}>
          {Massage === "new" ? t('Notifications.SuccessMessage') : t('Msg.frechR')}
        </h1>

        {/* Check it button */}
        <Link to={'/'} onClick={() => setMassage(null)} className={`w-full ${isArabic ? 'text-[1rem]' : 'text-[1.1rem]'} flex justify-center items-center bg-main text-[1.1rem] font-semibold text-center text-white h-[3.5rem] rounded-[.31rem] `}>
          {t('Msg.Checkit')}
        </Link>

        {/* Remind me later button */}
        <p onClick={() => setMassage(null)} className="text-[#C4C4C4] text-sm absolute bottom-4">{t('Msg.Remindme')}</p>
      </div>
    </div>
  );
}

// Export the Massege component
export default Massege;
