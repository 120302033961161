// Import necessary dependencies and components
import React, { useState, useEffect } from "react";
import format from "date-fns/format";
import { ProductsData } from "../../assest/data/ProductsData";
import up from "../../assest/up.svg";
import down from "../../assest/down.svg";
import { useTranslation } from "react-i18next";
import "../../App.css";
import LinedChart from "./LinedChart";

// Functional component for rendering trends
function Trends({ filteredData, Period }) {
  // State variables using React Hooks
  const [state, setState] = useState(0);
  const [TrendsChartD, setTrendsChartD] = useState();

  const { t, i18n } = useTranslation();
  var isArabic = i18n.language.startsWith("ar");
  const [STrendsData, setSTrendsData] = useState();

  function getPeriod(timestamp, Period) {
    const date = new Date(timestamp);

    if (Period === "Yesterday" || Period === "Today") {
      return date.getHours(); // Return the hour for 'Yesterday' or 'Today'
    } else if (Period === "Last year" || Period === "This year") {
      return date.getMonth(); // Return the month for 'Last year' or 'This year'
    } else {
      return date.getDate(); // Return the date for other periods
    }
  }

  // Initialize data structures to store totals and grouped data
  const TotalsSpent = {};

  useEffect(() => {
    // Loop through filtered data entries
    filteredData?.forEach((entry) => {
      const period = getPeriod(entry.created_at, Period);

      // Update TotalsSpent with total and createdAt information
      if (!TotalsSpent[period]) {
        TotalsSpent[period] = {
          total: entry?.json?.total,
          period: entry.created_at, // Add createdAt property
        };
      } else {
        TotalsSpent[period].total += entry?.json?.total;
        TotalsSpent[period].period = entry.created_at;
      }
    });
    const totalResult = Object.values(TotalsSpent)
    .filter((entry) => typeof entry.total === 'number' && !isNaN(entry.total));

    setTrendsChartD({
      data: totalResult.map((i) => i.total),
      time: totalResult.map((i) => i.period),
    });
  }, [filteredData]);

  // useEffect hook for handling data transformations when 'Period' changes
  useEffect(() => {
    // Helper function to get the hour from a timestamp
    const getHourFromTimestamp = (timestamp) => {
      const date = new Date(timestamp);
      return date.getHours();
    };

    // Array to store total spending per hour
    let totalSpentPerHour = [];

    // Iterate through filteredData and calculate total spending per hour
    filteredData?.forEach((item) => {
      const hour = getHourFromTimestamp(item.created_at);

      if (!totalSpentPerHour[hour]) {
        totalSpentPerHour[hour] = {
          total: 0,
          timestamps: "",
        };
      }
      totalSpentPerHour[hour].total += item?.json?.total;
      totalSpentPerHour[hour].timestamps = item.created_at;
    });

    // Filter out undefined and null values from totalSpentPerHour array
    totalSpentPerHour = totalSpentPerHour?.filter(
      (item) => typeof item !== "undefined" && item !== null
    );

    // Calculate percentage change between consecutive hours
    for (let i = 1; i < totalSpentPerHour?.length; i++) {
      const previousTotal = totalSpentPerHour[i - 1].total;
      const currentTotal = totalSpentPerHour[i].total;

      // Calculate percentage change
      let percentageChange =
        ((currentTotal - previousTotal) / previousTotal) * 100;

      totalSpentPerHour[i].percentageChange = percentageChange;
    }

    // Set the state with the updated trends data
    setSTrendsData(totalSpentPerHour);
  }, [Period]);

  // Render the component
  return (
    <div className="w-full flex flex-col">
      {/* Render LinedChart component */}
      <LinedChart TrendsChartD={TrendsChartD} Period={Period} />

      {/* Render the header section */}
      <div className="flex flex-row items-start text-left">
        <span
          className={`w-full justify-start py-4 flex ${
            isArabic ? "flex-row-reverse" : "flex-row"
          } gap-1 ${
            state >= 2 ? "text-[.94rem]" : "text-[1.1rem]"
          } items-center font-[400]`}
        >
          <span className={`w-3 h-3 rounded-full bg-main`}></span>{" "}
          <h1 className="text-[.96rem]"> {t("Stats.SpendingOver")} </h1>
        </span>
      </div>

      {/* Render the trends data */}
      <div>
        <div className="custom-scroll-container w-full flex flex-col h-[32vh]">
          <div
            className={`text-center font-[500] pb-4 text-[.9rem] ${
              isArabic ? "flex-row-reverse" : "flex-row"
            } flex justify-between items-center`}
          >
            <h1
              className={`w-full ${isArabic ? "text-right pr-2" : "text-left"}`}
            >
              {t("Stats.Date")}{" "}
            </h1>
            <h1 className="w-full">{t("Stats.Spending")}</h1>
            <h1 className={`w-full ${!isArabic ? "text-right" : "text-left"}`}>
              {t("Stats.PChange")}{" "}
            </h1>
          </div>

          <div className="content flex flex-col h-full pb-5 pr-2 overflow-auto pt-[.3rem] gap-1">
            {/* Map through STrendsData to render each data point */}
            {STrendsData?.map((i) => (
              <div
                className={`flex ${
                  isArabic ? "flex-row-reverse" : "flex-row"
                } h-6 mt-1 justify-between items-center w-full`}
              >
                <h1 className=" ">
                  {Period == "Today"
                    ? format(new Date(i.timestamps), "HH:mm:ss")
                    : i.timestamps.split("T")[0]}{" "}
                </h1>
                <h1 className="text-center text-main ">{i?.total}</h1>
                <h1 className="text-center text-[#06C671] ">
                  <div
                    className={`${
                      i.percentageChange < 0 ? "text-red-500" : "text-main"
                    } flex w-full flex-row gap-1`}
                  >
                    <p> {`${i.percentageChange?.toFixed(0) || 0} %`} </p>{" "}
                    <img
                      src={
                        i.percentageChange >= 0 || !i.percentageChange
                          ? up
                          : down
                      }
                      alt=""
                    />{" "}
                  </div>
                </h1>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

// Export the Trends component as the default export
export default Trends;
