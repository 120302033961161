import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

function ReceiptDetils({ json, setPdfOpen }) {
  const { t, i18n } = useTranslation();
  var isArabic = i18n.language.startsWith("ar");

  return (
    <motion.div
      initial={{
        height: "0rem",
        opacity: 0,
      }}
      animate={{
        height: "100%",
        opacity: 1,
      }}
      exit={{
        height: 0,
        opacity: 0,
      }}
      transition={{
        duration: 0.1,
      }}
      className={`  ${json ? " " : "hidden"}  w-full    `}
    >
      <div
        className={`  font-normal text-[.8rem] text-sec pt-[.7rem]   ${
          isArabic ? " flex-row-reverse" : " flex-row items-start pr-7 "
        }  w-full justify-between `}
      >
        <p> {json?.shop_details?.contact} </p>
        <span
          className={` flex gap-1 ${
            isArabic ? " flex-row-reverse" : " flex-row"
          }`}
        >
          <p className=" "> {json?.products?.length} </p>{" "}
          <span> {t("receipts.items")}</span>
        </span>
      </div>

      <div
        onClick={() => setPdfOpen(true)}
        className="pt-[1.6rem] pl-1 pr-[.8rem] flex-col  w-full justify-center     flex items-center "
      >
        <div
          className={`  font-[600]  text-[.96rem]  w-full  flex ${
            isArabic ? " flex-row-reverse" : " flex-row "
          }  justify-between    w-full `}
        >
          <span className=" flex  flex-col gap-3  w-full font-normal">
            <h1 className=" text-[1rem] opacity-80 font-[550] ">
              {" "}
              {t("receipts.Product")}
            </h1>
            {json?.products?.map((i) => (
              <p className=" px-1">
                {" "}
                {i.name?.length >= 8
                  ? i.name?.substring(0, 8)?.toLowerCase() + "..."
                  : i.name?.toLowerCase()}{" "}
              </p>
            ))}
          </span>

          <span className=" text-center flex flex-col gap-3  w-full font-normal">
            <h1 className=" text-[1rem] opacity-80 font-[550] ">
              {" "}
              {t("receipts.Quantity")}{" "}
            </h1>
            {json?.products?.map((i) => (
              <p>{i?.quantity} </p>
            ))}
          </span>

          <span
            className={` ${
              isArabic ? "  text-left pl-1 " : " text-right"
            }    pr-1 flex flex-col gap-3  w-full font-normal`}
          >
            <h1 className=" text-[1rem] opacity-80 font-[550] ">
              {t("receipts.Price")}
            </h1>
            {json?.products?.map((i) => (
              <p> {i?.price} </p>
            ))}
          </span>
        </div>

        <div className="  w-full items-center flex flex-col ">
          <span className=" w-full my-2 h-[1px] bg-gray-200 "></span>
          <div className=" flex flex-col  w-full   text-base  gap-[.2rem] ">
            <span
              className={`flex w-full ${
                isArabic ? " flex-row-reverse  text-sm" : "  flex-row"
              }  justify-between`}
            >
              <h1 className=" font-[600] ">{t("receipts.Total")}</h1>
              <h1 className=" font-[600] text-[.9rem] "> {json?.total} </h1>
            </span>

            <span
              className={`flex w-full ${
                isArabic ? " flex-row-reverse text-sm " : "  flex-row"
              }  justify-between`}
            >
              <h1 className=" font-[600] ">{t("receipts.Credit")}</h1>
              <h1 className=" font-[600] text-[.9rem] "> {json?.credit} </h1>
            </span>
          </div>

          <span className=" my-[.9rem] w-[100%] h-[.4px] bg-opacity-30 bg-main  "></span>
          <p className=" text-[.68rem] leading-4 ">
            {" "}
            <span className=" font-[600] ">
              {" "}
              {t("receipts.Important")}{" "}
            </span>{" "}
            {t("receipts.IportantT", {
              num: 2,
            })}{" "}
          </p>
        </div>
        <div className="  text-center w-full text-[.75rem] pb-1 pt-[.9rem] font-[500] ">
          {t("receipts.thanks")}
        </div>
      </div>
    </motion.div>
  );
}
export default ReceiptDetils;
